import { privateProperties } from './access';

export const contributorPreset = {
  'createUser.showValueProps': false,
  'logo.component': 'Contributor',
  showFooter: true,
  showContactUsLink: true,
  [privateProperties]: {
    'createUser.showDisplayName': true,
    'createUser.showFullName': true,
    'createUser.termsOfService.action': 'explicit',
    'createUser.termsOfService.component': 'contributor',
    'createUser.termsOfService.name': 'submit_tos',
    'createUser.termsOfService.value': 'submit:7',
    showLoginWithSso: false,
    showSocialLogin: false,
    contactUsLink: 'https://submit.shutterstock.com/contact',
  },
};

export default contributorPreset;
