import Url from 'url';

const recursionLimit = 5;

/**
 * Appends parameters to query, supports both absolute and relative locations
 * @param {string|URL} location The URL to append parameter to.
 * @param {string|URLSearchParams} searchParams The parameters string or object to add.
 * @returns {string} location with appended parameters.
 */
export const appendQueryParameters = function (location, searchParams) {
  const isRelative = location.startsWith('/');
  let url;
  try {
    url = new URL(location, isRelative ? window.location.protocol + '//' + window.location.host : undefined);
  } catch (error) {
    return location;
  }

  if (!(searchParams instanceof URLSearchParams)) {
    searchParams = new URLSearchParams(searchParams);
  }

  searchParams.forEach((value, name) => {
    url.searchParams.append(name, value);
  });

  return isRelative ? `${url.pathname}${url.search}${url.hash}` : url.toString();
};

/**
 * Searches a URL to find the specified query string parameter, including searching
 * encoded URLs in the `next` parameter.
 * @param {string|URL} location The URL to search.
 * @param {string} name The nested parameter key.
 */
export const getNestedQueryParameter = function (
  location,
  name,
  { recurse = false, type = undefined, _recursionCount = 0 } = {},
) {
  let url;
  try {
    url = new URL(location);
  } catch (err) {
    return null;
  }

  type = String(type).toLowerCase();

  const rootValue = url.searchParams.get(name);
  const nextValue = url.searchParams.get('next');
  let resolvedValue = null;

  if ((rootValue || type === 'boolean') && typeof rootValue === 'string') {
    resolvedValue = rootValue;
  } else if (nextValue && typeof nextValue == 'string') {
    const nextUrl = new URL(nextValue, location);
    const nestedValue = nextUrl.searchParams.get(name);
    if (typeof nestedValue === 'string') {
      resolvedValue = nestedValue;
    } else if (recurse) {
      if (_recursionCount < recursionLimit) {
        return getNestedQueryParameter(nextUrl, name, { recurse, _recursionCount: _recursionCount + 1 });
      } else {
        throw new Error('Recursion count exceeded limit.');
      }
    }
  }

  if (typeof resolvedValue === 'string' && type === 'boolean') {
    resolvedValue = ['', '1', 'true'].includes(resolvedValue.toLowerCase());
  }

  return resolvedValue;
};

export const sanitizeRedirectUrl = function (uri, fallback = '/') {
  let redirectUri = null;
  try {
    redirectUri = Url.parse(uri);
  } catch (e) {
    // Ignore
    return fallback;
  }
  const { protocol, hostname, href } = redirectUri;

  const isAllowedAppUrl = ['sstkcontributor:', 'sstkcustomer:'].includes(protocol);
  const isLocalUri = hostname === 'localhost' || hostname === '127.0.0.1';

  const isValidUri =
    (/^\//.test(uri) && !uri.startsWith('//') && !uri.startsWith('/\\')) ||
    ((/\.shutterstock\.com$/.test(hostname) ||
      /\.shuttercorp\.net$/.test(hostname) ||
      /\.premiumbeat\.com$/.test(hostname) ||
      /\.shuttercloud\.org$/.test(hostname)) &&
      ['http:', 'https:'].includes(protocol));

  if (isLocalUri || isValidUri || isAllowedAppUrl) {
    return href;
  }

  return fallback;
};
