import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { languageVar } from '../../application-state';

export const LegalExternalLinkType = {
  LicensingTermsOfService: 'LicensingTermsOfService',
  PrivacyPolicy: 'PrivacyPolicy',
  WebsiteTermsOfService: 'WebsiteTermsOfService',
};

const linksByType = {
  [LegalExternalLinkType.LicensingTermsOfService]: { href: 'https://www.shutterstock.com/{language}/license' },
  [LegalExternalLinkType.PrivacyPolicy]: { href: 'https://www.shutterstock.com/{language}/privacy' },
  [LegalExternalLinkType.WebsiteTermsOfService]: { href: 'https://www.shutterstock.com/{language}/terms' },
};

export const LegalExternalLink = ({ children: linkText, href, type }) => {
  const language = useReactiveVar(languageVar);
  const languageCode = language?.code || 'en';

  const link = linksByType[type] || {};

  if (!href && link.href) {
    href = link.href;
  }

  if (href) {
    href =
      languageCode === 'en'
        ? // If `en`, completely remove the language part from the URL path
          href.replace(/\{language\}\//g, '')
        : href.replace(/\{language\}/g, languageCode);
  }

  return (
    // Set `rel="noopener noreferrer"` to avoid exploitation of the window.opener API when target="_blank"
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#Security_and_privacy_concerns
    <a href={href} target="_blank" rel="noopener noreferrer">
      {linkText}
    </a>
  );
};
