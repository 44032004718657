import React from 'react';
import { SocialLogin } from '../../../../account';
import classes from './SocialLoginTop.module.scss';
import { OrTextDivider } from '../OrTextDivider/OrTextDivider';

export const SocialLoginTop = ({ openSocialLoginInNewTab = false, analyticsVariant = '' }) => {
  return (
    <div className={classes.container}>
      <SocialLogin openSocialLoginInNewTab={openSocialLoginInNewTab} analyticsVariant={analyticsVariant} />
      <OrTextDivider />
    </div>
  );
};
