const scriptUrl = 'https://www.google.com/recaptcha/enterprise.js?render=';

let loaded = false;

export const loadRecaptcha = ({ keyId = '' } = {}) => {
  if (loaded) {
    throw new Error('reCAPTCHA has already been loaded.');
  }

  const script = document.createElement('script');

  script.src = scriptUrl + keyId;

  document.body.appendChild(script);

  loaded = true;
};

export const useRecaptcha = () => {
  return window.grecaptcha;
};
