import { ApolloClient, InMemoryCache } from '@apollo/client';

export const createApolloClient = ({ uri = '/web/api/federated-graph' } = {}) => {
  const cache = new InMemoryCache();

  const client = new ApolloClient({
    uri,
    cache,
  });

  return client;
};
