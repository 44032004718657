import { getLog } from './Log';

const logger = getLog('sandbox');

/**
 * "Default" callHandler implementation
 * @param {Object} options
 * @param {Window} options.targetWindow a target window, for example `document.getElementById('iframe').contentWindow`
 * @param {string=} options.namespace postMessage namespace, for example `analytics`
 * @param {string=} options.targetOrigin the origin of targetWindow
 */
const createPostMessageHandler = ({ targetWindow, namespace, targetOrigin }) => {
  /**
   * "Default" callHandler
   * @param {Object} options
   * @param {string} options.prop a property name
   * @param {any[]} options.ars a list of properties
   * @param {Object} options.target a target object
   * @param {Function} options.property a target property function
   */
  return ({ prop, args, property, target }) => {
    targetWindow.postMessage({ prop, namespace, args }, targetOrigin || window.location.origin);
  };
};

/**
 * Proxy that caches all calls until we toggle caching to false.
 * After setting a caching to false proxy will flush cached items
 * @param {Object} params
 * @param {Object} params.target - calls target (can be another proxy)
 */
const createCachedProxy = ({ target }) => {
  let calls = [];
  let isCaching = true;

  const proxyHandler =
    ({ method }) =>
    (...args) =>
      target[method](...args);
  const addToCacheHandler =
    ({ method }) =>
    (...args) => {
      logger.trace('add to cache', { method, args });

      calls.push({ method, args });
    };

  /**
   * Enables/disables caching, if caching === false flushes cached items
   * @param {boolean} enabled
   */
  const toggleProxyCaching = (enabled) => {
    logger.trace('toggle proxy caching', { enabled });

    isCaching = enabled;

    //flush events
    if (!isCaching) {
      calls.forEach(({ method, args }) => {
        logger.trace('flush cached item', { method, args });

        try {
          proxyHandler({ method })(...args);
        } catch (error) {
          logger.erorr(error);
        }
      });

      calls = [];
    }
  };

  const handler = {
    get(target, propKey, receiver) {
      const property = Reflect.get(target, propKey, receiver);

      if (typeof property === 'function') {
        if (propKey === 'toggleProxyCaching') {
          return toggleProxyCaching;
        }

        if (isCaching) {
          return addToCacheHandler({ method: propKey });
        }

        return proxyHandler({ method: propKey });
      }

      return property;
    },
  };

  const proxy = new Proxy(target, handler);

  //necessary for calling a proxy toggleProxyCaching method
  proxy.toggleProxyCaching = () => {};

  return proxy;
};

/**
 * Proxy creator
 * Creates a proxy for all object functions, calls both methods: callHandler({ prop, args }) and a real one
 * @param {Object} options
 * @param {Object} options.target a target object
 * @param {Function} options.callHandler a handler which proxy will call
 */
const createProxy = ({ target, callHandler }) => {
  const methodsCache = new Map();

  const handler = {
    get(target, propKey, receiver) {
      const property = Reflect.get(target, propKey, receiver);

      if (typeof property === 'function') {
        let targetMethod = methodsCache.get(propKey);

        if (!targetMethod) {
          targetMethod = (...args) => {
            return callHandler({ prop: propKey, args, target, property });
          };

          methodsCache.set(propKey, targetMethod);
        }

        return targetMethod;
      }

      return property;
    },
  };

  return new Proxy(target, handler);
};

/**
 * Create a hidden iframe
 * @param {Object} options
 * @param {string} options.src - url
 */
const createSandboxIframe = ({ src, ...attrs }) => {
  const iframe = document.createElement('iframe');

  iframe.setAttribute('src', src);

  Object.entries({
    style: 'display: block; height: 0;',
    height: '0',
    width: '0',
    frameborder: '0',
    sandbox: 'allow-scripts allow-forms allow-same-origin',
    tabindex: -1,
    'aria-hidden': true,
    ...attrs,
  }).forEach(([key, value]) => {
    iframe.setAttribute(key, value);
  });

  return iframe;
};

export { createProxy, createSandboxIframe, createPostMessageHandler, createCachedProxy };
