import React from 'react';
import { Trans } from 'react-i18next';
import { I18nKey } from '../../i18n';
import classes from './OtpResendLinkHelp.module.scss';

export const OtpResendLinkHelp = ({ onClick = () => {}, disabled = false }) => {
  return (
    <p>
      {/* prettier-ignore */}
      <Trans i18nKey={I18nKey.OtpResendCodeHelp}>
        Didn't receive a code? Click <a href="/" onClick={onClick} className={disabled ? classes.disabled : ''}>here</a> to resend
      </Trans>
    </p>
  );
};
