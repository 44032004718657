import React from 'react';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export const PageTitle = ({ children: title, webProperty }) => {
  if (typeof title !== 'string') {
    title = renderToString(title);
  }

  const titleBody = title ? `${title} | ${webProperty}` : webProperty;

  return (
    <Helmet>
      <title>{titleBody}</title>
    </Helmet>
  );
};

PageTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  webProperty: PropTypes.string,
};

PageTitle.defaultProps = {
  children: '',
  webProperty: 'Shutterstock',
};
