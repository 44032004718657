import './polyfill';
import React, { lazy, Suspense } from 'react';
import { App } from './App';
import { MarketingSandbox } from './www/marketing-sandbox';
import { configureAnalyticsWrapper, createAnalyticsWrapper } from './analytics/analyticsWrapperFactory';
import {
  ApplicationStateConsumer,
  ApplicationStateProvider,
  analyticsVar,
  countryCodeVar,
  featureFlagsVar,
  syncLocalState,
  syncState,
  userVar,
  resolveApplicationConfiguration,
  recaptchaKeyIdVar,
} from './application-state';
import './index.css';
import { printDevToolsHelp } from './devtools-help';
import { createRoot } from 'react-dom/client';
import { loadRecaptcha } from './lab/signin';

const containersByMode = {
  app: App,
  marketing: MarketingSandbox,
};

const idn4731IdentifierFirstAuthentication = () => {
  const identifierFirstAuthenticationEnabled = featureFlagsVar()?.['identifier-first-authentication'] === 'variant';

  if (identifierFirstAuthenticationEnabled) {
    // TODO: when removing, refactor to general reCAPTCHA Enterprise support under a different feature flag.
    // When enabled, the previous version of reCAPTCHA should not be loaded in the login and signup views, which
    // happens via react-google-recaptcha.
    loadRecaptcha({ keyId: recaptchaKeyIdVar() });
  }
};

(async () => {
  try {
    printDevToolsHelp();

    const webAnalyticsHightouchKey = window.__hightouchKey;
    const analyticsWrapper = createAnalyticsWrapper({
      webAnalyticsHightouchKey,
    });
    const anonymousUserId = analyticsWrapper.getAnonId();

    await syncState({ anonymousUserId });
    syncLocalState();

    idn4731IdentifierFirstAuthentication();

    const { environment: webAnalyticsEnvironment } = analyticsVar();
    const country = countryCodeVar();
    const user = userVar();

    const appMode = window.location.pathname === '/sandboxes/marketing' ? 'marketing' : 'app';

    const analyticsWrapperProxy = configureAnalyticsWrapper({
      analyticsWrapper,
      appMode,
      webAnalyticsEnvironment,
      country,
      user,
    });

    const applicationConfiguration = resolveApplicationConfiguration();

    const render = (Component) => {
      createRoot(document.getElementById('root')).render(
        <ApplicationStateProvider analytics={analyticsWrapperProxy} originalAnalyticsWrapper={analyticsWrapper}>
          <ApplicationStateConsumer>
            {({ initialize }) => {
              initialize();

              return (
                <Component
                  analyticsWrapper={analyticsWrapperProxy}
                  applicationConfiguration={applicationConfiguration}
                />
              );
            }}
          </ApplicationStateConsumer>
        </ApplicationStateProvider>,
      );
    };

    render(containersByMode[appMode]);

    if (module.hot) {
      module.hot.accept('./App', () => {
        const NextApp = require('./App').App;

        render(NextApp);
      });
    }
  } catch (error) {
    console.error('Error loading the app:', error);

    const BootstrapErrorPage = lazy(() => import('./error-pages/BootstrapErrorPage'));

    createRoot(document.getElementById('root')).render(
      <Suspense fallback={<div />}>
        <BootstrapErrorPage />
      </Suspense>,
    );
  }
})();

/**
 * Fix for a safari back button, safari returns a cached version of the page
 */
window.addEventListener('pageshow', function (event) {
  if (event.persisted) {
    window.location.reload();
  }
});
