import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';

export const SignedInUser = ({ email, switchUserUrl = '/logout' }) => {
  const { t: translate } = useTranslation();

  return (
    <>
      <span>
        {/* prettier-ignore */}
        <Trans i18nKey={I18nKey.CurrentlySignedInAs}>
          Currently signed into Shutterstock as <strong>{{email}}</strong>
        </Trans>
      </span>
      &nbsp;
      <a href={switchUserUrl}>{translate(I18nKey.NotYou)}</a>
    </>
  );
};
