import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';
import { useAnalytics } from '../../analytics';
import { featureFlagsVar, userVar } from '../../application-state';

export const ExperimentContainer = ({
  children,
  experimentId,
  experimentName,
  featureFlagKey,
  featureFlagVariation = 'off',
  seen = false,
}) => {
  const featureFlags = useReactiveVar(featureFlagsVar);
  const user = useReactiveVar(userVar);
  const analytics = useAnalytics();

  const featureFlagVariationForUser = featureFlags?.[featureFlagKey];

  const enabledByFeatureFlag =
    featureFlagVariationForUser !== undefined &&
    featureFlagVariationForUser !== 'off' &&
    featureFlagVariationForUser === featureFlagVariation;

  useEffect(() => {
    if (!enabledByFeatureFlag) {
      return;
    }

    if (!seen) {
      return;
    }

    const event = {
      experiment_id: experimentId,
      experiment_name: experimentName || experimentId,
      source: 'launch-darkly',
      variation_id: featureFlagVariation,
    };

    // add user information if any
    if (user?.id) {
      const userId = String(user.id);
      const analyticsUser = {
        id: userId,
        user_id: userId,
      };
      event.id = userId;
      event.user_id = userId;
      event.user = analyticsUser;
    }
    analytics.experiment(event);
  }, [
    analytics,
    experimentId,
    experimentName,
    featureFlagVariation,
    featureFlagVariationForUser,
    enabledByFeatureFlag,
    seen,
    user,
  ]);

  if (featureFlags[featureFlagKey] !== undefined) {
    // Render the child component when the feature flag is off and the container contains
    // the control variation.
    if (featureFlagVariationForUser === 'off' && featureFlagVariation === 'control') {
      return <>{children}</>;
    }

    // Render the child component when the variation for the container matches the variation
    // for the current user.
    if (featureFlagVariationForUser === featureFlagVariation) {
      return <>{children}</>;
    }

    return <></>;
  }

  // Render the child component when there is no value for the feature flag key and the
  // container contains the control variation.
  if (featureFlagVariation === 'control') {
    return <>{children}</>;
  }

  return <></>;
};
