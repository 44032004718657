import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Recaptcha from 'react-google-recaptcha';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { I18nKey } from '../../i18n';
import { Form, FormAction, FormHeader, FormTitle, FormBody, TextDivider } from '../../core-components';
import { AnalyticsTracker } from '../../analytics/trackers';
import { CredentialsInput, useCredentialsInput } from '../CredentialsInput';
import { ForgotPasswordLink } from '../ForgotPasswordLink/ForgotPasswordLink';
import { CreateUserCta } from '../create-user';
import { RequestDemo } from '../RequestDemo';
import { SocialLogin } from './SocialLogin';
import classes from './LoginForm.module.scss';

const noop = () => {};

const codesToLinks = {
  USER_IS_DISABLED: 'https://www.shutterstock.com/help',
  CONTRIB_USER_IS_DISABLED: 'https://submit.shutterstock.com/contact',
  USER_FLAGGED_FRAUD: 'https://www.shutterstock.com/help',
  CONTRIB_USER_FLAGGED_FRAUD: 'https://submit.shutterstock.com/contact',
};

const loginErrorCodeTranslator = (error) => {
  let errorCode = error.code;
  switch (error.code) {
    case 'USER_IS_DISABLED':
    case 'CONTRIB_USER_IS_DISABLED':
    case 'LDAP_USER_IS_DISABLED':
      return 'This account has been disabled. Please contact IT.';
    case 'ERROR_USER_FLAGGED_FRAUD':
      errorCode = error.text.includes('contributor') ? 'CONTRIB_USER_FLAGGED_FRAUD' : 'USER_FLAGGED_FRAUD';
      const fraudLink = <a href={codesToLinks[errorCode]}>Shutterstock Customer Support</a>;
      return (
        <Trans i18nKey={I18nKey.UserIsSuspendedText}>
          This account has been suspended for security reasons. If you believe this deactivation is an error, please
          contact {fraudLink} for assistance.
        </Trans>
      );
    default:
      return null;
  }
};

export const LoginForm = ({
  errors,
  language,
  loading,
  showRecaptcha,
  recaptchaSiteKey,
  showFormTitle = true,
  showCreateUserLink = true,
  showRequestDemoLink = false,
  showSocialLogin = true,
  showLoginWithSso = true,
  openSocialLoginInNewTab,
  webPropertyDisplayName = '',
  onSubmit = noop,
  onFormChange = noop,
  onLoginWithIdentityProvider = noop,
  initialEmail = '',
  nextUrl = '',
}) => {
  const initialFormData = {
    email: initialEmail,
    password: '',
    recaptchaValue: '',
    valid: false,
  };

  const { t: translate } = useTranslation();
  const [formData, setFormData] = useState(initialFormData);

  const { credentialsFormValidation, credentialsFormData, credentialsValidateForm, credentialsErrors } =
    useCredentialsInput({
      showErrors: false,
      email: formData.email,
      password: formData.password,
      showPassword: true,
    });

  const handleCredentialsChange = (email, password, valid) => {
    const newFormData = {
      ...formData,
      email,
      password,
      valid,
    };

    onFormChange({ email, password });
    setFormData(newFormData);
  };

  const handleRecaptchaChange = (value) => {
    const newFormData = {
      ...formData,
      recaptchaValue: value,
    };

    setFormData(newFormData);
  };

  const handleLoginWithIdentityProvider = (event) => {
    event.preventDefault();
    onLoginWithIdentityProvider();
  };

  const handleSubmit = (event) => {
    const newFormData = {
      ...formData,
      password: '',
    };

    setFormData(newFormData);
    event.preventDefault();
    onSubmit(formData);
  };

  const transformError = (error) => {
    if (error.i18nKey) {
      return translate(error.i18nKey);
    }

    if (error.code) {
      return loginErrorCodeTranslator(error) || error.text;
    }

    return error.text;
  };

  let alerts;
  if (errors) {
    alerts = errors
      // To support backward compatibility. Wrap an error if it's a string
      .map((text) => (typeof text === 'string' && text ? { text } : text))
      .filter((error) => error.i18nKey || (typeof error.text === 'string' && error.text))
      .map(transformError)
      .filter(Boolean)
      .map((text, index) => (
        <Alert severity="warning" key={index}>
          {text}
        </Alert>
      ));
  }

  return (
    <Form onSubmit={handleSubmit} noValidate={true} data-test-id={LoginForm.TestSelector.Form}>
      <FormHeader className={classes.header}>
        {showFormTitle && <FormTitle>{translate(I18nKey.LoginWithShutterstock)}</FormTitle>}
      </FormHeader>
      <FormBody>
        <div className={classes.inputWrapper}>
          {alerts && <div className={classes.alertWrapper}>{alerts}</div>}
          <CredentialsInput
            email={formData.email}
            password={formData.password}
            showErrors={false}
            showPassword={true}
            onChange={handleCredentialsChange}
            translations={{ emailLabel: translate(I18nKey.UsernameOrEmailAddress) }}
            formValidation={credentialsFormValidation}
            formData={credentialsFormData}
            validateForm={credentialsValidateForm}
            errors={credentialsErrors}
          />
          <AnalyticsTracker.OnClick eventLabel="clickForgotPassword">
            <ForgotPasswordLink />
          </AnalyticsTracker.OnClick>
          {showRecaptcha && recaptchaSiteKey && (
            <div className={classes.recaptchaWrapper}>
              <Recaptcha sitekey={recaptchaSiteKey} render="explicit" hl={language} onChange={handleRecaptchaChange} />
            </div>
          )}
        </div>
        <FormAction>
          <div className={classes.bottomSpacingMd}>
            <AnalyticsTracker.OnClick eventLabel={'clickLogin'}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
                data-test-id={LoginForm.TestSelector.Submit}>
                {translate(I18nKey.LogIn)}
              </Button>
            </AnalyticsTracker.OnClick>
          </div>
          {showLoginWithSso && (
            <div className={classes.bottomSpacingSm}>
              <AnalyticsTracker.OnClick eventLabel={'clickLoginWithIdentityProvider'}>
                <Button
                  variant="outlined"
                  fullWidth
                  disabled={loading}
                  onClick={handleLoginWithIdentityProvider}
                  data-test-id={LoginForm.TestSelector.LoginWithIdentityProvider}>
                  {translate(I18nKey.LoginInWithSsoLink)}
                </Button>
              </AnalyticsTracker.OnClick>
            </div>
          )}
          {showSocialLogin && (
            <SocialLogin openSocialLoginInNewTab={openSocialLoginInNewTab} nextUrl={nextUrl} analyticsVariant="Login" />
          )}
        </FormAction>
        {showCreateUserLink && (
          <>
            <TextDivider>{translate(I18nKey.OptionOr)}</TextDivider>
            <CreateUserCta disabled={loading} />
          </>
        )}
        {showRequestDemoLink && (
          <>
            <TextDivider>{translate(I18nKey.OptionOr)}</TextDivider>
            <RequestDemo />
          </>
        )}
      </FormBody>
    </Form>
  );
};

LoginForm.TestSelector = {
  Form: 'login-form',
  Submit: 'login-form-submit-button',
  LoginWithIdentityProvider: 'login-form-login-with-identity-provider',
};
