import { nextUrlVar } from '../../../application-state';
import { useReactiveVar } from '@apollo/client';

export const InternalEmployeeIdentityProviderEntrypointLink = () => {
  const nextUrl = useReactiveVar(nextUrlVar);

  const searchParams = new URLSearchParams();

  if (nextUrl) {
    searchParams.set('next', nextUrl);
  }

  window.location.assign(`/saml/shuttercorp-okta/login?${String(searchParams)}`);

  return null;
};
