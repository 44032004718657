import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { RequestDemoLink } from './RequestDemoLink';
import classes from './RequestDemo.module.scss';

export const RequestDemo = () => {
  const { t: translate } = useTranslation();

  return (
    <div data-test-id={RequestDemo.TestSelector.container}>
      <div className={classes.prompt}>{translate(I18nKey.RequestDemoPrompt)}</div>
      <RequestDemoLink />
    </div>
  );
};

RequestDemo.TestSelector = {
  container: 'request-demo-container',
};
