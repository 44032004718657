import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { AnalyticsTracker } from '../../analytics/trackers';
import { languageVar, useActions } from '../../application-state';
import { LanguageMenu } from '../LanguageMenu';
import { supportedLanguages } from '../i18n';

const languageMenuOptions = supportedLanguages.map(({ name, code }) => ({ name, code, setLanguage: true, href: '' }));

export function LanguageMenuContainer() {
  const { changeLanguage = () => {} } = useActions();
  const language = useReactiveVar(languageVar);

  return (
    <AnalyticsTracker.OnClick eventLabel="clickLanguage">
      <LanguageMenu
        options={languageMenuOptions}
        multiCol={true}
        label={language.name}
        activeLanguageCode={language.code}
        updateUserAccountLanguage={changeLanguage}
        setUserAccountLanguage={true}
      />
    </AnalyticsTracker.OnClick>
  );
}
