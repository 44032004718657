/**
 * MUI Button x Shutterstock.com Theme
 * Exports an object to be passed into createTheme()
 *
 * Override keys: https://material-ui.com/api/button/#css
 *
 * TODO: Cleanup magic number constants
 * -- These values match Studio components' design spec, but have no ties to design-tokens
 * -- Please do not expose these values for use outside of this file
 * -- We will revisit updating both Studio and these in the future to align the component
 *    specs globally with the Shutterstock Design System
 *
 * ALSO: Color values should not be present in this base theme
 * -- Those values may be added to a color theme, ex: mui-theme-light, mui-theme-dark
 */
import makeStyles from '@mui/styles/makeStyles';

import { fontSize, radius, size } from '../theme/tokens';

const BUTTON_SMALL_PAD_X = '14px';
const BUTTON_MEDIUM_PAD_X = '40px';
const BUTTON_LARGE_PAD_X = '80px';

const common = {
  height: size.density.high,
  borderRadius: radius.l,
  fontWeight: 'bold',
  textTransform: 'none',
  borderColor: 'rgb(0,0, 0, 0.23)',
};

export const getUseButtonPresets = () =>
  makeStyles(({ palette, typography }) => ({
    buttonSquared: {
      '&&': {
        fontSize: fontSize.xs,
        lineHeight: fontSize.xs,
        borderRadius: radius.m,
      },
    },
    buttonLink: {
      '&&': {
        height: 'auto',
        minWidth: 0,
        padding: 0,
        fontWeight: 'normal',
        borderRadius: radius.m,
      },
    },
    buttonAnchor: {
      height: 'auto',
      padding: 0,
      minWidth: 0,
      display: 'inline-flex',
      fontWeight: typography.fontWeightRegular,
      color: palette.info.main,
      '&:hover': {
        backgroundColor: 'inherit',
        color: palette.text.primary,
      },
      // Disable ripple by default
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  }));

const buttonSmall = {
  fontSize: '15px',
  lineHeight: '15px',
  paddingLeft: BUTTON_SMALL_PAD_X,
  paddingRight: BUTTON_SMALL_PAD_X,
};

const buttonMedium = {
  borderRadius: radius.pill,
  paddingLeft: BUTTON_MEDIUM_PAD_X,
  paddingRight: BUTTON_MEDIUM_PAD_X,
};

const buttonLarge = {
  height: size.density.base,
  lineHeight: fontSize.l,
  fontSize: fontSize.l,
  paddingLeft: BUTTON_LARGE_PAD_X,
  paddingRight: BUTTON_LARGE_PAD_X,
};

export const MuiButtonOverrides = {
  styleOverrides: {
    root: common,
    containedSizeSmall: buttonSmall,
    outlinedSizeSmall: buttonSmall,
    contained: buttonMedium,
    outlined: buttonMedium,
    containedSizeLarge: buttonLarge,
    outlinedSizeLarge: buttonLarge,
    text: {
      ...buttonMedium,
      paddingLeft: BUTTON_SMALL_PAD_X,
      paddingRight: BUTTON_SMALL_PAD_X,
    },
  },
  defaultProps: {
    disableElevation: true,
    color: 'black',
  },
};
