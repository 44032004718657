import { useCallback, useState, useEffect } from 'react';

/**
 * A switcher hook
 * @param {Boolean} initValue
 * @returns {[boolean, Function]} - value and a setter
 */
const useToggle = (initValue = false) => {
  const [value, invertValue] = useState(initValue);

  const toggle = useCallback(
    ({ override } = {}) => invertValue((state) => (override === undefined ? !state : override)),
    [],
  );

  return [value, toggle];
};

/**
 * Allows to debounce a setter
 * @param {any} value - current value
 * @param {number} delay - debounce delay
 */
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export { useToggle, useDebounce };
