export const interceptResponse = () => (response) => response;

/**
 * Notifies an unexpected error if config doesn't have a notifyUnexpectedError = false property
 * for example this.http.get('/web/api/any-api', { notifyUnexpectedError: false })
 */
export const interceptResponseError = (notifyUnexpectedError) => (error) => {
  const { status } = error.response;
  const { notifyUnexpectedError: shouldNotifyUnexpectedError = true } = error.config || {};

  if (status >= 500 && shouldNotifyUnexpectedError) {
    notifyUnexpectedError();
  }

  return Promise.reject(error);
};

export const interceptRequest =
  ({ originalAnalyticsWrapper }) =>
  (config) => {
    const anonymousUserId = originalAnalyticsWrapper.getAnonId();
    const sessionId = originalAnalyticsWrapper.getSessionId();
    config.headers['x-end-user-anonymous-id'] = anonymousUserId;
    config.headers['x-end-stck-session-id'] = sessionId;
    return config;
  };
