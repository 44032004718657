import React from 'react';
import { useApplicationConfig } from '../../application-state';
import { ShutterstockLogo } from '../ShutterstockLogo';
import { ShutterstockEnterpriseLogo } from '../ShutterstockEnterpriseLogo';
import { ShutterstockContributorLogo } from '../ShutterstockContributorLogo';

const logos = {
  Shutterstock: ShutterstockLogo,
  Enterprise: ShutterstockEnterpriseLogo,
  Contributor: ShutterstockContributorLogo,
};

export const Logo = () => {
  const config = useApplicationConfig();

  const LogoComponent = logos[config['logo.component']];

  if (LogoComponent) {
    return <LogoComponent />;
  }

  return <ShutterstockLogo />;
};
