import React from 'react';
import { useApplicationConfig } from '../../../application-state';
import { FormPagePanelSection } from '../../../core-components';

export const WwwFormPagePanelSection = ({ children, className, fullWidth }) => {
  const { showFormBorders } = useApplicationConfig();

  return (
    <FormPagePanelSection className={className} fullWidth={fullWidth} showBorder={showFormBorders}>
      {children}
    </FormPagePanelSection>
  );
};
