import React, { useCallback } from 'react';
import { useAnalytics } from '../analytics';

/**
 * OnClick analytics tracker hook
 * @param {Object} params
 * @param {String=} params.name - event name
 * @param {String} params.eventLabel - event label
 * @param {String=} params.pageSection - pageSection, default form
 */
const useOnClickTracker = ({ name, eventLabel, pageSection = 'form' }) => {
  const analytics = useAnalytics();

  const onClickTracker = useCallback(() => {
    analytics.click({
      name,
      eventLabel,
      pageSection,
      eventAction: 'click',
    });
  }, [analytics, name, eventLabel, pageSection]);

  return onClickTracker;
};

/**
 * OnClick analytics tracker wrapper
 * @param {Object} params
 * @param {JSX.Element} params.children
 * @param {String=} params.name - event name
 * @param {String} params.eventLabel - event label
 * @param {String=} params.pageSection - pageSection, default form
 */
const OnClick = ({ children, name, eventLabel, pageSection }) => {
  const onAnalyticsClick = useOnClickTracker({
    name,
    eventLabel,
    pageSection,
  });

  return (
    <span onClick={onAnalyticsClick} data-type="analytics">
      {children}
    </span>
  );
};

export { OnClick, useOnClickTracker };
