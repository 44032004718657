import { I18nKey } from '../../i18n';

export const validationErrorMessageByType = {
  'any.empty': I18nKey.ErrorMissingRequiredField,
  'any.required': I18nKey.ErrorMissingRequiredField,
  'string.empty': I18nKey.ErrorMissingRequiredField,
  'string.email': I18nKey.ErrorInvalidEmail,
};

export const validationErrorMessageByKeyAndType = {
  password: {
    'string.empty': I18nKey.ErrorMissingRequiredField,
    'string.min': I18nKey.ErrorPasswordMinLength,
  },
  fullName: {
    'string.max': I18nKey.ValueUserFullNameMaxLength,
  },
  displayName: {
    'string.max': I18nKey.ValueUserDisplayNameMaxLength,
  },
  agreeToTos: {
    'any.invalid': I18nKey.PleaseAgreeToTosShort,
  },
};

export const getValidationErrorMessage = (key, type, translate) => {
  return translate((validationErrorMessageByKeyAndType[key] || {})[type] || validationErrorMessageByType[type]);
};

export const formatValidationErrorMessagesByKey = (error, translate, getValidationErrorMessageCustom) => {
  if (!error) return;
  return error.details
    .map(({ context: { key }, type }) => ({
      key,
      errorMessage: getValidationErrorMessageCustom
        ? getValidationErrorMessageCustom(key, type, translate)
        : getValidationErrorMessage(key, type, translate),
    }))
    .filter(({ errorMessage }) => errorMessage)
    .reduce((errorMessages, { key, errorMessage }) => {
      errorMessages[key] = [...(errorMessages[key] || []), errorMessage];
      return errorMessages;
    }, {});
};

export const getValidationErrorForDirtyFields = (error, state) => {
  if (!error) return;
  return {
    details: error.details.reduce((acc, item) => {
      if (state[item.context.key]?.meta.dirty) {
        acc.push(item);
      }
      return acc;
    }, []),
  };
};
