import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { I18nKey } from '../../i18n';
import { notFound } from '../../utils/http-codes';
import { AnalyticsTracker } from '../../analytics/trackers';
import { EmailInput, Form, FormAction, FormBody, FormHeader, FormTitle } from '../../core-components';
import classes from './IdentityProviderLoginForm.module.scss';

const noop = () => {};

export const IdentityProviderLoginForm = ({
  errors,
  initialEmail = '',
  autoFocus = true,
  loading = false,
  onFormChange = noop,
  onSubmit = noop,
  onDismiss = noop,
}) => {
  const { t: translate } = useTranslation();
  const [emailOrUsername, setEmailOrUsername] = useState(initialEmail);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ emailOrUsername });
  };

  const handleDismiss = (event) => {
    event.preventDefault();
    onDismiss();
  };

  const notFoundError = (
    <span>
      {/* prettier-ignore */}
      <Trans i18nKey={I18nKey.SsoLoginUserNotfound}>
        Your company domain is not currently registered for SSO access. Contact <a href="mailto:enterpriseintegrations@shutterstock.com">enterpriseintegrations@shutterstock.com</a> to enable
        Single Sign-On for your account.
      </Trans>
    </span>
  );

  let alert;

  if (errors) {
    const [error] = errors;

    alert = (
      <div className={classes.alertWrapper}>
        <Alert severity="warning">{error.statusCode === notFound ? notFoundError : translate(I18nKey.WentWrong)}</Alert>
      </div>
    );
  }

  return (
    <Form onSubmit={handleSubmit} noValidate={true} data-test-id={IdentityProviderLoginForm.TestSelector.Form}>
      <FormHeader>
        <FormTitle>{translate(I18nKey.LoginInWithSsoLink)}</FormTitle>
      </FormHeader>
      <FormBody>
        {alert}
        <AnalyticsTracker.OnClick eventLabel="clickEmail">
          <EmailInput
            value={emailOrUsername}
            label={translate(I18nKey.EmailAddress)}
            autoFocus={autoFocus}
            autoComplete="email"
            inputProps={{ 'data-test-id': IdentityProviderLoginForm.TestSelector.EmailInput }}
            onChange={(value) => {
              setEmailOrUsername(value);
              onFormChange({ email: value });
            }}
          />
        </AnalyticsTracker.OnClick>
        <FormAction>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={!emailOrUsername || loading}
            data-test-id={IdentityProviderLoginForm.TestSelector.Submit}>
            {translate(I18nKey.Continue)}
          </Button>
        </FormAction>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="" onClick={handleDismiss} data-test-id={IdentityProviderLoginForm.TestSelector.Dismiss}>
          {translate(I18nKey.GoBack)}
        </a>
      </FormBody>
    </Form>
  );
};

IdentityProviderLoginForm.TestSelector = {
  Dismiss: 'identity-provider-login-form-dismiss',
  EmailInput: 'identity-provider-login-form-email-input',
  Form: 'identity-provider-login-form',
  Submit: 'identity-provider-login-form-submit',
};
