import { color } from '../theme/tokens';

export const MuiInputLabelOverrides = {
  styleOverrides: {
    root: {
      '&.Mui-focused': {
        color: `${color.blue.azure}`,
      },
    },
  },
};
