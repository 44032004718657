import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import {
  errorsVar,
  formInputErrorsVar,
  invitationFlowVar,
  languageVar,
  marketingEmailOptInVar,
  nextUrlVar,
  prefillEmailVar,
  recaptchaSiteKeyVar,
  referrerVar,
  showRecaptchaVar,
  ssoVar,
  useActions,
  useApi,
  useApplicationConfig,
  userVar,
} from '../../application-state';
import { I18nKey } from '../../i18n';
import { CreateUserForm } from './CreateUserForm';

export const CreateUser = ({
  showDisplayName,
  showFullName,
  showPassword = true,
  showFormTitle = true,
  showSocialLogin = true,
  openLinksInNewTab,
  openSocialLoginInNewTab,
  invitation = null,
}) => {
  const { t: translate } = useTranslation();

  const config = useApplicationConfig();

  const { createUser } = useActions();
  const api = useApi();

  const errors = useReactiveVar(errorsVar);
  const formInputErrors = useReactiveVar(formInputErrorsVar);
  const invitationFlow = useReactiveVar(invitationFlowVar);
  const language = useReactiveVar(languageVar);
  const marketingEmailOptIn = useReactiveVar(marketingEmailOptInVar);
  const nextUrl = useReactiveVar(nextUrlVar);
  const prefillEmail = useReactiveVar(prefillEmailVar);
  const referrer = useReactiveVar(referrerVar);
  const showRecaptcha = useReactiveVar(showRecaptchaVar);
  const recaptchaSiteKey = useReactiveVar(recaptchaSiteKeyVar);
  const sso = useReactiveVar(ssoVar);
  const user = useReactiveVar(userVar);

  let disableEmail = invitationFlow;
  const ssoFlow = sso.isSso && sso.isSsoRequested;
  let ssoUseProfileEmail = false;

  if (ssoFlow) {
    showPassword = false;
    // IDN-4388 - disable email editing for apple private emails
    if (sso.identityProviderName === 'apple' && sso.profile.email && sso.profile.is_email_private) {
      disableEmail = true;
      ssoUseProfileEmail = true;
    }
  }

  const tosAction = config['createUser.termsOfService.action'];
  const tosFieldName = config['createUser.termsOfService.name'];
  const tosFieldValue = config['createUser.termsOfService.value'];

  const translations = {
    submitButtonText: translate(config['createUser.formSubmitI18nKey']),
    formTitle: translate(config['createUser.formTitleI18nKey']),
  };

  if (invitation) {
    translations.formTitle = (
      <Trans i18nKey={I18nKey.CreateYourPremierAccount}>Set up your {invitation.organization.company} account</Trans>
    );
  }

  const handleSubmit = ({
    email,
    password,
    marketingEmailOptIn,
    agreeToTos,
    valid,
    recaptchaValue,
    displayName,
    fullName,
  }) => {
    if (valid) {
      createUser(ssoUseProfileEmail ? sso.profile.email : email, password, {
        isSsoFlow: ssoFlow,
        identityProviderName: sso.identityProviderName,
        marketingEmailOptIn,
        implicitAgreeTOS: tosAction === 'implicit',
        tosPayload:
          tosAction === 'explicit' && agreeToTos && tosFieldName && tosFieldValue
            ? { [tosFieldName]: tosFieldValue }
            : undefined,
        nextUrl,
        recaptchaValue,
        ...(showDisplayName ? { displayName } : {}),
        ...(showFullName ? { fullName } : {}),
      });
    }
  };

  return (
    <CreateUserForm
      disableEmail={disableEmail}
      validateDisplayName={api.validateDisplayName.bind(api)}
      showDisplayName={showDisplayName}
      showFullName={showFullName}
      language={language.code}
      loading={user.creating}
      email={invitation ? invitation.value : prefillEmail}
      webPropertyDisplayName={referrer.displayName}
      showRecaptcha={showRecaptcha}
      recaptchaSiteKey={recaptchaSiteKey}
      showMarketingEmailOptIn={marketingEmailOptIn.show}
      showSocialLogin={showSocialLogin}
      openLinksInNewTab={openLinksInNewTab}
      openSocialLoginInNewTab={openSocialLoginInNewTab}
      errors={errors}
      inputErrors={formInputErrors}
      termsOfServiceAction={tosAction}
      showPassword={showPassword}
      showFormTitle={showFormTitle}
      translations={translations}
      onSubmit={(data) => handleSubmit(data)}
    />
  );
};
