import { useLayoutEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import { useApplicationConfig } from '../../application-state';
import { EMBEDDED_MIN_HEIGHT, EMBEDDED_MIN_WIDTH, EMBEDDED_MOBILE_WIDTH_BREAKPOINT } from './constants';

export const windowSizeMonitorAlertTestId = 'window-size-monitor-alert';

const alertMessage =
  () => `Embedded mode requires a minimum container size of ${EMBEDDED_MIN_WIDTH} width by ${EMBEDDED_MIN_HEIGHT} height to prevent overflow. The current
  container dimensions are: ${window.innerWidth} width, ${window.innerHeight} height. Please update your configuration for the
  minimum dimensions.`;

export const WindowSizeMonitor = ({ children, showUiDeveloperWarnings }) => {
  const { 'embedded.warnEmbeddedWindowSize': warnEmbeddedWindowSize } = useApplicationConfig();
  const [showAlert, setShowAlert] = useState(false);

  useLayoutEffect(() => {
    const updateSize = () => {
      const isQAorDevEnv = ['dev.', 'qa.'].some((env) => window?.location?.hostname?.includes(env));
      if (
        warnEmbeddedWindowSize &&
        (!isQAorDevEnv || window.screen.width > EMBEDDED_MOBILE_WIDTH_BREAKPOINT) &&
        (window.innerWidth < EMBEDDED_MIN_WIDTH || window.innerHeight < EMBEDDED_MIN_HEIGHT)
      ) {
        console.error(alertMessage());
        return setShowAlert(true);
      }
      setShowAlert(false);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [warnEmbeddedWindowSize]);

  return (
    <>
      {showAlert && showUiDeveloperWarnings && (
        <Alert data-test-id={windowSizeMonitorAlertTestId} severity="error">
          {alertMessage()}
        </Alert>
      )}
      {children}
    </>
  );
};
