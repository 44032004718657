import contributor from './contributor';
import embeddedIframe from './embedded-iframe';
import enterprise from './enterprise';
import googleOneTap from './google-one-tap';
import shutterstock from './shutterstock';
import creative from './creative';
import creativeEmbeddedSignup from './creative-embedded-signup';
import expCreativeEmbedded from '../lab/$presets/exp-creative-embedded';
import exp817 from '../lab/$presets/exp817';
import p31193 from '../lab/$presets/p31193';
import cvrt846 from '../lab/$presets/cvrt846';
import cvrt846_v2 from '../lab/$presets/cvrt846_v2';
import cvrt837 from '../lab/$presets/cvrt837';
import cvrt911 from '../lab/$presets/cvrt911';
import ImageAdpConversionPanel from '../lab/$presets/ImageAdpConversionPanel';
import freetrialflex10 from './freetrialflex10';
import p31644 from '../lab/$presets/p31644';
import helpRequest from './help-request';
import freetrialflex10variantA from '../lab/$presets/freetrialflex10variantA';
import freetrialflex10variantB from '../lab/$presets/freetrialflex10variantB';

export const presets = {
  contributor,
  embeddedIframe,
  enterprise,
  shutterstock,
  creative,
  'creative-embedded-signup': creativeEmbeddedSignup,
  'exp-creative-embedded': expCreativeEmbedded,
  'google-one-tap': googleOneTap,
  exp817,
  p31193,
  cvrt846,
  cvrt846_v2,
  cvrt837,
  cvrt911,
  ImageAdpConversionPanel,
  freetrialflex10,
  p31644,
  'help-request': helpRequest,
  freetrialflex10variantA,
  freetrialflex10variantB,
};

export default presets;
