import { useReactiveVar } from '@apollo/client';
import Button from '@mui/material/Button';
import propTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { signupPathVar } from '../../application-state';
import { I18nKey } from '../../i18n';

export const CreateUserLink = ({ disabled, path }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const signupPath = useReactiveVar(signupPathVar);

  const resolvedSignupPath = path || signupPath || '/users/new';

  function handleClick(e) {
    e.preventDefault();
    navigate(resolvedSignupPath);
  }

  return (
    <Button
      href={resolvedSignupPath}
      variant="outlined"
      fullWidth
      disabled={disabled}
      onClick={handleClick}
      data-test-id="create-user-btn">
      {translate(I18nKey.CreateYourAccount)}
    </Button>
  );
};

CreateUserLink.propTypes = {
  path: propTypes.string,
};
