import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n';

export const GenericError = ({ title, statusCode, alphaCode, description }) => {
  const { t: translate } = useTranslation();

  const descriptionSections = (description ?? '').split('\n');

  return (
    <div>
      {title && <h1 style={{ fontSize: '1.75rem', marginBottom: '15px', textAlign: 'center' }}>{title}</h1>}
      <strong>
        {(statusCode || alphaCode) && (
          <span>
            {translate(I18nKey.Error)}
            {statusCode && <>&nbsp;{statusCode}</>}
            {alphaCode && <>:&nbsp;</>}
            {alphaCode && <>{alphaCode}</>}
          </span>
        )}
      </strong>
      {descriptionSections.length && (
        <div style={{ overflowWrap: 'break-word' }}>
          {descriptionSections.map((descriptionSection, index) => (
            <div key={index} style={{ marginBottom: '15px' }}>
              {descriptionSection}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
