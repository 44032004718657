import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { FacebookLogo } from '../../brand';
import { TextHideOverflow } from '../../core-components';
import classes from './FacebookLogin.module.scss';

export const FacebookLogin = ({ openSocialLoginInNewTab = false, nextUrl = '' }) => {
  const { t: translate } = useTranslation();

  const queryParams = new URLSearchParams([['next', nextUrl]]);
  const href = `/sso/facebook/login?${String(queryParams)}`;

  return (
    <Button variant="outlined" href={href} target={openSocialLoginInNewTab ? 'socialsignon' : '_self'} fullWidth>
      <div className={classes.buttonContent}>
        <div className={classes.logo}>
          <FacebookLogo />
        </div>
        <TextHideOverflow>{translate(I18nKey.ContinueWithFacebook)}</TextHideOverflow>
      </div>
    </Button>
  );
};
