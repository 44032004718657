import React from 'react';
import { useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { I18nKey } from '../../i18n';
import { FormPagePanel } from '../../core-components';
import { WwwFormPage, WwwFormPagePanelSection } from '../../www/page';
import { SsoConfirmForm } from '../../account';
import { csrfTokenVar, nextUrlVar, ssoVar, userVar } from '../../application-state';
import { usePageViewAnalytics } from '../../analytics';

export const SsoConfirmPage = () => {
  const { identityProviderName } = useParams();

  usePageViewAnalytics('sso-confirmation');

  const csrfToken = useReactiveVar(csrfTokenVar);
  const nextUrl = useReactiveVar(nextUrlVar);
  const sso = useReactiveVar(ssoVar);
  const user = useReactiveVar(userVar);

  // If the user is not in the SSO flow, redirect to the next location.
  if (!sso.isSso) {
    window.location.replace(nextUrl);
    return null;
  }

  return (
    <div data-test-page="www-sso-confirm-page">
      <WwwFormPage pageTitleI18nKey={I18nKey.ConfirmAccountLink} contentPlacement="center">
        <FormPagePanel>
          <WwwFormPagePanelSection>
            <SsoConfirmForm
              sso={sso}
              identityProviderEmail={(sso.profile && sso.profile.email) || ''}
              user={user}
              identityProviderName={identityProviderName}
              identityProviderDescription={sso.identityProviderDescription}
              csrfToken={csrfToken}
              nextUrl={nextUrl}
            />
          </WwwFormPagePanelSection>
        </FormPagePanel>
      </WwwFormPage>
    </div>
  );
};
