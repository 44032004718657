import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';

export const ForgotPasswordLink = ({ path, routerLink }) => {
  const { t: translate } = useTranslation();

  let link;
  if (routerLink) {
    link = (
      <Link to={path} data-test-id="forgot-password-link">
        {translate(I18nKey.ForgotPassword)}
      </Link>
    );
  } else {
    link = (
      <a href={path} data-test-id="forgot-password-link">
        {translate(I18nKey.ForgotPassword)}
      </a>
    );
  }

  return link;
};

ForgotPasswordLink.propTypes = {
  path: propTypes.string,
  routerLink: propTypes.bool,
};

ForgotPasswordLink.defaultProps = {
  path: '/credentials/forgot',
  routerLink: true,
};
