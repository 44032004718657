import { useTranslation, Trans } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { I18nKey } from '../../../../../i18n';
import { ValueProps, ValueProp } from '../../../../../brand';
import classes from '../../../../../customization/CreateUserValueProps/CreateUserValuePropsTrialImage/CreateUserValuePropsTrialImage.module.scss';

const CreateUserValuePropsTrialImage15DayTrial = () => {
  const { t: translate } = useTranslation();

  return (
    <div className={classes.panelWrapper}>
      <ValueProps title={translate(I18nKey.ImageNewUserTitle15DayTrial)}>
        <div className={classes.noFreeTrial}>
          <i>{translate(I18nKey.IndiaNoFreeTrialMessage)}*</i>
        </div>
        <ValueProp Icon={<ViewQuiltIcon />}>
          <Trans i18nKey={I18nKey.ImageCreateUserGreetingBulletFullNew}>
            <b>Full access:</b> Access over 350 million assets, plus new design tools, creative analytics, and content
            planner.
          </Trans>
        </ValueProp>
        <ValueProp Icon={<PersonOutlineOutlinedIcon />}>
          <Trans i18nKey={I18nKey.ImageCreateUserGreetingBulletRiskFree15Days}>
            <b>Risk-free:</b> Cancel with just a few clicks anytime within the first fifteen days and pay nothing.
          </Trans>
        </ValueProp>
        <ValueProp Icon={<CheckCircleOutlineIcon />}>
          <Trans i18nKey={I18nKey.ImageCreateUserGreetingBulletSave15Days}>
            <b>Save more:</b> Get your first 15 days free, then enjoy an annual subscription for just $29/mo.
          </Trans>
        </ValueProp>
        <ValueProp Icon={<ListAltOutlinedIcon />}>
          <Trans i18nKey={I18nKey.ImageCreateUserGreetingBulletKeepNew}>
            <b>Keep what you’ve licensed:</b> Even if you cancel, you can keep the images you've downloaded.
          </Trans>
        </ValueProp>
      </ValueProps>
      <div className={classes.panelLegalNote}>
        {/* prettier-ignore */}
        <Trans i18nKey={I18nKey.ImageCreateUserLegalNote}>
        To cancel during your free trial period, visit the  <a href="https://www.shutterstock.com/account/plans">Plans page</a>. If you don't cancel within the free
        trial period, your annual commitment will begin and you will be <a href="https://support.shutterstock.com/s/article/How-do-Shutterstock-Image-subscription-plans-work?language=en_US">
          charged monthly
        </a>. If you cancel after the free trial period ends and before your first year is up, an early cancellation fee may apply. Your plan will
        automatically renew every year unless you <a href="https://www.shutterstock.com/help">contact us</a>. Limit 1 free trial per person. This promotion may
        not be combined with any other offers, coupons, or discounts.
      </Trans>
      </div>
    </div>
  );
};

export default CreateUserValuePropsTrialImage15DayTrial;
