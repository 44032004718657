import { makeVar } from '@apollo/client';

/**
 * The currently selected authentication identifier.
 */
export const authenticationIdentifierVar = makeVar(null);

/**
 * The unique identifier for the current interaction.
 */
export const interactionUidVar = makeVar(null);
