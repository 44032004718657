import { useEffect } from 'react';

export function useComponentWillUnmount(fn = () => {}) {
  const effect = () => fn;
  // Passing an array as the second argument will cause React to
  // invoke the callback passed to `useEffect` only once during
  // the initial render. The returned function will be invoked
  // when the component is unmounted.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}
