import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import classes from './ShutterstockContributorLogo.module.scss';

export const ShutterstockContributorLogoComponent = (props) => {
  return (
    <svg
      className={classes.root}
      data-test-id={ShutterstockContributorLogoComponent.TestSelector.container}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 259 21"
      fill="none">
      <path
        d="M146.424 16.5216C146.602 16.6114 146.692 16.791 146.602 16.9706C145.978 18.8562 144.372 20.0235 141.695 20.0235C138.75 20.0235 136.52 18.2276 136.52 14.5462V10.5954C136.52 7.0037 138.84 5.11808 141.873 5.11808C144.461 5.11808 146.156 6.55474 146.602 8.53016C146.692 8.79953 146.602 8.97912 146.335 9.06891L145.442 9.33828C145.264 9.42807 145.086 9.42807 145.086 9.1587C144.818 7.90162 143.747 6.64454 141.784 6.64454C139.732 6.64454 138.126 7.90162 138.126 10.8647V14.3666C138.126 17.2399 139.553 18.6766 141.784 18.6766C143.747 18.6766 144.818 17.6889 145.175 16.4318C145.264 16.0726 145.532 16.1624 145.71 16.2522L146.424 16.5216Z"
        fill="#25282A"
      />
      <path
        d="M160.433 14.6361C160.433 17.9583 158.381 20.1133 154.812 20.1133C151.332 20.1133 149.28 18.1379 149.28 14.8156V10.6852C149.28 7.27316 151.421 5.20795 154.812 5.20795C158.47 5.20795 160.433 7.27316 160.433 10.6852V14.6361ZM158.827 10.775C158.827 7.9017 157.399 6.55483 154.901 6.55483C152.581 6.55483 150.975 7.9017 150.975 10.775V14.4565C150.975 17.3298 152.403 18.5869 154.901 18.5869C157.399 18.5869 158.827 17.24 158.827 14.3667V10.775Z"
        fill="#25282A"
      />
      <path
        d="M172.746 19.7541C172.568 19.7541 172.479 19.7541 172.479 19.4847V10.326C172.479 8.17099 171.943 6.55474 169.623 6.55474C167.928 6.55474 166.233 7.90162 165.43 9.51786V19.5745C165.43 19.7541 165.34 19.8439 165.073 19.8439H164.091C163.913 19.8439 163.734 19.8439 163.734 19.5745V5.65683C163.734 5.47725 163.824 5.38745 164.091 5.38745H165.073C165.251 5.38745 165.34 5.38745 165.34 5.65683V7.72203C166.411 6.28537 168.017 5.11808 169.98 5.11808C172.746 5.11808 173.996 7.0037 173.996 9.78724V19.5745C173.996 19.7541 173.906 19.8439 173.728 19.8439H172.746V19.7541Z"
        fill="#25282A"
      />
      <path
        d="M182.561 19.7542C179.974 19.7542 178.635 19.0358 178.635 15.8033V7.18335H176.762C176.583 7.18335 176.494 7.09356 176.494 6.91398V6.10585C176.494 5.92627 176.494 5.83648 176.762 5.83648H178.635V2.51419C178.635 2.33461 178.725 2.24482 178.992 2.24482H179.974C180.152 2.24482 180.242 2.33461 180.242 2.51419V5.92627H183.186C183.365 5.92627 183.454 6.01606 183.454 6.19565V7.00377C183.454 7.18335 183.454 7.27314 183.186 7.27314H180.242V16.0727C180.242 18.0481 181.045 18.4073 182.561 18.4073H183.454C183.721 18.4073 183.811 18.4971 183.811 18.6767V19.395C183.811 19.6644 183.721 19.7542 183.454 19.7542H182.561Z"
        fill="#25282A"
      />
      <path
        d="M187.202 19.7541C187.023 19.7541 186.845 19.7541 186.845 19.4847V5.65683C186.845 5.47725 187.023 5.38745 187.202 5.38745H188.183C188.361 5.38745 188.451 5.47725 188.451 5.65683V8.44037C189.254 6.73433 191.128 5.11808 193.001 5.11808C193.358 5.11808 193.358 5.20787 193.358 5.47725L193.18 6.55474C193.18 6.73433 193.001 6.82412 192.912 6.82412C192.644 6.82412 192.466 6.82412 192.377 6.82412C190.503 7.0037 188.986 8.70974 188.629 10.1464V19.4847C188.629 19.6643 188.451 19.7541 188.272 19.7541H187.202Z"
        fill="#25282A"
      />
      <path
        d="M196.124 3.1427C195.946 3.1427 195.767 3.05291 195.767 2.78354V0.359166C195.767 0.179583 195.857 0 196.124 0H197.106C197.373 0 197.463 0.0897914 197.463 0.359166V2.78354C197.463 3.05291 197.284 3.1427 197.106 3.1427H196.124ZM196.124 19.7541C195.946 19.7541 195.767 19.6643 195.767 19.395V5.74666C195.767 5.47728 195.946 5.38749 196.124 5.38749H197.106C197.284 5.38749 197.463 5.47728 197.463 5.74666V19.395C197.463 19.6643 197.284 19.7541 197.106 19.7541H196.124Z"
        fill="#25282A"
      />
      <path
        d="M212.007 14.2769C212.007 17.9583 209.687 20.0235 206.832 20.0235C204.512 20.0235 203.263 18.8562 202.727 17.8685V19.4848C202.727 19.6643 202.549 19.7541 202.37 19.7541H201.389C201.21 19.7541 201.121 19.7541 201.121 19.4848V0.269375C201.121 0.0897915 201.21 0 201.389 0H202.46C202.638 0 202.727 -5.35199e-08 202.727 0.269375V7.90165C203.352 6.82416 204.869 5.20791 207.099 5.20791C210.133 5.20791 211.829 7.09353 211.829 10.775V14.2769H212.007ZM210.401 10.8648C210.401 8.17103 209.419 6.55478 207.01 6.55478C204.869 6.55478 202.816 8.4404 202.816 11.0444V14.9952C202.816 17.1502 204.423 18.5868 206.653 18.5868C208.884 18.5868 210.401 17.3298 210.401 14.0973V10.8648Z"
        fill="#25282A"
      />
      <path
        d="M224.321 19.7541C224.142 19.7541 223.964 19.7541 223.964 19.4847V17.5991C222.982 18.7664 221.198 20.0235 219.056 20.0235C216.736 20.0235 215.219 18.497 215.219 15.8032V5.6568C215.219 5.47722 215.309 5.38743 215.487 5.38743H216.558C216.736 5.38743 216.825 5.38743 216.825 5.6568V15.7135C216.825 17.5991 217.807 18.6766 219.324 18.6766C221.019 18.6766 222.625 17.7787 223.875 16.2522V5.6568C223.875 5.47722 223.964 5.38743 224.142 5.38743H225.213C225.391 5.38743 225.481 5.38743 225.481 5.6568V19.5745C225.481 19.7541 225.391 19.8439 225.213 19.8439H224.321V19.7541Z"
        fill="#25282A"
      />
      <path
        d="M234.403 19.7542C231.816 19.7542 230.477 19.0358 230.477 15.8033V7.18335H228.604C228.425 7.18335 228.336 7.09356 228.336 6.91398V6.10585C228.336 5.92627 228.336 5.83648 228.604 5.83648H230.477V2.51419C230.477 2.33461 230.567 2.24482 230.834 2.24482H231.816C231.994 2.24482 232.084 2.33461 232.084 2.51419V5.92627H235.028C235.207 5.92627 235.296 6.01606 235.296 6.19565V7.00377C235.296 7.18335 235.296 7.27314 235.028 7.27314H232.084V16.0727C232.084 18.0481 232.887 18.4073 234.403 18.4073H235.296C235.563 18.4073 235.653 18.4971 235.653 18.6767V19.395C235.653 19.6644 235.563 19.7542 235.296 19.7542H234.403Z"
        fill="#25282A"
      />
      <path
        d="M249.037 14.6361C249.037 17.9583 246.985 20.1133 243.415 20.1133C239.936 20.1133 237.883 18.1379 237.883 14.8156V10.6852C237.883 7.27316 240.025 5.20795 243.415 5.20795C247.074 5.20795 249.037 7.27316 249.037 10.6852V14.6361ZM247.342 10.775C247.342 7.9017 245.914 6.55483 243.415 6.55483C241.096 6.55483 239.489 7.9017 239.489 10.775V14.4565C239.489 17.3298 240.917 18.5869 243.415 18.5869C245.914 18.5869 247.342 17.24 247.342 14.3667V10.775Z"
        fill="#25282A"
      />
      <path
        d="M252.695 19.7541C252.517 19.7541 252.338 19.7541 252.338 19.4847V5.65683C252.338 5.47725 252.517 5.38745 252.695 5.38745H253.677C253.855 5.38745 253.944 5.47725 253.944 5.65683V8.44037C254.747 6.73433 256.621 5.11808 258.495 5.11808C258.852 5.11808 258.852 5.20787 258.852 5.47725L258.673 6.55474C258.673 6.73433 258.495 6.82412 258.406 6.82412C258.138 6.82412 257.96 6.82412 257.87 6.82412C255.997 7.0037 254.48 8.70974 254.123 10.1464V19.4847C254.123 19.6643 253.944 19.7541 253.766 19.7541H252.695Z"
        fill="#25282A"
      />
      <path
        d="M78.6103 11.7626C78.4319 11.7626 77.7181 11.583 77.5396 11.583C76.4689 11.4035 75.5766 11.1341 75.5766 10.0566C75.5766 9.15867 76.5581 8.70972 77.7181 8.70972C79.5026 8.70972 81.0195 9.51784 81.198 9.60763L82.1795 7.00368C81.8226 6.82409 80.2165 5.92618 77.7181 5.92618C75.3981 5.92618 72.632 7.09347 72.632 10.1464C72.632 12.8401 74.5058 13.6483 76.3796 14.0074C76.6473 14.0972 77.4504 14.187 77.6288 14.187C78.878 14.3666 79.5026 14.8155 79.5026 15.7135C79.5026 16.7012 78.5211 17.2399 77.0935 17.2399C74.7735 17.2399 73.3459 16.5216 72.989 16.342L71.9182 18.8562C72.2751 19.0357 73.8812 19.9337 76.915 19.9337C79.9488 19.9337 82.3579 18.4072 82.3579 15.6237C82.5364 13.1095 80.7518 12.1218 78.6103 11.7626Z"
        fill="#FC3441"
      />
      <path
        d="M87.8008 1.25706L84.9455 2.8733V6.28538H83.4286V9.06892H84.9455V14.9054C84.9455 17.4195 86.0162 19.7541 89.6746 19.7541H90.8346V16.9706H90.3884C88.6039 16.9706 87.8008 15.8931 87.8008 14.5462V8.97913H91.1022V6.19559H87.8008V1.25706Z"
        fill="#FC3441"
      />
      <path
        d="M110.197 8.79951C111.268 8.79951 112.428 9.33826 112.785 10.5055L115.372 9.51784C114.659 6.91388 112.249 5.92618 110.197 5.92618C106.45 5.92618 104.933 8.26076 104.933 10.5953V15.3543C104.933 17.5991 106.45 20.0235 110.197 20.0235C112.339 20.0235 114.659 19.0357 115.372 16.4318L112.785 15.4441C112.428 16.7012 111.268 17.1501 110.197 17.1501C108.234 17.1501 107.699 16.0726 107.699 14.9053V11.0443C107.699 9.87701 108.234 8.79951 110.197 8.79951Z"
        fill="#FC3441"
      />
      <path
        d="M122.332 12.3014L127.686 6.28538H124.206L119.566 11.4933V1.25706H116.711V19.7541H119.566V15.4441L120.548 14.3666L124.652 19.7541H128.043L122.332 12.3014Z"
        fill="#FC3441"
      />
      <path
        d="M99.1329 9.15873H96.1884C95.7422 9.15873 95.2961 9.5179 95.2961 10.0566V13.2891H92.3516V10.0566C92.3516 7.99144 94.0469 6.2854 96.0992 6.2854H99.0437V9.15873"
        fill="#FC3441"
      />
      <path
        d="M96.7238 16.791H99.6683C100.114 16.791 100.561 16.4319 100.561 15.8931V12.6606H103.505V15.8931C103.505 17.9583 101.81 19.6644 99.7575 19.6644H96.813V16.791"
        fill="#FC3441"
      />
      <path
        d="M48.2725 6.28538H51.574V9.06892H48.2725V14.636C48.2725 16.0727 48.9864 17.0604 50.8602 17.0604H51.3063V19.8439H50.1463C46.488 19.8439 45.4172 17.5991 45.4172 14.9952V9.06892H40.5989V14.636C40.5989 16.0727 41.3127 17.0604 43.1865 17.0604H43.6327V19.8439H42.4727C38.8143 19.8439 37.7436 17.5991 37.7436 14.9952V9.06892H36.3159V6.28538H37.7436V2.8733L40.5989 1.25706V6.19559H45.4172V2.8733L48.2725 1.25706V6.28538Z"
        fill="#FC3441"
      />
      <path
        d="M17.3995 6.01601C16.5072 6.01601 15.6149 6.19559 14.7226 6.55476V1.25706H11.8673V19.7541H14.7226V9.15871C15.258 8.79954 16.1503 8.61996 16.9533 8.61996C18.7379 8.61996 19.6302 9.60767 19.6302 11.1341V19.7541H22.4855V10.6852C22.4855 7.99142 20.5225 6.01601 17.3995 6.01601Z"
        fill="#FC3441"
      />
      <path
        d="M31.9438 17.0603C31.4084 17.3297 30.6946 17.5093 30.07 17.5093C27.9285 17.5093 27.1255 16.4318 27.1255 14.9053V6.19553H24.2701V14.9053C24.2701 18.3174 26.2332 19.9336 29.7131 19.9336C31.4084 19.9336 33.193 19.4847 34.7991 18.7663V6.28532H31.9438V17.0603Z"
        fill="#FC3441"
      />
      <path
        d="M6.60291 11.7626C6.42445 11.7626 5.71062 11.583 5.53217 11.583C4.46142 11.4035 3.56914 11.1341 3.56914 10.0566C3.56914 9.15867 4.55065 8.70972 5.71062 8.70972C7.49519 8.70972 9.01208 9.51784 9.19053 9.60763L10.172 7.00368C9.81513 6.82409 8.20902 5.92618 5.71062 5.92618C3.39068 5.92618 0.624599 7.09347 0.624599 10.1464C0.624599 12.8401 2.4984 13.6483 4.3722 14.0074C4.63988 14.0972 5.44294 14.187 5.6214 14.187C6.87059 14.3666 7.49519 14.8155 7.49519 15.7135C7.49519 16.7012 6.51368 17.2399 5.08602 17.2399C2.76608 17.2399 1.33843 16.5216 0.981513 16.342L0 18.946C0.356914 19.1255 1.96303 20.0235 4.9968 20.0235C8.03056 20.0235 10.4397 18.497 10.4397 15.7135C10.4397 13.1095 8.74439 12.1218 6.60291 11.7626Z"
        fill="#FC3441"
      />
      <path
        d="M63.3522 10.5953C63.3522 8.08118 61.4784 5.92618 58.0877 5.92618C54.2509 5.92618 52.8232 8.35055 52.8232 10.5953V15.0849C52.8232 18.2276 54.7863 20.0235 58.0877 20.0235C61.0323 20.0235 62.5491 18.3174 62.9953 17.3297L60.5861 15.8032C60.4077 16.4318 59.4261 17.2399 58.0877 17.2399C56.3032 17.2399 55.6786 16.2522 55.6786 14.9951V14.2768H63.3522V10.5953ZM60.4969 11.9422H55.6786V10.9545C55.6786 9.87701 56.2139 8.70972 58.0877 8.70972C59.8723 8.70972 60.4969 9.87701 60.4969 10.9545V11.9422Z"
        fill="#FC3441"
      />
      <path
        d="M65.1368 10.5954V19.7542H67.9921V10.8648C67.9921 10.0567 68.4383 8.79959 70.4905 8.79959C70.8475 8.79959 71.5613 8.88938 71.829 8.97917V6.19564C71.5613 6.10584 71.0259 6.01605 70.4013 6.01605C66.743 6.01605 65.1368 8.26084 65.1368 10.5954Z"
        fill="#FC3441"
      />
      <path
        d="M130.363 8.61998C129.56 8.61998 128.935 7.99144 128.935 7.27311C128.935 6.46498 129.56 5.83644 130.363 5.83644C131.166 5.83644 131.791 6.46498 131.791 7.27311C131.701 8.08123 131.077 8.61998 130.363 8.61998ZM130.363 6.19561C129.738 6.19561 129.292 6.64457 129.292 7.27311C129.292 7.81186 129.738 8.3506 130.363 8.3506C130.988 8.3506 131.434 7.90165 131.434 7.27311C131.344 6.73436 130.898 6.19561 130.363 6.19561ZM130.541 7.99144L130.274 7.54248H130.095V7.99144H129.828V6.64457H130.363C130.72 6.64457 130.898 6.91394 130.898 7.09352C130.898 7.27311 130.809 7.45269 130.631 7.54248L130.898 7.99144H130.541ZM130.631 7.00373C130.631 6.91394 130.541 6.82415 130.363 6.82415H130.184V7.27311H130.363C130.541 7.27311 130.631 7.18331 130.631 7.00373Z"
        fill="#FC3441"
      />
    </svg>
  );
};

export const ShutterstockContributorLogo = (props) => {
  return <SvgIcon {...props} component={ShutterstockContributorLogoComponent}></SvgIcon>;
};

ShutterstockContributorLogoComponent.TestSelector = {
  container: 'shutterstock-contributor-logo-container',
};
