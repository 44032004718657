import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { I18nKey } from '../../i18n';
import { Form, FormAction, FormHeader, FormHelpText, FormTitle, FormBody } from '../../core-components';
import classes from './OtpTriggerForm.module.scss';
import { OtpTriggerHelp } from './OtpTriggerHelp';
import { AnalyticsTracker } from '../../analytics/trackers';
import { OverlayLoader } from '../../core-components/OverlayLoader/OverlayLoader';
import { decodeHtmlCharRef } from '../../utils/html';
import { OtpLogOutLinkHelp } from './OtpLogOutLinkHelp';

export const OtpTriggerStatus = {
  Triggering: 'triggering',
};

export const OtpTriggerForm = ({ error, user, api, onTriggerSuccess }) => {
  const { t: translate } = useTranslation();

  const initialOtp = {
    status: null,
    formAlert: error,
  };

  const [otp, setOtp] = useState(initialOtp);

  const isSubmitEnabled = () => {
    if (otp.status === OtpTriggerStatus.Triggering) {
      return false;
    }

    return true;
  };

  const onTriggerFailure = (err) => {
    let error = null;
    if (err.error) {
      if (err.error.text) err.error.text = decodeHtmlCharRef(err.error.text);
      error = err.error;
    }

    setOtp({
      ...otp,
      status: null,
      formAlert: error,
    });
  };

  const triggerOtp = () => {
    setOtp({ ...otp, status: OtpTriggerStatus.Triggering });

    return api
      .triggerOtp({
        namespace: user.verificationNamespace,
        strategy: user.verificationStrategy,
      })
      .then((response) => onTriggerSuccess(response))
      .catch(onTriggerFailure);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    triggerOtp();
  };

  let formAlerts = otp.formAlert ? (
    <Alert severity={otp.formAlert.level}>
      {otp.formAlert.i18nKey ? translate(otp.formAlert.i18nKey) : otp.formAlert.text}
    </Alert>
  ) : null;

  return (
    <Form onSubmit={handleSubmit} noValidate={true} data-test-id={OtpTriggerForm.TestSelector.Form}>
      <div className={classes.formInnerWrapper}>
        {otp.status === OtpTriggerStatus.Triggering && (
          <div className={classes.overlayLoaderWrapper}>
            <OverlayLoader />
          </div>
        )}
        <FormHeader>
          <FormTitle>{translate(I18nKey.OtpTriggerVerifyEmailTitle)}</FormTitle>
          {formAlerts && <div className={classes.alertWrapper}>{formAlerts}</div>}
          <FormHelpText>
            <OtpTriggerHelp webPropertyEmail={user.email} />
          </FormHelpText>
        </FormHeader>
        <Box className={classes.lineDividerSpacing}>
          <Divider />
        </Box>
        <FormBody>
          <FormAction>
            <AnalyticsTracker.OnClick eventLabel="passcode-trigger-click" pageSection="body">
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={!isSubmitEnabled()}
                  data-test-id={OtpTriggerForm.TestSelector.Submit}>
                  {translate(I18nKey.OtpTriggerVerifyEmailVerifyButton)}
                </Button>
              </div>
            </AnalyticsTracker.OnClick>
            <AnalyticsTracker.OnClick eventLabel="passcode-trigger-logout-click" pageSection="body">
              <Box className={classes.logoutSpacing}>
                <OtpLogOutLinkHelp nextUrl={user.verificationNextUrl} />
              </Box>
            </AnalyticsTracker.OnClick>
          </FormAction>
        </FormBody>
      </div>
    </Form>
  );
};

OtpTriggerForm.TestSelector = {
  Form: 'otp-trigger-form',
  Submit: 'otp-trigger-form-submit-button',
};
