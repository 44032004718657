import React from 'react';
import { Trans } from 'react-i18next';
import { I18nKey } from '../../i18n';
import classes from './OtpHelp.module.scss';

export const OtpHelp = ({ webPropertyEmail }) => {
  return (
    <div className={classes.root}>
      <p>
        <Trans i18nKey={I18nKey.OtpHelp2}>To continue, enter the code sent to your email {webPropertyEmail}</Trans>
      </p>
    </div>
  );
};
