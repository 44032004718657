import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { I18nKey } from '../../i18n';
import { AnalyticsTracker } from '../../analytics/trackers';
import { Form, FormAction, FormBody, FormHeader, FormHelpText, FormTitle } from '../../core-components';
import { getSsoUrls } from './sso-url';
import classes from './SsoConfirmForm.module.scss';
import { SignedInUser } from '../SignedInUser';

export const SsoConfirmForm = ({
  user,
  identityProviderEmail,
  identityProviderName,
  identityProviderDescription,
  csrfToken,
  nextUrl = '/',
}) => {
  const { t: translate } = useTranslation();

  const { email } = user;

  const { confirmUrl, logoutUrl, switchAccountUrl } = getSsoUrls({
    identityProviderName,
    nextUrl,
    externalEmail: identityProviderEmail,
  });

  return (
    <Form action={confirmUrl} method="POST" data-test-id={SsoConfirmForm.TestSelector.Form}>
      <FormHeader className={classes.header}>
        <FormTitle>{translate(I18nKey.ConfirmAccountLink)}</FormTitle>
        <FormHelpText className={classes.formHelpText}>
          <div>
            <Trans i18nKey={I18nKey.ConfirmAccountLinkText}>
              Your {{ identityProviderDescription }} account shown below will be linked with{' '}
              <strong>{{ email }}</strong>. For security reasons, the password for your Shutterstock account will be
              reset if you continue.
            </Trans>
          </div>
          <div className={classes.targetEmail}>
            <strong>{identityProviderEmail}</strong>
          </div>
        </FormHelpText>
      </FormHeader>
      <FormBody>
        <input type="hidden" name="_csrf" value={csrfToken} />
        <FormAction>
          <AnalyticsTracker.OnClick eventLabel="confirmClick" name="Confirm">
            <div className={classes.bottomSpacingSm}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                data-test-id={SsoConfirmForm.TestSelector.Confirm}>
                {translate(I18nKey.Confirm)}
              </Button>
            </div>
          </AnalyticsTracker.OnClick>
          <AnalyticsTracker.OnClick eventLabel="switchClick" name="Switch">
            <Button type="submit" href={switchAccountUrl} variant="outlined" size="large" fullWidth>
              {translate(I18nKey.SwitchAccount)}
            </Button>
          </AnalyticsTracker.OnClick>
        </FormAction>
        <SignedInUser email={email} switchUserUrl={logoutUrl} />
      </FormBody>
    </Form>
  );
};

SsoConfirmForm.TestSelector = {
  Form: 'sso-confirm-link-form',
  Confirm: 'sso-confirm-link-confirm-button',
};
