import { useApplicationConfig } from '../../../application-state';
import { InternalEmployeeIdentityProviderEntrypointLink } from './InternalEmployeeIdentityProviderEntrypointLink';
import Alert from '@mui/material/Alert';
import React from 'react';

export const DisableForEmployeeUsers = ({ children }) => {
  const { ssoOnly: shouldRedirectToEntryPoint, showUiAvailabilityWarning } = useApplicationConfig();

  if (showUiAvailabilityWarning)
    return (
      <React.Fragment>
        <Alert severity="error">
          Warning: this page is not available in production and is only shown here for automated testing. Employee users
          will be automatically redirected to Okta in production.
        </Alert>
        {children}
      </React.Fragment>
    );

  if (shouldRedirectToEntryPoint) return <InternalEmployeeIdentityProviderEntrypointLink />;

  return children;
};
