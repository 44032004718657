import React, { Component } from 'react';
// TODO: theming
import styles from './Form.module.scss';

export class Form extends Component {
  render() {
    return (
      <form {...this.props} className={styles.root}>
        {this.props.children}
      </form>
    );
  }
}
