import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../../i18n';
import TextField from '@mui/material/TextField';
import { ValidationError } from '../ValidationError';

export const EmailInput = forwardRef(({ error, floatLabel = false, label, value, onChange, ...props }, ref) => {
  const { t: translate } = useTranslation();

  label = label || translate(I18nKey.EmailAddress);

  if (Array.isArray(error)) {
    error = error[0];
  }

  return (
    <>
      <TextField
        inputRef={ref}
        type="email"
        label={label}
        value={value}
        error={!!error}
        helperText={error ? <ValidationError error={error} /> : null}
        fullWidth
        onChange={(event) => onChange(event.currentTarget.value)}
        InputLabelProps={{ shrink: !floatLabel }}
        inputProps={props.inputProps}
        {...props}
      />
    </>
  );
});
