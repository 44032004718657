import { privateProperties } from './access';

export const embeddedIframePreset = {
  'createUser.showValueProps': false,
  showFooter: false,
  showHeader: false,
  showLanguageMenu: false,
  showPreviousLocationLink: false,
  [privateProperties]: {
    showFormBorders: false,
    'createUser.openLinksInNewTab': false,
    'embedded.warnEmbeddedWindowSize': true,
    openSocialLoginInNewTab: true,
    openSsoLoginInNewTab: true,
    'login.showFormTitle': false,
    'createUser.showFormTitle': false,
  },
};

export default embeddedIframePreset;
