/**
 * MUI Chip x Shutterstock.com Theme
 * Exports an object to be passed into createTheme()
 */
import { radius } from '../theme/tokens';

export const MuiChipOverrides = {
  styleOverrides: {
    root: {
      borderRadius: radius.m,
    },
  },
};
