import { useTranslation } from 'react-i18next';
import { TextDivider } from '../../../../core-components';
import classes from './OrTextDivider.module.scss';
import { I18nKey } from '../../../../i18n';

export const OrTextDivider = () => {
  const { t: translate } = useTranslation();
  return (
    <div className={classes.container}>
      <TextDivider>{translate(I18nKey.OptionOr)}</TextDivider>
    </div>
  );
};
