import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { referrerVar, useApplicationConfig } from '../../../application-state';
import {
  FormPage,
  FormPageContent,
  PageHeader,
  PageLogo,
  PageTitle,
  PageTitleText,
  AdobeBackLink,
} from '../../../core-components';
import { Logo } from '../../../brand';
import { LanguageMenuContainer } from '../../../i18n';
import { ContactSupportFabButton } from '../../user-support';

export const WwwFormPage = ({ children, contentPlacement, pageTitleI18nKey, fullWidth = false, showFooter }) => {
  const referrer = useReactiveVar(referrerVar);
  const config = useApplicationConfig();

  showFooter = showFooter === undefined ? config.showFooter : showFooter;

  return (
    <FormPage>
      <PageTitle webProperty={referrer.displayName}>
        <PageTitleText i18nKey={pageTitleI18nKey} />
      </PageTitle>
      {config.showHeader && (
        <PageHeader>
          <PageLogo>
            <Logo />
          </PageLogo>
        </PageHeader>
      )}
      {config.showPreviousLocationLink && (
        <div style={{ marginLeft: '30px', marginBottom: '5px' }}>
          <AdobeBackLink />
        </div>
      )}
      <FormPageContent contentPlacement={contentPlacement} fullWidth={fullWidth} fullWidthInner>
        {children}
        {config.showLanguageMenu && <LanguageMenuContainer />}
      </FormPageContent>
      {/* Show a FAB that links to the support page. Only shown in cases where the user
        should see footer content.
      */}
      {showFooter && <ContactSupportFabButton />}
    </FormPage>
  );
};
