import { I18nKey } from '../../i18n';
import { privateProperties } from '../../$presets/access';

export const creativeEmbeddedPreset = {
  'createUser.formTitleI18nKey': I18nKey.CreateYourAccount,
  'createUser.valueProps.component': 'creative',
  [privateProperties]: {
    showFormBorders: false,
    'createUser.openLinksInNewTab': true,
  },
};

export default creativeEmbeddedPreset;
