import { I18nKey } from '../../i18n';

export const ImageAdpConversionPanel = {
  'createUser.showValueProps': true,
  'createUser.valueProps.component': 'freetrialflex10',
  'createUser.formSubmitI18nKey': I18nKey.DownloadForFree,
  'createUser.formTitleI18nKey': I18nKey.CreateUserFormTrialFlex10Title,
};

export default ImageAdpConversionPanel;
