import { I18nKey } from '../i18n';
import { privateProperties } from './access';

export const googleOneTapPreset = {
  'createUser.showValueProps': false,
  showFooter: false,
  showHeader: false,
  showLanguageMenu: false,
  showPreviousLocationLink: false,
  [privateProperties]: {
    'createUser.openLinksInNewTab': false,
    'createUser.showFormTitle': true,
    'createUser.formSubmitI18nKey': I18nKey.SignUp,
  },
};

export default googleOneTapPreset;
