import React from 'react';
import { Trans } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { LegalExternalLink, LegalExternalLinkType } from '../LegalExternalLink';
import { useApplicationConfig } from '../../application-state';

const contributorTos = () => {
  return (
    <div>
      <Trans i18nKey={I18nKey.ContributorCreateAccountTermsOfService}>
        I certify that I am 18 or older and I agree to the
        <LegalExternalLink type={LegalExternalLinkType.WebsiteTermsOfService} />
        {' and '}
        <LegalExternalLink type={LegalExternalLinkType.PrivacyPolicy} />
      </Trans>
    </div>
  );
};

const customerTos = () => {
  return (
    <div>
      <Trans i18nKey={I18nKey.CreateAccountTermsOfService}>
        By creating an account, I agree to Shutterstock's
        <LegalExternalLink type={LegalExternalLinkType.WebsiteTermsOfService} />
        ,
        <LegalExternalLink type={LegalExternalLinkType.PrivacyPolicy} />
        , and
        <LegalExternalLink type={LegalExternalLinkType.LicensingTermsOfService} />
      </Trans>
    </div>
  );
};

const supportedTosComponents = {
  contributor: contributorTos,
  customer: customerTos,
};

export const CreateUserTermsOfService = () => {
  const config = useApplicationConfig();
  const tosComponentType = config['createUser.termsOfService.component'];

  const createTosComponent = supportedTosComponents[tosComponentType] || customerTos;

  return createTosComponent();
};
