import React from 'react';
import classes from './PageLogo.module.scss';

/**
 * Page log component
 * @param {Object} params
 * @param {Object=} params.theme
 * @param {JSX.Element} params.children
 */
export const PageLogoComponent = ({ children, theme }) => <div className={theme.root}>{children}</div>;

export const PageLogo = (props) => <PageLogoComponent {...props} theme={classes} />;
