import { I18nKey } from '../i18n';

export const freetrialflex10 = {
  'createUser.showValueProps': true,
  'createUser.valueProps.component': 'freetrialflex10',
  'createUser.formSubmitI18nKey': I18nKey.StartFreeTrial,
  'createUser.formTitleI18nKey': I18nKey.CreateUserFormTrialFlex10Title,
  'createUser.valueProps.titleI18nKey': I18nKey.CreateUserTrialFlex10GetImagesMusicTitle,
  'createUser.valueProps.bullet4I18nKey': I18nKey.ImageCreateUserGreetingBulletKeepNewP31193,
};

export default freetrialflex10;
