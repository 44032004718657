import { useReactiveVar } from '@apollo/client';
import { createContext } from 'react';
import { userVar } from '../application-state';

export const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const user = useReactiveVar(userVar);

  return <authContext.Provider value={{ user }}>{children}</authContext.Provider>;
};
