export const printDevToolsHelp = () => {
  let shouldPrint = ['test', 'production'].includes(process.env.NODE_ENV) === false;

  if (/accounts\.qa\.shutter(stock|corp)/.test(window.location.origin)) {
    shouldPrint = true;
  }

  if (!shouldPrint) {
    return;
  }

  // Do not print if in an iframe
  if (window.location !== window.parent.location) {
    return;
  }

  console.log('%cGreetings Shutterstock developers!', 'font-size: 1.5em; color: #f54336;');
  console.log(`For help with development, please consult the following documents:

For troubleshooting common issues: https://github.shuttercorp.net/Identity/accounts/blob/next/lib/next/web/docs/devtools-console/troubleshooting.md

For Identity service developers: https://github.shuttercorp.net/Identity/accounts/blob/next/lib/next/web/docs/devtools-console/developer.md
  `);
};
