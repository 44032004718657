import React from 'react';
import classes from './ValueProp.module.scss';

export const ValueProp = ({ Icon, children = '' }) => {
  return (
    <li className={classes.listItem}>
      <div className={classes.iconWrapper}>{Icon || <div className={classes.iconPlaceholder}></div>}</div>
      <span>{children}</span>
    </li>
  );
};
