import React, { Children } from 'react';

export const FlexGap = {
  None: '0px',
  Small: '15px',
};

export const FlexRow = ({ children, gap = FlexGap.None, component: Component = 'div', fullWidth }) => {
  return (
    <Component style={{ display: 'flex', gap, width: fullWidth ? '100%' : '' }}>
      {Children.map(children, (child) => (
        <div style={{ flex: '1' }}>{child}</div>
      ))}
    </Component>
  );
};
