import React from 'react';
import classes from './PageHeader.module.scss';

/**
 * Page header component
 * @param {Object} params
 * @param {Object=} params.theme
 * @param {JSX.Element} params.children
 */
export const PageHeaderComponent = ({ children, theme }) => {
  return <header className={theme.header}>{children}</header>;
};

export const PageHeader = (props) => <PageHeaderComponent {...props} theme={classes} />;
