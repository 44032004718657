import { useContext } from 'react';
import { ApplicationStateContext } from './application-state';

export const useApi = () => {
  const { api } = useContext(ApplicationStateContext);

  return api;
};

export const useActions = () => useContext(ApplicationStateContext);
