import { useTranslation } from 'react-i18next';
import { formatValidationErrorMessagesByKey, useFormValidation } from '../../core-components/form';
import joi from 'joi';

const emailValidationSchema = joi
  .string()
  .email({ tlds: { allow: false } })
  .required();
const passwordValidationSchema = joi.string().min(8).required();

const mergeErrors = (errors, validationErrors) => {
  errors = errors || {};
  validationErrors = validationErrors || {};

  return Array.from(new Set([...Object.keys(errors), ...Object.keys(validationErrors)])).reduce((mergedErrors, key) => {
    mergedErrors[key] = [errors[key], ...(validationErrors[key] || []), ...(mergedErrors[key] || [])].filter(
      (error) => error,
    );
    return mergedErrors;
  }, {});
};

export const useCredentialsInput = ({ email, password, showPassword, errors }) => {
  const initialState = { email };
  if (showPassword) {
    initialState.password = password;
  }

  let validationSchema;
  if (showPassword) {
    validationSchema = joi.object({
      email: emailValidationSchema,
      password: passwordValidationSchema,
    });
  } else {
    validationSchema = joi.object({ email: emailValidationSchema });
  }

  const { t: translate } = useTranslation();
  const { formData, formValidation, validateForm } = useFormValidation(initialState, validationSchema, {
    abortEarly: false,
    stateDependencies: [showPassword],
  });
  const validationErrors = formatValidationErrorMessagesByKey(formValidation.error, translate);
  const credentialsErrors = mergeErrors(errors, validationErrors);

  return {
    credentialsFormValidation: formValidation,
    credentialsFormData: formData,
    credentialsValidateForm: validateForm,
    credentialsErrors,
  };
};
