import { I18nKey } from '../../i18n';

/**
 * The schema is a {@link https://json-schema.org/|JSON Schema} object that defines every property
 * of the user interface that can be configured at runtime. All properties should be defined in
 * the root `properties` object as simple key-value pairs. (Do not define objects as values). Use
 * a `.` to denote namespaced properties.
 */
export const applicationConfigurationSchema = {
  $schema: 'https://json-schema.org/draft/2020-12/schema',
  $id: 'https://accounts.shutterstock.com/ui.configuration.schema.json',
  title: '',
  description: '',
  type: 'object',
  properties: {
    'checkout.showSocialLogin': {
      $$public: false,
      $$provisional: true,
      type: 'boolean',
      default: true,
      description: 'When `true`, show the social login buttons in the checkout flow.',
    },
    'createUser.formSubmitI18nKey': {
      $$public: true,
      type: 'string',
      default: I18nKey.GetStarted,
      description:
        'Internationalization key for the text to show in the button that submits the form to create a new user.',
    },
    'createUser.valueProps.titleI18nKey': {
      $$public: true,
      type: 'string',
      description:
        'Internationalization key for the text to show as title for value proposition in create new user view.',
    },
    'createUser.valueProps.subtitleI18nKey': {
      $$public: true,
      type: 'string',
      description:
        'Internationalization key for the text to show as sub title for value proposition in create new user view.',
    },
    'createUser.valueProps.bullet4I18nKey': {
      $$public: true,
      type: 'string',
      description:
        'Internationalization key for the text to show as bullet point 4 for value proposition in create new user view.',
    },
    'createUser.formTitleI18nKey': {
      $$public: true,
      type: 'string',
      default: I18nKey.CreateYourFreeAccount,
    },
    'createUser.showDisplayName': {
      $$public: false,
      type: 'boolean',
      default: false,
    },
    'createUser.showFullName': {
      $$public: false,
      type: 'boolean',
      default: false,
    },
    'createUser.showFormTitle': {
      $$public: false,
      type: 'boolean',
      default: true,
    },
    'createUser.showValueProps': {
      $$public: true,
      type: 'boolean',
      default: true,
      description:
        'When `true`, show the value props in the new user view. Value props may still be hidden on smaller devices.',
    },
    'createUser.valueProps.component': {
      $$public: true,
      type: 'string',
      default: '',
      description:
        'Identifier of the value propositions component that will be resolved at runtime. Value propositions are shown in the view for creating a new user (sign-up) to provide detail about the value of creating a Shutterstock account.',
    },
    'login.canCreateUser': {
      $$public: false,
      type: 'boolean',
      default: true,
    },
    'login.showRequestDemo': {
      $$public: false,
      type: 'boolean',
      default: false,
    },
    'login.showFormTitle': {
      $$public: false,
      type: 'boolean',
      default: true,
    },
    'logo.component': {
      $$public: true,
      type: 'string',
      enum: ['Shutterstock', 'Enterprise', 'Contributor'],
      default: 'Shutterstock',
      description: 'Identifier of the logo component that will be resolved at runtime',
    },
    showFooter: {
      $$public: true,
      type: 'boolean',
      default: true,
      description: 'When `true`, show the page footer',
    },
    showFormBorders: {
      $$public: false,
      type: 'boolean',
      default: true,
      description:
        'When `true`, render forms with borders and padding. This is intended for removing visual elements for specific use cases like embedded forms.',
    },
    showHeader: {
      $$public: true,
      type: 'boolean',
      default: true,
      description: 'When `true`, show the global application header.',
    },
    showLanguageMenu: {
      $$public: true,
      type: 'boolean',
      default: true,
      description: 'When `true`, show the language selection menu.',
    },
    showPreviousLocationLink: {
      $$public: true,
      type: 'boolean',
      default: false,
      description:
        'When `true`, show a link that takes the user to the previous location. This is intended to support use cases where a browser back button is not available, such as the embedded browser for Adobe plugins.',
    },
    showSocialLogin: {
      $$public: false,
      type: 'boolean',
      default: true,
      description: 'When `true`, show the social login buttons.',
    },
    showLoginWithSso: {
      $$public: false,
      type: 'boolean',
      default: true,
      description: 'When `true`, show `Login with Sso` login button.',
    },
    showContactUsLink: {
      $$public: true,
      type: 'boolean',
      default: false,
      description: 'When `true`, show contact us section in the footer.',
    },
    openSocialLoginInNewTab: {
      $$public: false,
      type: 'boolean',
      default: false,
      description: 'When `true`, open social login links in new tab.',
    },
    openSsoLoginInNewTab: {
      $$public: false,
      type: 'boolean',
      default: false,
      description: 'When `true`, open sso login in new tab.',
    },
    'createUser.openLinksInNewTab': {
      $$public: false,
      type: 'boolean',
      default: false,
      description: 'When `true`, opens links in new tab.',
    },
    'createUser.termsOfService.action': {
      $$public: false,
      type: 'string',
      default: 'implicit',
      enum: ['implicit', 'explicit', 'none'],
      description: 'When `explicit`, then the user has to explicitly accept the tos',
    },
    'createUser.termsOfService.component': {
      $$public: false,
      type: 'string',
      default: 'customer',
      enum: ['contributor', 'customer'],
      description:
        'Identifier of the terms of service component that will be resolved at runtime. Terms of service are shown in the view for creating a new user (sign-up) to confirm that the user accepts product-specific terms of service.',
    },
    'createUser.termsOfService.name': {
      $$public: false,
      type: 'string',
      default: '',
      description: 'The name of the tos field name that will be sent while creating an account',
    },
    'createUser.termsOfService.value': {
      $$public: false,
      type: 'string',
      default: '',
      description: 'The value of the tos field name that will be sent while creating an account',
    },
    contactUsLink: {
      $$public: false,
      type: 'string',
      default: 'https://www.shutterstock.com/help',
      description: 'URL to use for call to action in contact us section in footer.',
    },
    'embedded.warnEmbeddedWindowSize': {
      $$public: false,
      type: 'boolean',
      default: false,
      description:
        'When `true`, output a warning when the window dimensions are less than the allowed minimum values. This warning is intended to alert application developers to issues when implementing the authentication user interface in an embedded format, such as a secondary window (pop up) or iframe.',
    },
    ssoOnly: {
      $$public: false,
      type: 'boolean',
      default: false,
      description: `When true, disable features that are not supported for SSO/federated identity, such as login, sign-up, change email address, reset password, etc. It is intended solely for use in the Shutterstock employee realm.`,
    },
    showUiAvailabilityWarning: {
      $$public: false,
      type: 'boolean',
      default: false,
      description: `When true, show warning pop-up that normally this page would not be rendered, f.e redirect would be initiated`,
    },
  },
};
