import React from 'react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { appendQueryParameters } from '../../application-state/utils/url';

export const LoginLink = ({ path, openInNewTab }) => {
  const { t: translate } = useTranslation();

  if (openInNewTab && path) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('embedded');

    path = appendQueryParameters(path, searchParams);
  }

  return (
    <Link to={path} data-test-id="login-link" target={openInNewTab ? '_blank' : '_self'}>
      {translate(I18nKey.LogIn)}
    </Link>
  );
};

LoginLink.propTypes = {
  path: propTypes.string,
};

LoginLink.defaultProps = {
  path: '/login',
};
