import { useApplicationConfig } from '../../application-state';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const CanCreateUser = ({ children }) => {
  const navigate = useNavigate();

  const { 'login.canCreateUser': canCreateUser } = useApplicationConfig();

  useEffect(() => {
    if (!canCreateUser) {
      navigate('/login');
    }
  }, [canCreateUser, navigate]);

  return canCreateUser ? children : null;
};
