import React from 'react';
import classnames from 'classnames';
import classes from './FormPageContent.module.scss';

const placementToAlignItems = {
  top: 'start',
  center: 'center',
  bottom: 'end',
};

export const FormPageContentComponent = ({ contentPlacement, children, fullWidth, fullWidthInner, theme }) => {
  return (
    <main
      className={classnames(theme.root, fullWidth && theme.fullWidth)}
      style={{ alignItems: placementToAlignItems[contentPlacement] || 'start' }}
      role="main">
      <div className={classnames(theme.inner, fullWidthInner && theme.fullWidthInner)}>
        <div className={theme.formContainer}>{children}</div>
      </div>
    </main>
  );
};

export const FormPageContent = (props) => <FormPageContentComponent {...props} theme={classes} />;
