import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, styled } from '@mui/material/styles';

const { zIndex } = createTheme();

const BackdropLayout = styled(Backdrop)({
  zIndex: zIndex.drawer + 1,
});

/**
 * The full page overlay loader
 */
export function OverlayLoader() {
  return (
    <BackdropLayout open>
      <CircularProgress color="inherit" />
    </BackdropLayout>
  );
}
