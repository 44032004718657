import { useReactiveVar } from '@apollo/client';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import {
  analyticsVar,
  captchaBypassVar,
  csrfTokenVar,
  embeddedVar,
  featureFlagsVar,
  invitationFlowVar,
  languageVar,
  marketingEmailOptInVar,
  nextUrlVar,
  oauthClientIdVar,
  prefillEmailVar,
  recaptchaSiteKeyVar,
  referrerVar,
  showRecaptchaVar,
  ssoVar,
  useApplicationConfig,
  userVar,
} from '../application-state';

export const DebugDrawer = ({ open }) => {
  const applicationConfig = useApplicationConfig();
  const analytics = useReactiveVar(analyticsVar);
  const captchaBypass = useReactiveVar(captchaBypassVar);
  const csrfToken = useReactiveVar(csrfTokenVar);
  const embedded = useReactiveVar(embeddedVar);
  const featureFlags = useReactiveVar(featureFlagsVar);
  const invitationFlow = useReactiveVar(invitationFlowVar);
  const language = useReactiveVar(languageVar);
  const marketingEmailOptIn = useReactiveVar(marketingEmailOptInVar);
  const nextUrl = useReactiveVar(nextUrlVar);
  const oauthClientId = useReactiveVar(oauthClientIdVar);
  const prefillEmail = useReactiveVar(prefillEmailVar);
  const recaptchaSiteKey = useReactiveVar(recaptchaSiteKeyVar);
  const referrer = useReactiveVar(referrerVar);
  const showRecaptcha = useReactiveVar(showRecaptchaVar);
  const sso = useReactiveVar(ssoVar);
  const user = useReactiveVar(userVar);

  const applicationState = [
    { key: 'analytics.environment', value: analytics.environment, local: false },
    { key: 'analytics.hightouchKey', value: analytics.hightouchKey, local: false },
    { key: 'analytics.site', value: analytics.site, local: false },
    { key: 'captchaBypass', value: String(captchaBypass), local: true },
    { key: 'csrfToken', value: csrfToken, local: false },
    { key: 'embedded', value: String(embedded), local: true },
    { key: 'invitationFlow', value: String(invitationFlow), local: true },
    { key: 'language.code', value: language.code, local: false },
    { key: 'language.name', value: language.name, local: false },
    { key: 'marketingEmailOptIn.show', value: marketingEmailOptIn.show, local: false },
    { key: 'nextUrl', value: nextUrl, local: false },
    { key: 'oauthClientId', value: oauthClientId, local: true },
    { key: 'prefillEmail', value: prefillEmail, local: true },
    { key: 'recaptchaSiteKey', value: recaptchaSiteKey, local: false },
    { key: 'referrer.key', value: referrer.key, local: true },
    { key: 'referrer.site', value: referrer.site, local: true },
    { key: 'referrer.displayName', value: referrer.displayName, local: true },
    { key: 'showRecaptcha', value: String(showRecaptcha), local: false },
    { key: 'sso.isSso', value: String(sso.isSso), local: false },
    { key: 'sso.identityProviderDescription', value: sso.identityProviderDescription, local: false },
    { key: 'sso.profile.email', value: sso.profile.email, local: false },
    { key: 'user.email', value: user.email, local: false },
    { key: 'user.id', value: user.id, local: false },
    { key: 'user.loggedIn', value: String(user.loggedIn), local: false },
    { key: 'user.username', value: user.username, local: false },
  ];

  const applicationStateHeadings = ['Property', 'Type', 'Value', 'Local'];

  const applicationConfigurationHeadings = ['Property', 'Type', 'Value'];

  const featureFlagRows = Object.entries(featureFlags || {})
    .filter(([key]) => !key.startsWith('$'))
    .map(([key, value]) => ({ key, value }));

  return (
    <Drawer variant="persistent" anchor="bottom" open={open}>
      <div style={{ maxHeight: '380px' }}>
        {/* Application configuration */}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={applicationConfigurationHeadings.length}>Application configuration</TableCell>
              </TableRow>
              <TableRow>
                {applicationConfigurationHeadings.map((headingValue) => (
                  <TableCell key={headingValue}>{headingValue}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(applicationConfig).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{typeof value !== 'undefined' ? typeof value : ''}</TableCell>
                  <TableCell>{String(value)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Application state */}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={applicationStateHeadings.length}>Application state</TableCell>
              </TableRow>
              <TableRow>
                {applicationStateHeadings.map((headingValue) => (
                  <TableCell key={headingValue}>{headingValue}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {applicationState.map(({ key, value, local }) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{typeof value !== 'undefined' ? typeof value : ''}</TableCell>
                  <TableCell>{value}</TableCell>
                  <TableCell>{String(local === true)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Feature flags */}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>Feature flags</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featureFlagRows.map(({ key, value }) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{String(value)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Drawer>
  );
};
