export const experiment = {
  id: 'idn-4877-social-button-placement',
  name: 'Social Button Placement',
  featureFlagKey: 'idn-4877-social-button-placement',
  featureFlagVariation: {
    Control: 'control',
    VariantTop: 'variant_top',
    VariantTopMinimal: 'variant_top_minimal',
  },
};

export const experimentRound2 = {
  id: 'idn-5315-social-button-placement',
  name: 'Social Button Placement Round 2',
  featureFlagKey: 'idn-5315-social-button-placement',
  featureFlagVariation: {
    Control: 'control',
    VariantTop: 'variant_top',
  },
};
