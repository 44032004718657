import { I18nKey } from '../../i18n';
import { privateProperties } from '../../$presets/access';

export const exp817 = {
  'createUser.showValueProps': true,
  showFooter: false,
  showHeader: false,
  showLanguageMenu: false,
  showPreviousLocationLink: false,
  [privateProperties]: {
    showFormBorders: false,
    'createUser.openLinksInNewTab': true,
    'embedded.warnEmbeddedWindowSize': true,
    openSocialLoginInNewTab: true,
  },
  'createUser.valueProps.component': 'freetrialflex10',
  'createUser.formSubmitI18nKey': I18nKey.StartFreeTrial,
  'createUser.formTitleI18nKey': I18nKey.CreateUserFormTrialFlex10Title,
};

export default exp817;
