import React from 'react';
import { Trans } from 'react-i18next';
import { I18nKey } from '../../i18n';
import classes from './OtpTriggerHelp.module.scss';

export const OtpTriggerHelp = ({ webPropertyEmail }) => {
  return (
    <div className={classes.root}>
      <p>
        <Trans i18nKey={I18nKey.OtpTriggerVerifyEmailHelp}>
          Thanks for signing up! We just need to verify your email address to finish setting up your account. Click
          below to send a verification email to {webPropertyEmail}
        </Trans>
      </p>
    </div>
  );
};
