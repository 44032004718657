import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { I18nKey } from '../../i18n';

export const MarketingEmailOptIn = ({ value, onChange }) => {
  const { t: translate } = useTranslation();

  return (
    <FormControlLabel
      label={translate(I18nKey.ShutterstockEmailOffers)}
      control={<Checkbox color="primary" checked={value} onChange={(event) => onChange(event.target.checked)} />}
    />
  );
};
