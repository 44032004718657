import { spacing } from '../theme/tokens';

export const MuiAutocompleteOverrides = {
  styleOverrides: {
    root: {
      '&&& .MuiInputBase-adornedEnd': {
        paddingRight: spacing.m,
      },
    },
  },
};
