const debug = require('debug');

export class Log {
  constructor(name) {
    name = `${Log.baseName}${name ? `:${name}` : ''}`;
    this.log = debug(name);

    ['trace', 'debug', 'info', 'warn', 'error'].forEach((method) => (this[method] = this.handler.bind(this)));
  }

  static get baseName() {
    return 'accounts';
  }

  handler(...args) {
    this.log(...args);
  }
}

export function getLog(name) {
  return new Log(name);
}
