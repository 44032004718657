import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { nextUrlVar } from '../../application-state';
import { FacebookLogin } from './FacebookLogin';
import { GoogleLogin } from './GoogleLogin';
import { AppleLogin } from './AppleLogin';
import { AnalyticsTracker } from '../../analytics/trackers';
import classes from './SocialLogin.module.scss';

export const SocialLogin = ({ openSocialLoginInNewTab = false, analyticsVariant = '' } = {}) => {
  const nextUrl = useReactiveVar(nextUrlVar);

  return (
    <div data-test-id={SocialLogin.TestSelector.ButtonWrapper}>
      <div className={classes.button}>
        <AnalyticsTracker.OnClick eventLabel={'clickSSOGoogle' + analyticsVariant}>
          <GoogleLogin nextUrl={nextUrl} openSocialLoginInNewTab={openSocialLoginInNewTab} />
        </AnalyticsTracker.OnClick>
      </div>
      <div className={classes.button}>
        <AnalyticsTracker.OnClick eventLabel={'clickSSOFacebook' + analyticsVariant}>
          <FacebookLogin nextUrl={nextUrl} openSocialLoginInNewTab={openSocialLoginInNewTab} />
        </AnalyticsTracker.OnClick>
      </div>
      <div className={classes.button}>
        <AnalyticsTracker.OnClick eventLabel={'clickSSOApple' + analyticsVariant}>
          <AppleLogin nextUrl={nextUrl} openSocialLoginInNewTab={openSocialLoginInNewTab} />
        </AnalyticsTracker.OnClick>
      </div>
    </div>
  );
};

SocialLogin.TestSelector = {
  ButtonWrapper: 'button-wrapper',
};
