import React from 'react';
import { Trans } from 'react-i18next';
import { I18nKey } from '../../i18n';

export const OtpLogOutLinkHelp = ({ nextUrl }) => {
  const href = `/logout?next=${encodeURIComponent(nextUrl ? nextUrl : '/')}`;
  return (
    <p>
      {/* prettier-ignore */}
      <Trans i18nKey={I18nKey.OtpLogOut}>
        Not ready to continue? <a href={href}>Log Out</a>
      </Trans>
    </p>
  );
};
