import { MuiAutocompleteOverrides } from './MuiAutocomplete';
import { MuiBackdropOverrides } from './MuiBackdrop';
import { MuiButtonOverrides } from './MuiButton';
import { MuiChipOverrides } from './MuiChip';
import { MuiDialogOverrides } from './MuiDialog';
import { MuiGridOverrides } from './MuiGrid';
import { MuiOutlinedInputOverrides } from './MuiOutlinedInput';
import { MuiInputOverrides } from './MuiInput';
import { MuiInputLabelOverrides } from './MuiInputLabel';
import { MuiTabOverrides } from './MuiTab';
import { MuiTooltipOverrides } from './MuiTooltip';
import { MuiTextFieldOverrides } from './MuiTextField';
import { MuiFormControlOverrides } from '../components/MuiFormControl';

export const components = {
  MuiAutocomplete: MuiAutocompleteOverrides,
  MuiBackdrop: MuiBackdropOverrides,
  MuiButton: MuiButtonOverrides,
  MuiChip: MuiChipOverrides,
  MuiDialog: MuiDialogOverrides,
  MuiGrid: MuiGridOverrides,
  MuiOutlinedInput: MuiOutlinedInputOverrides,
  MuiInput: MuiInputOverrides,
  MuiInputLabel: MuiInputLabelOverrides,
  MuiTab: MuiTabOverrides,
  MuiTooltip: MuiTooltipOverrides,
  MuiTextField: MuiTextFieldOverrides,
  MuiFormControl: MuiFormControlOverrides,
};
