import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { LoginLink } from './LoginLink';

export const ExistingUserLoginLink = ({ openInNewTab }) => {
  const { t: translate } = useTranslation();

  return (
    <p>
      {translate(I18nKey.AlreadyHaveAnAccount)} <LoginLink openInNewTab={openInNewTab} />
    </p>
  );
};
