import AnalyticsWrapper from '@shutterstock-private/analytics-wrapper';
import { createProxy, createSandboxIframe, createPostMessageHandler, createCachedProxy } from '../sandbox';

/**
 * Returns location data using parent window of the marketing-sandbox iframe
 */
const getLocationData = () => {
  const fullUrl = window.parent.location.href;
  const idx = fullUrl.indexOf('#');
  const url = idx === -1 ? fullUrl : fullUrl.slice(0, idx);

  return {
    path: window.parent.location.pathname,
    referrer: window.parent.document.referrer,
    search: window.parent.location.search,
    title: window.parent.document.title,
    url,
  };
};

export const createAnalyticsWrapper = ({ webAnalyticsHightouchKey }) => {
  if (webAnalyticsHightouchKey === '__HIGHTOUCH_KEY__') {
    // When running on localhost using the dev server, replacements are not made in index.html.
    // Just use the dev key to prevent errors in the console.
    webAnalyticsHightouchKey = '1d6dc873adbc5a8876376c3686c079ab5b2ece709ff57ee76671e3ca52737601';
  }

  const options = {
    debugMode: /localhost/.test(window.location.origin),
    hightouch: {
      enabled: true,
      writeKey: webAnalyticsHightouchKey,
    },
    gtm: {
      enabled: true,
      id: 'GTM-ML7LRQS',
    },
  };

  const analyticsWrapper = new AnalyticsWrapper(options);

  return analyticsWrapper;
};

/**
 * Configure the analytics wrapper
 * @param {Object} options
 * @param {('marketing'|'app')} options.appMode - instance appMode
 * @param {AnalyticsWrapper} options.analyticsWrapper
 * @param {string=} options.visitorId - visitor id
 * @param {string=} options.visitId - visit id
 * @param {Object} options.webAnalyticsEnvironment - analytics env
 * @param {Object} options.country - country
 */
export const configureAnalyticsWrapper = ({
  appMode,
  analyticsWrapper,
  visitorId = getVisitorId(appMode),
  visitId = getVisitId(appMode),
  webAnalyticsEnvironment,
  country,
  user,
} = {}) => {
  switch (appMode) {
    case 'marketing':
      analyticsWrapper.loadProvider().then(() => {
        analyticsWrapper.setInitialData({
          senderType: 'iframe', // our own property which indicates that we send data from the iframe
          ...getLocationData(), // pass correct location data taking from parent window of the real app
          page: {
            applicationName: 'accounts',
            environment: webAnalyticsEnvironment,
          },
          user: user?.id
            ? {
                id: String(user.id),
                user_id: String(user.id),
                isActive: 'true',
                status: 'customer',
              }
            : undefined,
          visit: {
            visitId,
            visitorId,
            geoLocationCountryCode: country || '',
          },
        });
        //notify parent window that iframe analytics is ready
        window.parent.postMessage(
          { namespace: 'analytics', eventCode: 'marketing-initialized' },
          window.location.origin,
        );
      });

      return analyticsWrapper;

    case 'app':
      // if it's a real app we need to wrap analytics to the postMessage proxy and append an iframe with a marketing-sandbox
      const proxy = createMarketingProxy({ visitId, visitorId, analyticsWrapper });

      //waits for a marketing-initialized event and toggle caching
      window.addEventListener('message', (event) => {
        const { namespace, eventCode } = event.data;

        if (namespace === 'analytics' && eventCode === 'marketing-initialized') {
          proxy.toggleProxyCaching(false);
        }
      });

      return proxy;

    default:
      throw new Error(`Unsupported appMode ${appMode}`);
  }
};

/**
 * Create a post message proxy for marketing
 * @param {Object} params
 * @param {import('@shutterstock-private/analytics-wrapper')} params.analyticsWrapper
 * @param {string} params.visitorId
 * @param {string} params.visitId
 */
const createMarketingProxy = ({ visitId, visitorId, analyticsWrapper }) => {
  const marketingIframe = createSandboxIframe({
    src: `${window.location.origin}/sandboxes/marketing?nextweb=true&visitor_id=${visitorId}&visit_id=${visitId}`,
  });

  document.body.appendChild(marketingIframe);

  const handler = createPostMessageHandler({ targetWindow: marketingIframe.contentWindow, namespace: 'analytics' });

  return createCachedProxy({ target: createProxy({ target: analyticsWrapper, callHandler: handler }) });
};

/**
 * Get id by appMode
 * @param {string} id - key id
 */

const getIdByType = (id) => (appMode) => {
  switch (appMode) {
    case 'app':
      return (new RegExp(`\\b${id}=(\\d+)`).exec(document.cookie) || [])[1] || '';

    case 'marketing':
      return new URLSearchParams(window.location.search).get(id);

    default:
      throw new Error(`Unsupported appMode ${appMode}`);
  }
};

/**
 * Get a visitor id depends of an instance appMode, supports app and marketing
 * @param {('marketing'|'app')} appMode - instance appMode
 */

export const getVisitorId = getIdByType('visitor_id');

/**
 * Get a visit id depends of an instance appMode, supports app and marketing
 * @param {('marketing'|'app')} appMode - instance appMode
 */

export const getVisitId = getIdByType('visit_id');
