export const HtmlCharacterReference = {
  Apostrophe: { codePoint: '&#39;', replacement: "'" },
  NonBreakingSpace: { codePoint: '&#160;', replacement: '​' },
};

const getReplacementRegex = ({ codePoint }) => new RegExp(codePoint, 'g');

export const decodeHtmlCharRef = (str = '') =>
  str
    .replace(getReplacementRegex(HtmlCharacterReference.Apostrophe), HtmlCharacterReference.Apostrophe.replacement)
    .replace(
      getReplacementRegex(HtmlCharacterReference.NonBreakingSpace),
      HtmlCharacterReference.NonBreakingSpace.replacement,
    );
