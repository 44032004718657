import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import merge from 'deepmerge';

import { baseTheme } from './mui-theme-base';

const {
  tokens: { color },
} = baseTheme;

const lightTheme = merge(baseTheme, {
  themeName: 'Bigstock Light',
  palette: {
    mode: 'light',
    primary: {
      main: color.red.tomato,
    },
    secondary: {
      main: color.white,
    },
    info: {
      main: color.blue.azure,
    },
    success: {
      main: color.green.jungle,
    },
    warning: {
      main: color.yellow.amber,
    },
    background: {
      default: color.white,
    },
    black: {
      main: color.black,
    },
    border1Color: color.gray['onyx-12'],
    border2Color: 'rgb(0,0, 0, 0.23)', // Matches default MUI borders
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: color.white,
          '&:hover': {
            backgroundColor: color.red.rose,
          },
        },
      },
    },
  },
});

// For optimal SSR performance we compute the initial theme at build time
export const muiThemeLight = responsiveFontSizes(createTheme(lightTheme));
