import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import makeStyles from '@mui/styles/makeStyles';
import classes from './LanguageMenu.module.scss';
import { AnalyticsTracker } from '../../analytics/trackers';

const useStyles = makeStyles({
  paper: {
    cursor: 'pointer',
    'max-width': 400,
    'max-height': '90%',
    padding: 10,
    overflow: 'scroll',
  },
});

export function LanguageMenu(props) {
  const useClasses = useStyles();
  const initialMenuData = {
    menuActive: false,
    menuRef: null,
  };
  const [menuData, setMenuData] = useState(initialMenuData);
  const { dataAutomation, activeLanguageCode, label, options } = props;

  const onClick = (e) => {
    if (!props.isHoverable && !menuData.menuActive) {
      setMenuData({
        ...menuData,
        menuActive: true,
        menuRef: e.currentTarget,
      });
    }
  };

  const onClickOutside = () => {
    if (!props.isHoverable && menuData.menuActive) {
      setMenuData({
        ...menuData,
        menuActive: false,
        menuRef: null,
      });
    }
  };

  const onMouseEnter = () => {
    if (props.isHoverable) {
      setMenuData({
        ...menuData,
        menuActive: true,
      });
    }
  };

  const onMouseLeave = () => {
    if (props.isHoverable) {
      setMenuData({
        ...menuData,
        menuActive: false,
      });
    }
  };

  const handleChange = (languageCode, name, setLanguage) => {
    const { setUserAccountLanguage, updateUserAccountLanguage } = props;
    if (setUserAccountLanguage && setLanguage) {
      updateUserAccountLanguage(languageCode, name);
    }
    setMenuData({
      ...menuData,
      menuActive: false,
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      tabIndex="0" // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
      role="button"
      className={classes.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      data-automation={dataAutomation}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className={classes.buttonContainer} tabIndex="-1">
        <span className={classes.labelIconContainer}>
          <span className={classes.label}>{label}</span>
        </span>
        {menuData.menuActive ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      <Popover
        onClose={onClickOutside}
        open={menuData.menuActive}
        classes={useClasses}
        tabIndex="-1"
        anchorEl={menuData.menuRef}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Grid container spacing={4}>
          {options.map(({ code: value, name: caption, setLanguage, href }) => (
            <Grid
              item
              xs={4}
              sm={3}
              key={value}
              value={value}
              caption={caption}
              selected={value === activeLanguageCode}
              onClick={() => handleChange(value, caption, setLanguage)}
              tabIndex="-1"
              data-automation={`${dataAutomation}_${value}`}>
              <AnalyticsTracker.OnClick eventLabel={'clickLanguage-' + value} pageSection="form">
                <div className={classes.menuItem}>
                  <span className={classnames(classes.menuItem, { [classes.selected]: value === activeLanguageCode })}>
                    {caption}
                  </span>
                </div>
              </AnalyticsTracker.OnClick>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </div>
  );
}

LanguageMenu.propTypes = {
  dataAutomation: PropTypes.string,
  activeLanguageCode: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isHoverable: PropTypes.bool,
  updateUserAccountLanguage: PropTypes.func.isRequired,
  setUserAccountLanguage: PropTypes.bool,
};

LanguageMenu.defaultProps = {
  dataAutomation: null,
  showLabel: true,
  isHoverable: false,
  setUserAccountLanguage: false,
};
