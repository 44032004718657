import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import Alert from '@mui/material/Alert';
import Fab from '@mui/material/Fab';
import Snackbar from '@mui/material/Snackbar';
import { ApolloProvider, useReactiveVar } from '@apollo/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { muiThemeLight } from './material-ui/theme';
import { createApolloClient } from './graphql';
import {
  ApplicationConfigurationProvider,
  analyticsVar,
  debugVar,
  initializingVar,
  languageVar,
  unexpectedErrorVar,
  useActions,
  embeddedVar,
  showUiDeveloperWarningsVar,
  fullWidthVar,
} from './application-state';
import { AnalyticsProvider } from './analytics';
import { WwwRouter } from './www';
import './App.scss';
import { DebugDrawer } from './debug';
import { AuthProvider } from './auth';
import { WindowSizeMonitor } from './www/window-size-monitor/WindowSizeMonitor';
import { isIframe, isUnsupportedBrowser } from './utils/browser';
import { I18nKey } from './i18n';

export const App = ({ analyticsWrapper, applicationConfiguration }) => {
  const apolloClient = createApolloClient();

  const { t: translate } = useTranslation();

  const { hideUnexpectedError } = useActions();

  const fullWidth = useReactiveVar(fullWidthVar);
  const analytics = useReactiveVar(analyticsVar);
  const showUiDeveloperWarnings = useReactiveVar(showUiDeveloperWarningsVar);
  const debug = useReactiveVar(debugVar);
  const embedded = useReactiveVar(embeddedVar);
  const initializing = useReactiveVar(initializingVar);
  const language = useReactiveVar(languageVar);
  const unexpectedError = useReactiveVar(unexpectedErrorVar);
  const [debugDrawerOpen, setDebugDrawerOpen] = useState(false);

  if (initializing) {
    return <div></div>;
  }

  if (isIframe() && !embedded) {
    console.warn(
      'Missing required query string parameter: embedded. The `embedded` parameter should be included in the URL for embedded use cases, for example https://accounts.shutterstock.com/oauth/authorize?embedded',
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiThemeLight}>
          <ApplicationConfigurationProvider configuration={applicationConfiguration}>
            <WindowSizeMonitor showUiDeveloperWarnings={showUiDeveloperWarnings}>
              <AuthProvider>
                <AnalyticsProvider analytics={analyticsWrapper} pageLanguage={language.code} site={analytics.site}>
                  <Fragment>
                    <Helmet htmlAttributes={{ lang: language.code }} />
                    {debug && (
                      <>
                        <Fab
                          variant="extended"
                          color="primary"
                          size="small"
                          style={{ zIndex: '1', position: 'absolute', right: '15px', top: '15px' }}
                          onClick={() => setDebugDrawerOpen(!debugDrawerOpen)}
                          data-test-id="debug-drawer-button">
                          Debug
                        </Fab>
                        <DebugDrawer open={debugDrawerOpen} />
                      </>
                    )}
                    {isUnsupportedBrowser() && <Alert severity="error">{translate(I18nKey.UnsupportedBrowser)}</Alert>}
                    <WwwRouter fullWidth={fullWidth} />
                    <Snackbar
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      open={unexpectedError.show}
                      autoHideDuration={10000}
                      transitionDuration={{ exit: 0 }}
                      onClose={hideUnexpectedError}>
                      <Alert severity="warning" elevation={6} onClose={hideUnexpectedError}>
                        {unexpectedError.message}
                      </Alert>
                    </Snackbar>
                  </Fragment>
                </AnalyticsProvider>
              </AuthProvider>
            </WindowSizeMonitor>
          </ApplicationConfigurationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
};
