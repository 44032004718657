import React from 'react';
import cn from 'classnames';
import classes from './wrapper-component.module.scss';

export const simpleWrapperComponent =
  (className) =>
  (Component, defaultAlign) =>
  ({ children, align, ...props }) => {
    const Wrapper = Component || 'div';
    const alignClass = align || defaultAlign ? classes[align || defaultAlign] : '';

    return (
      <Wrapper {...props} className={cn(className, alignClass, props.className)}>
        {children}
      </Wrapper>
    );
  };
