import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { I18nKey } from '../../../i18n';
import { ValueProp, ValueProps } from '../../../brand';

const CreateUserValuePropsDefault = () => {
  const { t: translate } = useTranslation();

  return (
    <ValueProps title={translate(I18nKey.ValuePropsWwwTitle)}>
      <ValueProp Icon={<CreateNewFolderOutlinedIcon />}>{translate(I18nKey.ValuePropsWwwSaveAssets)}</ValueProp>
      <ValueProp Icon={<ShareOutlinedIcon />}>{translate(I18nKey.ValuePropsWwwShareCollections)}</ValueProp>
      <ValueProp Icon={<GetAppOutlinedIcon />}>{translate(I18nKey.ValuePropsWwwDownloadComps)}</ValueProp>
      <ValueProp Icon={<VisibilityOutlinedIcon />}>{translate(I18nKey.ValuePropsWwwHistory)}</ValueProp>
      <ValueProp>{translate(I18nKey.ValuePropsWwwMuchMore)}</ValueProp>
    </ValueProps>
  );
};

export default CreateUserValuePropsDefault;
