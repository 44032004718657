export const marketingEmailOptInCountries = [
  'BR', // Brazil
  'CA', // Canada
  'GB', // Great Britain
  // EU countries
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'CY', // Cypress
  'CZ', // Czech Republic
  'DE', // Germany
  'DK', // Denmark
  'EE', // Estonia
  'ES', // Spain
  'FI', // Finland
  'FR', // France
  'GR', // Greece
  'HR', // Croatia
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LU', // Luxembourg
  'LV', // Latvia
  'LT', // Lithuania
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SE', // Sweden
  'SI', // Slovenia
  'SK', // Slovakia
];

/**
 * Returns a boolean indicating whether the marketing opt-in should be shown for the specified country.
 * @param {string} countryCode ISO country code
 * @returns {boolean} `true` if the marketing email opt-in should be show for the specified country.
 */
export const showMarketingEmailOptInForCountry = (countryCode = '') => {
  return marketingEmailOptInCountries.includes(countryCode.toUpperCase());
};
