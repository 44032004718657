import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { useReactiveVar } from '@apollo/client';
import {
  errorsVar,
  languageVar,
  nextUrlVar,
  prefillEmailVar,
  recaptchaSiteKeyVar,
  referrerVar,
  showRecaptchaVar,
  useActions,
  useApplicationConfig,
  userVar,
} from '../../application-state';
import { LoginForm } from './LoginForm';
import { IdentityProviderLoginForm } from './IdentityProviderLoginForm';
import classes from './Login.module.scss';

const LoginMode = {
  Credentials: 'credentials',
  IdentityProvider: 'identity_provider',
};

export const Login = ({ fullWidth = false }) => {
  const {
    'login.canCreateUser': canCreateUser,
    'login.showRequestDemo': showRequestDemo,
    'login.showFormTitle': showFormTitle,
    showLoginWithSso,
    showSocialLogin,
    openSocialLoginInNewTab,
    openSsoLoginInNewTab,
  } = useApplicationConfig();
  const { clearErrors, login, loginSso } = useActions();

  const errors = useReactiveVar(errorsVar);
  const language = useReactiveVar(languageVar);
  const nextUrl = useReactiveVar(nextUrlVar);
  const referrer = useReactiveVar(referrerVar);
  const showRecaptcha = useReactiveVar(showRecaptchaVar);
  const recaptchaSiteKey = useReactiveVar(recaptchaSiteKeyVar);
  const user = useReactiveVar(userVar);

  const prefillEmail = useReactiveVar(prefillEmailVar);
  const currentEmail = useRef(prefillEmail);

  const [loginMode, setLoginMode] = useState(LoginMode.Credentials);

  const handleSsoLogin = ({ emailOrUsername, recaptchaValue }) => {
    loginSso(emailOrUsername, { nextUrl, recaptchaValue, openSsoLoginInNewTab });
  };

  const handleLogin = ({ email, password, recaptchaValue }) => {
    login(email, password, { nextUrl, recaptchaValue });
  };

  const handleFormChange = ({ email }) => (currentEmail.current = email);

  const handleChangeLoginMode = (loginMode) => {
    setLoginMode(loginMode);
    clearErrors();
  };

  const loginForm = () => {
    if (loginMode === LoginMode.IdentityProvider) {
      return (
        <IdentityProviderLoginForm
          errors={errors}
          language={language.code}
          loading={user.loggingIn}
          onSubmit={handleSsoLogin}
          onFormChange={handleFormChange}
          initialEmail={currentEmail.current}
          onDismiss={() => handleChangeLoginMode(LoginMode.Credentials)}
        />
      );
    }

    return (
      <LoginForm
        errors={errors}
        language={language.code}
        showCreateUserLink={canCreateUser}
        showRecaptcha={showRecaptcha}
        recaptchaSiteKey={recaptchaSiteKey}
        loading={user.loggingIn}
        webPropertyDisplayName={referrer.displayName}
        onSubmit={handleLogin}
        onFormChange={handleFormChange}
        initialEmail={currentEmail.current}
        onLoginWithIdentityProvider={() => handleChangeLoginMode(LoginMode.IdentityProvider)}
        showRequestDemoLink={showRequestDemo}
        showFormTitle={showFormTitle}
        showSocialLogin={showSocialLogin}
        showLoginWithSso={showLoginWithSso}
        openSocialLoginInNewTab={openSocialLoginInNewTab}
        nextUrl={nextUrl}
      />
    );
  };

  return <div className={classnames(classes.root, fullWidth && classes.fullWidth)}>{loginForm()}</div>;
};
