import { I18nKey } from '../../i18n';
import exp817 from './exp817';

export const p31193Preset = {
  ...exp817,
  'createUser.valueProps.titleI18nKey': I18nKey.GetStandardContentTitle,
  'createUser.valueProps.subtitleI18nKey': I18nKey.OfferCreateUserSubtitleP31193,
  'createUser.valueProps.bullet4I18nKey': I18nKey.ImageCreateUserGreetingBulletKeepNewP31193,
};

export default p31193Preset;
