import deepMerge from 'deepmerge';
import defaultPresets from '../../$presets';
import { privateProperties } from '../../$presets/access';
import { I18nKey } from '../../i18n';
import { getNestedQueryParameter } from '../utils/url';
import { allowEnterpriseAccountCreationVar, ssoVar, realmVar } from '../';
import { applicationConfigurationSchema } from './schema';

export const Display = {
  Adobe: 'adobe',
  Iframe: 'iframe',
  Popup: 'popup',
};

export const propertyValuesForDisplay = {
  [Display.Adobe]: {
    showFooter: true,
    showHeader: true,
    showLanguageMenu: true,
    showPreviousLocationLink: true,
  },
  [Display.Iframe]: {
    showFooter: false,
    showHeader: false,
    showLanguageMenu: false,
    showPreviousLocationLink: false,
    openSocialLoginInNewTab: true,
    openSsoLoginInNewTab: true,
    'embedded.warnEmbeddedWindowSize': true,
  },
  [Display.Popup]: {
    showFooter: false,
    showHeader: true,
    showLanguageMenu: true,
    showPreviousLocationLink: false,
  },
};

export const View = {
  FreeTrialImage: 'freetrialimage',
  FreeTrialFlex10: 'freetrialflex10',
  FreeTrialImageAIPS: 'freetrialimageaips',
};

export const propertyValuesForView = {
  [View.FreeTrialImage]: {
    'createUser.valueProps.component': View.FreeTrialImage,
    'createUser.formSubmitI18nKey': I18nKey.StartFreeTrial,
    'createUser.formTitleI18nKey': I18nKey.ImageCreateUserTryForFree,
  },
  [View.FreeTrialFlex10]: {
    'createUser.valueProps.component': View.FreeTrialFlex10,
    'createUser.formSubmitI18nKey': I18nKey.StartFreeTrial,
    'createUser.formTitleI18nKey': I18nKey.CreateUserFormTrialFlex10Title,
    'createUser.valueProps.titleI18nKey': I18nKey.CreateUserTrialFlex10GetImagesMusicTitle,
    'createUser.valueProps.bullet4I18nKey': I18nKey.ImageCreateUserGreetingBulletKeepNewP31193,
  },
  [View.FreeTrialImageAIPS]: {
    'createUser.valueProps.component': View.FreeTrialImageAIPS,
    'createUser.formSubmitI18nKey': I18nKey.StartFreeTrial,
    'createUser.formTitleI18nKey': I18nKey.SignUpStartFreeTrial,
  },
};

export const resolvePropertyValueForDisplay = (property, display, defaultValue) => {
  return propertyValuesForDisplay[display]?.[property] ?? defaultValue;
};

export const resolvePropertyValueForView = (property, view, defaultValue) => {
  return propertyValuesForView[view]?.[property] ?? defaultValue;
};

export const warnQueryParameterDeprecated = (parameter) => {
  const hostname = window?.location?.hostname;
  const isProd = !hostname?.includes('.qa.') && !hostname?.includes('.dev.') && !hostname?.includes('localhost');
  if (isProd) return;
  console.warn(`Use of '${parameter}' query parameter is deprecated and support will soon be removed.`);
};

export const resolveApplicationConfigurationFromDisplay = (
  display,
  { schema = applicationConfigurationSchema } = {},
) => {
  let config = {};

  if (display) {
    warnQueryParameterDeprecated('display');

    config = {
      showFooter: resolvePropertyValueForDisplay('showFooter', display),
      showHeader: resolvePropertyValueForDisplay('showHeader', display),
      showLanguageMenu: resolvePropertyValueForDisplay('showLanguageMenu', display),
      showPreviousLocationLink: resolvePropertyValueForDisplay('showPreviousLocationLink', display),
      showSocialLogin: resolvePropertyValueForDisplay('showSocialLogin', display),
      openSocialLoginInNewTab: resolvePropertyValueForDisplay('openSocialLoginInNewTab', display),
      openSsoLoginInNewTab: resolvePropertyValueForDisplay('openSsoLoginInNewTab', display),
      'embedded.warnEmbeddedWindowSize': resolvePropertyValueForDisplay('embedded.warnEmbeddedWindowSize', display),
    };
  }

  const newConfig = Object.keys(config)
    .filter((key) => config[key] !== undefined)
    .reduce((orginalConfig, key) => ({ ...orginalConfig, [key]: config[key] }), {});

  return newConfig;
};

export const resolveApplicationConfigurationFromView = (view, { schema = applicationConfigurationSchema } = {}) => {
  let config = {};

  const shouldResolveForViewValue = view && Object.values(View).includes(view);

  if (shouldResolveForViewValue) {
    warnQueryParameterDeprecated('view');

    config = {
      'createUser.valueProps.component': resolvePropertyValueForView(
        'createUser.valueProps.component',
        view,
        schema?.properties?.['createUser.valueProps.component']?.default,
      ),
      'createUser.formSubmitI18nKey': resolvePropertyValueForView(
        'createUser.formSubmitI18nKey',
        view,
        schema?.properties?.['createUser.formSubmitI18nKey']?.default,
      ),
      'createUser.formTitleI18nKey': resolvePropertyValueForView(
        'createUser.formTitleI18nKey',
        view,
        schema?.properties?.['createUser.formTitleI18nKey']?.default,
      ),
      'createUser.valueProps.titleI18nKey': resolvePropertyValueForView(
        'createUser.valueProps.titleI18nKey',
        view,
        schema?.properties?.['createUser.valueProps.titleI18nKey']?.default,
      ),
      'createUser.valueProps.bullet4I18nKey': resolvePropertyValueForView(
        'createUser.valueProps.bullet4I18nKey',
        view,
        schema?.properties?.['createUser.valueProps.bullet4I18nKey']?.default,
      ),
    };
  }

  return config;
};

export const resolveApplicationConfigurationFromSite = (site, options = {}) => {
  let preset = site;

  if (site === 'premier') {
    preset = 'enterprise';
  }

  return {
    ...resolveApplicationConfigurationFromPreset(preset, options),
  };
};

export const resolveApplicationConfigurationForEmbedded = (embedded, { presets, schema } = {}) => {
  let config = {};

  if (embedded === true || embedded === 'loginSignup') {
    config = resolveApplicationConfigurationFromPreset('embeddedIframe', { presets, schema });
  }

  return config;
};

export const resolveApplicationConfigurationForFlow = (flow, { presets, schema } = {}) => {
  let config = {};

  if (flow === 'googleOneTap') {
    config = resolveApplicationConfigurationFromPreset('google-one-tap', { presets, schema });
  }

  return config;
};

export const resolveApplicationConfigurationFromLocation = (location, { presets, schema } = {}) => {
  let config = {};
  if (location) {
    try {
      // Attempt to resolve the preset from the URL first, otherwise try to resolve for realm
      let preset = getNestedQueryParameter(location, 'preset') ?? realmVar();
      // Resolve configuration from all search params from lowest to highest precedence.
      config = deepMerge.all([
        // Attempt to load configuration based on the value of the `site` query string parameter
        resolveApplicationConfigurationFromSite(getNestedQueryParameter(location, 'site'), {
          presets,
          schema,
        }),
        // Configuration from any other legacy search parameters has higher precendence than `site`
        resolveApplicationConfigurationFromDisplay(getNestedQueryParameter(location, 'display', { recurse: true })),
        resolveApplicationConfigurationFromView(getNestedQueryParameter(location, 'view', { recurse: true })),
        resolveApplicationConfigurationForEmbedded(
          getNestedQueryParameter(location, 'embedded', { recurse: true, type: 'boolean' }) ||
            getNestedQueryParameter(location, 'embedded', { recurse: true }),
          { presets, schema },
        ),
        resolveApplicationConfigurationForFlow(getNestedQueryParameter(location, 'flow', { recurse: true })),
        // Values from the preset take precedence over `site` and any legacy parameters
        resolveApplicationConfigurationFromPreset(preset, {
          presets,
          schema,
        }),
      ]);
    } catch (error) {
      console.warn(`Failed to resolve application configuration from 'location'`);
    }
  }

  return config;
};

export const localStorageKey = 'applicationConfig';

export const resolveApplicationConfigurationFromLocalStorage = (localStorage) => {
  let config = {};

  if (localStorage) {
    try {
      config = localStorage.getItem(localStorageKey);
    } catch (error) {
      console.warn(`Failed to load application configuration from 'localStorage'`);
    }

    if (typeof config === 'string') {
      try {
        config = JSON.parse(config);
      } catch (error) {
        console.warn(
          `Failed parse application configuration JSON from 'localStorage'. Ensure that the value of '${localStorageKey}' is a valid JSON string`,
        );

        config = {};
      }
    }
  }

  if (!config || Array.isArray(config)) {
    config = {};
  }

  return config;
};

export const resolveApplicationConfigurationFromPreset = (
  preset,
  { presets = defaultPresets, schema = applicationConfigurationSchema } = {},
) => {
  const presetObj = presets[preset] ?? {};
  let config = {};

  Object.entries(presetObj).forEach(([key, value]) => {
    if (schema?.properties?.[key]?.$$public === true) {
      config[key] = value;
    }
  });

  const presetPrivateSection = presetObj[privateProperties];

  if (presetPrivateSection) {
    Object.entries(presetPrivateSection)
      .filter(([key]) => schema?.properties?.[key])
      .forEach(([key, value]) => (config[key] = value));
  }

  // TODO: quick fix for IDN-3255. This is not how/where this determination should be made.
  if (preset === 'enterprise') {
    const allowEnterpriseAccountCreation = allowEnterpriseAccountCreationVar();

    config['login.canCreateUser'] = !!allowEnterpriseAccountCreation;
    config['login.showRequestDemo'] = !allowEnterpriseAccountCreation;
  }

  return config;
};

export const resolveApplicationConfigurationFromApplicationState = () => {
  let config = {};

  if (ssoVar()?.isSso && ssoVar()?.isSsoRequested) {
    config = {
      ...config,
      'createUser.formSubmitI18nKey': I18nKey.Continue,
      'createUser.formTitleI18nKey': I18nKey.CreateYourAccount,
      showSocialLogin: false,
    };
  }

  if (realmVar() === 'ldap') {
    config.showSocialLogin = false;
    config['ssoOnly'] = true;
    // env specific
    config['showUiAvailabilityWarning'] = ['dev', 'qa', 'localhost'].some((env) =>
      window.location.hostname.includes(env),
    );
  }
  return config;
};

/**
 * Resolves the application configuration from the `default` values of the properties
 * in the schema.
 */
export const resolveDefaultApplicationConfigurationFromSchema = (schema) => {
  let config = {};

  if (schema?.properties) {
    Object.entries(schema.properties)
      .filter(([, value]) => Object.keys(value).includes('default'))
      .forEach(([key, value]) => (config[key] = value.default));
  }

  return config;
};

/**
 * Resolves the application configuration from all sources.
 */
export const resolveApplicationConfiguration = ({
  location = window.location,
  localStorage = window.localStorage,
  presets = defaultPresets,
  schema = applicationConfigurationSchema,
} = {}) => {
  let defaultConfig = resolveDefaultApplicationConfigurationFromSchema(schema);
  let configFromLocalStorage = resolveApplicationConfigurationFromLocalStorage(localStorage);

  let configFromLocation = resolveApplicationConfigurationFromLocation(location, {
    presets,
    schema,
  });

  let configFromApplicationState = resolveApplicationConfigurationFromApplicationState();

  // Resolve configuration from all sources from lowest to highest precedence
  const config = deepMerge.all([defaultConfig, configFromApplicationState, configFromLocation, configFromLocalStorage]);

  return config;
};
