import { color } from '../theme/tokens';

export const MuiInputOverrides = {
  styleOverrides: {
    underline: {
      '&:after': {
        borderBottom: `2px solid ${color.blue.azure}`,
      },
    },
  },
  defaultProps: {
    size: 'small',
  },
};
