import { createSvgIcon } from '@mui/material/utils';

export const PredictiveAIIcon = createSvgIcon(
  <path
    d="M18.9168 0C19.5077 0 19.9864 0.480757 19.9864 1.07164C19.9864 1.66253 19.5077 2.14117 18.9168 2.14117C18.3259 2.14117 17.8452 1.66041 17.8452 1.07164C17.8473 0.480757 18.3259 0 18.9168 0ZM1.07164 8.35077C1.66253 8.35077 2.14117 8.83152 2.14117 9.42241C2.14117 10.0133 1.66253 10.4919 1.07164 10.4919C0.480758 10.4919 0 10.0112 0 9.42241C0 8.83152 0.480758 8.35077 1.07164 8.35077ZM10.2632 0C11.2501 0.00635361 12.2244 0.461696 12.8576 1.2326C13.0588 1.47827 13.2261 1.7536 13.3511 2.04163C14.1559 3.92442 14.9458 5.81144 15.7506 7.69211C15.9222 8.08603 16.3394 8.355 16.7841 8.33171C17.3411 8.30206 17.8409 7.81495 17.8431 7.21559V4.08537C17.8515 3.96466 17.8473 3.935 17.8769 3.8164C17.9553 3.50931 18.1756 3.24458 18.4615 3.10904C18.8279 2.93749 19.2896 2.99255 19.6073 3.25305C19.8148 3.42672 19.9546 3.67874 19.9906 3.94771C19.9948 3.99219 19.9948 4.03666 19.9991 4.08326C19.9991 5.12948 20.0012 6.17783 19.9991 7.22618C19.9927 8.41007 19.3108 9.56219 18.2328 10.1192C17.411 10.5449 16.4072 10.6021 15.5431 10.2611C14.7679 9.954 14.122 9.33346 13.7852 8.56891C13.781 8.56044 13.7768 8.54985 13.7725 8.54138C12.9677 6.66071 12.1778 4.77156 11.373 2.88878C11.2099 2.5118 10.8499 2.23224 10.4475 2.16658C9.91376 2.07975 9.34193 2.38684 9.12379 2.89725L6.1863 9.83116C6.16512 9.8714 6.14818 9.91376 6.12488 9.95188C5.94275 10.2632 5.59753 10.4729 5.23538 10.4877C4.91982 10.5004 4.60213 10.3649 4.39035 10.1298C4.18068 9.89469 4.08114 9.56431 4.12773 9.25086C4.14467 9.13226 4.16162 9.10473 4.20186 8.99036L7.14146 2.05645C7.5375 1.13094 8.355 0.404514 9.32499 0.127072C9.62784 0.0423575 9.94341 0 10.2632 0Z"
    fill="#0C121C"
    fillOpacity="0.6"
  />,
  'PredictiveAIIcon',
);
