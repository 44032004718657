import url from 'url';

export const getSsoUrls = ({ identityProviderName = '', nextUrl = '', externalEmail = '' } = {}) => {
  const confirmUrl = url.format({ pathname: `/sso/${identityProviderName}/confirm`, query: { next: nextUrl } });
  const switchAccountUrl = url.format({
    pathname: `/users/new`,
    query: { next: confirmUrl, ...(externalEmail && { prefill_email: externalEmail }), sso_flow: true },
  });
  const logoutUrl = url.format({ pathname: `/logout`, query: { next: nextUrl } });

  return {
    confirmUrl,
    logoutUrl,
    switchAccountUrl,
  };
};
