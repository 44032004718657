import { useTranslation, Trans } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { I18nKey } from '../../../i18n';
import { ValueProps, ValueProp } from '../../../brand';
import classes from './CreateUserValuePropsTrialFlex10.module.scss';

const CreateUserValuePropsTrialFlex10 = ({ titleI18nKey, subtitleI18nKey, bullet4I18nKey } = {}) => {
  const { t: translate } = useTranslation();

  return (
    <div class={classes.panelWrapper}>
      <ValueProps
        titleStyle={{ maxWidth: 350, marginBottom: 0 }}
        listStyle={{ marginTop: 0 }}
        title={translate(titleI18nKey || I18nKey.CreateUserTrialFlex10Title)}>
        <div class={classes.noFreeTrial}>
          <i>*{translate(subtitleI18nKey || I18nKey.IndiaNoFreeTrialMessage)}</i>
        </div>
        <ValueProp Icon={<ViewQuiltIcon />}>
          <Trans i18nKey={I18nKey.ImageCreateUserGreetingBulletFull}>
            <b>Full access:</b> Enjoy our entire library of more than 316 million photos, illustrations, and vectors.
          </Trans>
        </ValueProp>
        <ValueProp Icon={<PersonOutlineOutlinedIcon />}>
          <Trans i18nKey={I18nKey.ImageCreateUserGreetingBulletRiskFree}>
            <b>Risk free:</b> Cancel with just a few clicks anytime within the first month and pay nothing.
          </Trans>
        </ValueProp>
        <ValueProp Icon={<CheckCircleOutlineIcon />}>
          <Trans i18nKey={I18nKey.ImageCreateUserGreetingBulletSave}>
            <b>Save more:</b> Get your first month free, then enjoy an annual subscription for just $29/mo.
          </Trans>
        </ValueProp>
        <ValueProp Icon={<ListAltOutlinedIcon />}>
          <Trans i18nKey={bullet4I18nKey || I18nKey.ImageCreateUserGreetingBulletKeep}>
            <b>Keep what you've licensed:</b> Even if you cancel, you can keep the images, videos, and music you've
            downloaded.
          </Trans>
        </ValueProp>
      </ValueProps>
      <div class={classes.panelLegalNote}>
        {/* prettier-ignore */}
        <Trans i18nKey={I18nKey.CreateUserTrialFlex10LegalNote}>
          To cancel during your free trial period, visit the  <a href="https://www.shutterstock.com/account/plans">Plans page</a>. If you do not cancel within the free
          trial period, your annual commitment will begin and you will be <a href="https://support.shutterstock.com/s/article/What-is-a-Shutterstock-FLEX-subscription-with-credits?language=en_US">
            charged monthly
          </a>. If you cancel after the free trial period ends, an early cancellation fee may apply. Your plan will
          automatically renew for another 1-year commitment, unless you <a href="https://www.shutterstock.com/help">contact us</a>. Limit 1 free trial per person. This promotion may
          not be combined with any other offers, coupons, or discounts.
        </Trans>
      </div>
    </div>
  );
};

export default CreateUserValuePropsTrialFlex10;
