import { createTheme } from '@mui/material/styles';

const { typography, spacing } = createTheme();

export const MuiTooltipOverrides = {
  styleOverrides: {
    tooltip: {
      fontSize: typography.caption.fontSize,
      padding: spacing(1),
    },
  },
};
