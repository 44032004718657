import React from 'react';
import IconButton from '@mui/material/IconButton';
import { green } from '@mui/material/colors';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useToggle } from '../../utility/hooks';

export const CopyToClipboard = ({
  text,
  toggleIcon = true,
  snackbar = false,
  itemToCopy = '',
  itemCopied = '',
  onCopy = () => {},
}) => {
  const [hasCopied, toggleHasCopied] = useToggle();

  const [copyToClipboardSnackbarOpen, setCopyToClipboardSnackbarOpen] = useState(false);
  const [copyToClipboardSnackbarMessage, setCopyToClipboardSnackbarMessage] = useState('');

  const handleCloseCopyToClipboardSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopyToClipboardSnackbarOpen(false);
  };

  const handleClick = (e) => {
    e.preventDefault();

    navigator.clipboard.writeText(text || '');

    if (toggleIcon) {
      toggleHasCopied({ override: true });
    }

    if (snackbar) {
      handleCloseCopyToClipboardSnackbar();
      setCopyToClipboardSnackbarMessage(`${itemCopied} copied to clipboard`);
      setCopyToClipboardSnackbarOpen(true);
    }

    if (typeof onCopy === 'function') {
      onCopy();
    }
  };

  const ButtonTooltip = ({ children }) => {
    if (!itemToCopy) {
      return <>{children}</>;
    }

    return <Tooltip title={`Copy ${itemToCopy}`}>{children}</Tooltip>;
  };

  return (
    <>
      <ButtonTooltip>
        <IconButton onClick={handleClick} size="small">
          {hasCopied ? (
            <CheckOutlinedIcon style={{ color: green[500] }} fontSize="small" />
          ) : (
            <FileCopyOutlinedIcon fontSize="small" />
          )}
        </IconButton>
      </ButtonTooltip>
      {snackbar && (
        <Snackbar
          open={copyToClipboardSnackbarOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={handleCloseCopyToClipboardSnackbar}
          message={copyToClipboardSnackbarMessage}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseCopyToClipboardSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      )}
    </>
  );
};
