import React from 'react';
import classnames from 'classnames';
import classes from './FormPagePanelSection.module.scss';

export const FormPagePanelSection = ({
  children,
  className = '',
  theme = classes,
  fullWidth = false,
  showBorder = true,
}) => {
  return (
    <div className={classnames(theme.root, className, fullWidth && theme.fullWidth, showBorder ? '' : theme.noBorder)}>
      {children}
    </div>
  );
};
