import React from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { AnalyticsTracker } from '../../analytics/trackers';
import { CreateUserLink } from './CreateUserLink';
import classes from './CreateUserCta.module.scss';

export const CreateUserCta = ({ disabled = false }) => {
  const { t: translate } = useTranslation();

  return (
    <div data-test-id={CreateUserCta.TestSelector.container}>
      <div className={classes.prompt}>{translate(I18nKey.CreateAccountPrompt)}</div>
      <AnalyticsTracker.OnClick eventLabel="clickGotoCreateAccount">
        <CreateUserLink disabled={disabled} />
      </AnalyticsTracker.OnClick>
    </div>
  );
};

CreateUserCta.TestSelector = {
  container: 'create-user-cta-container',
};
