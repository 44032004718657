import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import classes from './ShutterstockEnterpriseLogo.module.scss';

const ShutterstockEnterpriseLogoComponent = () => {
  return (
    <svg
      className={classes.root}
      data-test-id={ShutterstockEnterpriseLogo.TestSelector.container}
      viewBox="0 0 175 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>Group 54</title>
      <defs>
        <polygon
          id="path-1"
          points="0.127893103 0.100893103 7.63265172 0.100893103 7.63265172 13.9655172 0.127893103 13.9655172"
        />
      </defs>
      <g id="logo_exploration" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="19" transform="translate(-273.000000, -259.000000)">
          <g id="Group-54" transform="translate(273.000000, 259.000000)">
            <path
              d="M58.6050621,7.74754138 C58.4579586,7.72178276 57.9642,7.63954138 57.8195793,7.61285172 C57.0396828,7.47288621 56.3718207,7.29040345 56.3718207,6.50554138 C56.3718207,5.83178276 57.0778552,5.5170931 57.9570621,5.5170931 C59.2654759,5.5170931 60.4125103,6.11047241 60.5434759,6.17595517 L61.2609931,4.28378276 C60.9847862,4.14071379 59.8175793,3.51723103 57.9794069,3.51723103 C56.2957862,3.51723103 54.2394414,4.4010931 54.2394414,6.60081724 C54.2394414,8.56902414 55.6459241,9.20088621 57.0015103,9.43923103 C57.1886483,9.47367931 57.7770621,9.57454138 57.945269,9.60340345 C58.8570621,9.75795517 59.3579586,10.0971621 59.3579586,10.6939552 C59.3579586,11.4511966 58.6466483,11.8059207 57.5700621,11.8059207 C55.8457862,11.8059207 54.8346828,11.2923 54.5423379,11.1721966 L53.7478552,13.0227828 C53.9945793,13.1500241 55.1714069,13.8138517 57.4679586,13.8138517 C59.6962345,13.8138517 61.4844414,12.7099552 61.4844414,10.6557828 C61.4673724,8.74374828 60.1819241,8.01847241 58.6050621,7.74754138"
              id="Fill-7"
              fill="#000000"
            />
            <path
              d="M65.4095897,0.0722793103 L63.3004862,1.23886552 L63.3004862,3.70952069 L62.1900724,3.70952069 L62.1900724,5.72645172 L63.3004862,5.72645172 L63.3004862,10.0867966 C63.3004862,11.9494862 64.0661069,13.6216241 66.7664172,13.6216241 L67.6108655,13.6216241 L67.6108655,11.602831 L67.2943138,11.602831 C65.966969,11.602831 65.4095897,10.8372103 65.4095897,9.79662414 L65.4095897,5.72645172 L67.831831,5.72645172 L67.831831,3.70952069 L65.4095897,3.70952069 L65.4095897,0.0722793103"
              id="Fill-9"
              fill="#000000"
            />
            <path
              d="M81.8860759,5.56755517 C82.7091103,5.56755517 83.5520069,5.94183103 83.8052483,6.84772759 L85.7188345,6.11965862 C85.182869,4.22562414 83.4232138,3.51741724 81.870869,3.51741724 C79.0892483,3.51741724 77.9679724,5.24448621 77.9679724,6.92314138 L77.9679724,10.4070724 C77.9679724,12.0851069 79.0892483,13.8137276 81.870869,13.8137276 C83.4232138,13.8137276 85.182869,13.1042793 85.7188345,11.2099345 L83.8052483,10.4824862 C83.5520069,11.3871414 82.7091103,11.7635897 81.8860759,11.7635897 C80.4532138,11.7635897 80.0733517,10.9790379 80.0733517,10.0849345 L80.0733517,7.24465862 C80.0733517,6.35117586 80.4532138,5.56755517 81.8860759,5.56755517"
              id="Fill-11"
              fill="#000000"
            />
            <polygon
              id="Fill-13"
              fill="#000000"
              points="90.9039517 8.16154138 94.8465724 3.70933448 92.253331 3.70933448 88.8618828 7.58988621 88.8618828 0.0727137931 86.7509172 0.0727137931 86.7509172 13.6217483 88.8618828 13.6217483 88.8618828 10.4667828 89.5542621 9.68502414 92.5742276 13.6217483 95.1050897 13.6217483"
            />
            <path
              d="M73.731331,5.87246897 L71.5384345,5.87246897 C71.1899172,5.87246897 70.9065724,6.15581379 70.9065724,6.50308966 L70.9065724,8.88281379 L68.743469,8.88281379 L68.743469,6.50308966 C68.743469,4.95974483 69.9938483,3.70936552 71.5384345,3.70936552 L73.731331,3.70936552 L73.731331,5.87246897"
              id="Fill-15"
              fill="#000000"
            />
            <path
              d="M71.9462276,11.4583655 L74.1406759,11.4583655 C74.4879517,11.4583655 74.7716069,11.1756414 74.7716069,10.8271241 L74.7716069,8.44833103 L76.9347103,8.44833103 L76.9347103,10.8271241 C76.9347103,12.370469 75.6837103,13.6217793 74.1406759,13.6217793 L71.9462276,13.6217793 L71.9462276,11.4583655"
              id="Fill-17"
              fill="#000000"
            />
            <path
              d="M36.1820276,3.70942759 L38.6036483,3.70942759 L38.6036483,5.72635862 L36.1820276,5.72635862 L36.1820276,9.79653103 C36.1820276,10.8371172 36.7390966,11.6030483 38.0652,11.6030483 L38.3820621,11.6030483 L38.3820621,13.621531 L37.5373034,13.621531 C34.8360621,13.621531 34.0726138,11.9493931 34.0726138,10.0867034 L34.0726138,5.72635862 L30.5232,5.72635862 L30.5232,9.79653103 C30.5232,10.8371172 31.0812,11.6030483 32.4076138,11.6030483 L32.7229241,11.6030483 L32.7229241,13.621531 L31.8781655,13.621531 C29.1781655,13.621531 28.4131655,11.9493931 28.4131655,10.0867034 L28.4131655,5.72635862 L27.3437172,5.72635862 L27.3437172,3.70942759 L28.4131655,3.70942759 L28.4131655,1.23877241 L30.5232,0.0721862069 L30.5232,3.70942759 L34.0726138,3.70942759 L34.0726138,1.23877241 L36.1820276,0.0721862069 L36.1820276,3.70942759"
              id="Fill-19"
              fill="#000000"
            />
            <path
              d="M13.4137966,3.5172931 C12.7468655,3.5172931 12.0696931,3.64639655 11.4604862,3.9042931 L11.4604862,0.0721551724 L9.35138276,0.0721551724 L9.35138276,13.6218103 L11.4604862,13.6218103 L11.4604862,5.87622414 C11.8844172,5.60994828 12.4942448,5.45074138 13.0907276,5.45074138 C14.4165207,5.45074138 15.0403138,6.18781034 15.0403138,7.27494828 L15.0403138,13.6218103 L17.1478655,13.6218103 L17.1478655,6.98694828 C17.1478655,4.98615517 15.6988655,3.5172931 13.4137966,3.5172931"
              id="Fill-21"
              fill="#000000"
            />
            <path
              d="M24.1043379,11.6505 C23.7033724,11.8668103 23.1959586,11.9906379 22.6910276,11.9906379 C21.1169586,11.9906379 20.525131,11.1890172 20.525131,10.0829483 L20.525131,3.70939655 L18.4163379,3.70939655 L18.4163379,10.1217414 C18.4163379,12.6141207 19.8566483,13.8139138 22.431269,13.8139138 C23.6645793,13.8139138 25.0297862,13.5020172 26.2125103,12.9899483 L26.2125103,3.70939655 L24.1043379,3.70939655 L24.1043379,11.6505"
              id="Fill-23"
              fill="#000000"
            />
            <path
              d="M5.40758276,7.74754138 C5.26141034,7.72178276 4.76703103,7.63954138 4.6221,7.61285172 C3.84344483,7.47288621 3.17403103,7.29040345 3.17403103,6.50554138 C3.17403103,5.83178276 3.87975517,5.5170931 4.7598931,5.5170931 C6.06768621,5.5170931 7.21503103,6.11047241 7.34599655,6.17595517 L8.0628931,4.28378276 C7.78885862,4.14071379 6.6201,3.51723103 4.78099655,3.51723103 C3.09861724,3.51723103 1.04196207,4.4010931 1.04196207,6.60081724 C1.04196207,8.56902414 2.4468931,9.20088621 3.80434138,9.43923103 C3.99116897,9.47367931 4.5798931,9.57454138 4.74934138,9.60340345 C5.66051379,9.75795517 6.15923793,10.0971621 6.15923793,10.6939552 C6.15923793,11.4511966 5.4501,11.8059207 4.37382414,11.8059207 C2.6483069,11.8059207 1.6368931,11.2923 1.34423793,11.1721966 L0.550686207,13.0227828 C0.79772069,13.1500241 1.97423793,13.8138517 4.2711,13.8138517 C6.49937586,13.8138517 8.28758276,12.7099552 8.28758276,10.6557828 C8.26834138,8.74374828 6.98351379,8.01847241 5.40758276,7.74754138"
              id="Fill-25"
              fill="#000000"
            />
            <path
              d="M45.214769,7.91394828 L41.6585276,7.91394828 L41.6585276,7.19301724 C41.6585276,6.3882931 42.0625966,5.52553448 43.4476655,5.52553448 C44.782769,5.52553448 45.214769,6.36594828 45.214769,7.15360345 L45.214769,7.91394828 Z M47.3241828,6.92332759 C47.3241828,5.1102931 45.9425276,3.51636207 43.4575966,3.51636207 C40.6371828,3.51636207 39.5540793,5.27787931 39.5540793,6.92332759 L39.5540793,10.2154655 C39.5540793,12.4930862 41.0092862,13.8139138 43.4575966,13.8139138 C45.6172862,13.8139138 46.7382517,12.5650862 47.0709414,11.8149828 L45.2904931,10.7452241 C45.1387345,11.1859138 44.433631,11.8038103 43.4715621,11.8038103 C42.1674931,11.8038103 41.6659759,11.0763621 41.6659759,10.164569 L41.6585276,9.62177586 L47.3241828,9.62177586 L47.3241828,6.92332759 L47.3241828,6.92332759 Z"
              id="Fill-27"
              fill="#000000"
            />
            <path
              d="M48.6737172,6.92683448 L48.6737172,13.6215931 L50.781269,13.6215931 L50.781269,7.10714483 C50.781269,6.53828276 51.1040276,5.56566207 52.6517172,5.56566207 C52.8950276,5.56566207 53.4440276,5.63486897 53.6199931,5.66776552 L53.6199931,3.62321379 C53.4297517,3.5775931 52.9940276,3.51645517 52.5440276,3.51645517 C49.863269,3.51645517 48.6737172,5.19790345 48.6737172,6.92683448"
              id="Fill-29"
              fill="#000000"
            />
            <path
              d="M96.9717207,4.27192759 C96.9717207,4.19372069 96.9223759,4.12016897 96.7898586,4.12016897 L96.6573414,4.12016897 L96.6573414,4.4541 L96.7898586,4.4541 C96.9326172,4.4541 96.9717207,4.39016897 96.9717207,4.28682414 L96.9717207,4.27192759 Z M96.9524793,4.97982414 C96.9422379,4.97982414 96.9326172,4.97516897 96.9273414,4.96554828 L96.7699966,4.62106552 L96.6573414,4.62106552 L96.6573414,4.96058276 C96.6573414,4.97020345 96.6520655,4.97982414 96.6424448,4.97982414 L96.4456862,4.97982414 C96.4357552,4.97982414 96.4258241,4.97020345 96.4258241,4.96058276 L96.4258241,3.94265172 L96.8193414,3.94265172 C97.0843759,3.94265172 97.2026172,4.11023793 97.2026172,4.26727241 L97.2026172,4.28682414 C97.2026172,4.42461724 97.1290655,4.55216897 96.9962379,4.61175517 L97.1929966,4.96058276 C97.1979621,4.97020345 97.1929966,4.97982414 97.1830655,4.97982414 L96.9524793,4.97982414 Z M96.7898586,3.68227241 C96.3426517,3.68227241 96.018031,4.03172069 96.018031,4.47892759 C96.018031,4.89696207 96.3473069,5.25075517 96.7898586,5.25075517 C97.2367552,5.25075517 97.557031,4.89696207 97.557031,4.47892759 C97.557031,4.03606552 97.2078931,3.68227241 96.7898586,3.68227241 L96.7898586,3.68227241 Z M96.7898586,5.48165172 C96.2194448,5.48165172 95.7576517,5.04903103 95.7576517,4.47892759 C95.7576517,3.90851379 96.2194448,3.45075517 96.7898586,3.45075517 C97.3549966,3.45075517 97.8174103,3.91347931 97.8174103,4.47892759 C97.8174103,5.04903103 97.3602724,5.48165172 96.7898586,5.48165172 L96.7898586,5.48165172 Z"
              id="Fill-31"
              fill="#000000"
            />
            <path
              d="M107.759245,7.37751724 C107.759245,5.54213793 106.814245,4.52482759 105.142417,4.52482759 C103.725072,4.52482759 102.616831,5.45151724 102.616831,7.25027586 L102.616831,8.17696552 L107.759245,8.17696552 L107.759245,7.37751724 Z M108.594693,11.466 C108.722245,11.5386207 108.776555,11.6475517 108.703934,11.8111034 C108.177279,13.0285862 107.086728,13.7917241 105.251348,13.7917241 C102.816693,13.7917241 101.471659,12.3017586 101.471659,10.0306552 L101.471659,7.21396552 C101.471659,4.96086207 103.052866,3.54351724 105.160728,3.54351724 C107.413831,3.54351724 108.885797,4.86993103 108.885797,7.43213793 L108.885797,8.68562069 C108.885797,8.97641379 108.722245,9.10365517 108.431452,9.10365517 L102.616831,9.10365517 L102.616831,9.97603448 C102.616831,11.6658621 103.45259,12.8104138 105.251348,12.8104138 C106.741624,12.8104138 107.395831,12.1745172 107.777555,11.3387586 C107.868176,11.1572069 107.959107,11.1388966 108.086348,11.2295172 L108.594693,11.466 Z"
              id="Fill-33"
              fill="#000000"
            />
            <path
              d="M116.890179,13.6283586 C116.780938,13.6283586 116.690007,13.6100483 116.690007,13.4648069 L116.690007,7.12291034 C116.690007,5.63294483 116.308593,4.54301379 114.709697,4.54301379 C113.510214,4.54301379 112.365352,5.46970345 111.820386,6.55994483 L111.820386,13.4648069 C111.820386,13.6100483 111.729455,13.6283586 111.602524,13.6283586 L110.893697,13.6283586 C110.784766,13.6283586 110.675834,13.6100483 110.675834,13.4648069 L110.675834,3.87049655 C110.675834,3.72494483 110.766766,3.70694483 110.893697,3.70694483 L111.583903,3.70694483 C111.693145,3.70694483 111.784076,3.72494483 111.784076,3.87049655 L111.784076,5.30615172 C112.528903,4.28853103 113.637766,3.5433931 115.01849,3.5433931 C116.94449,3.5433931 117.816869,4.83349655 117.816869,6.74149655 L117.816869,13.4648069 C117.816869,13.6100483 117.725938,13.6283586 117.617007,13.6283586 L116.890179,13.6283586 Z"
              id="Fill-35"
              fill="#000000"
            />
            <path
              d="M123.369838,13.6283586 C121.58939,13.6283586 120.64439,13.1377034 120.64439,10.8846 L120.64439,4.97873793 L119.317666,4.97873793 C119.172734,4.97873793 119.154424,4.88811724 119.154424,4.77887586 L119.154424,4.25191034 C119.154424,4.14328966 119.172734,4.05235862 119.317666,4.05235862 L120.64439,4.05235862 L120.64439,1.74463448 C120.64439,1.59908276 120.735321,1.56277241 120.862562,1.56277241 L121.571079,1.56277241 C121.68001,1.56277241 121.771252,1.59908276 121.771252,1.74463448 L121.771252,4.05235862 L123.788183,4.05235862 C123.933114,4.05235862 123.951424,4.14328966 123.951424,4.25191034 L123.951424,4.77887586 C123.951424,4.88811724 123.933114,4.97873793 123.788183,4.97873793 L121.771252,4.97873793 L121.771252,11.011531 C121.771252,12.3562552 122.334217,12.6470483 123.369838,12.6470483 L124.006045,12.6470483 C124.169286,12.6470483 124.242217,12.7379793 124.242217,12.8469103 L124.242217,13.3738759 C124.242217,13.5557379 124.205907,13.6283586 123.969734,13.6283586 L123.369838,13.6283586 Z"
              id="Fill-37"
              fill="#000000"
            />
            <path
              d="M131.699741,7.37751724 C131.699741,5.54213793 130.754431,4.52482759 129.082914,4.52482759 C127.665569,4.52482759 126.557328,5.45151724 126.557328,7.25027586 L126.557328,8.17696552 L131.699741,8.17696552 L131.699741,7.37751724 Z M132.53519,11.466 C132.662741,11.5386207 132.717052,11.6475517 132.644431,11.8111034 C132.117466,13.0285862 131.027224,13.7917241 129.191845,13.7917241 C126.756879,13.7917241 125.412155,12.3017586 125.412155,10.0306552 L125.412155,7.21396552 C125.412155,4.96086207 126.993362,3.54351724 129.101224,3.54351724 C131.354328,3.54351724 132.826293,4.86993103 132.826293,7.43213793 L132.826293,8.68562069 C132.826293,8.97641379 132.662741,9.10365517 132.371948,9.10365517 L126.557328,9.10365517 L126.557328,9.97603448 C126.557328,11.6658621 127.393086,12.8104138 129.191845,12.8104138 C130.682121,12.8104138 131.336017,12.1745172 131.717741,11.3387586 C131.808672,11.1572069 131.899603,11.1388966 132.026845,11.2295172 L132.53519,11.466 Z"
              id="Fill-39"
              fill="#000000"
            />
            <path
              d="M134.8341,13.6283586 C134.724859,13.6283586 134.615928,13.6100483 134.615928,13.4648069 L134.615928,3.87049655 C134.615928,3.72494483 134.724859,3.70694483 134.8341,3.70694483 L135.524617,3.70694483 C135.633548,3.70694483 135.724169,3.74325517 135.724169,3.88849655 L135.724169,5.8328069 C136.269445,4.65163448 137.595859,3.5433931 138.849652,3.5433931 C139.067824,3.5433931 139.104134,3.61601379 139.086134,3.79756552 L138.977203,4.54301379 C138.958893,4.68794483 138.867962,4.72456552 138.777031,4.74256552 C138.61379,4.74256552 138.486548,4.72456552 138.377307,4.74256552 C137.087203,4.88811724 136.015272,6.03266897 135.76079,7.01428966 L135.76079,13.4648069 C135.76079,13.6100483 135.651859,13.6283586 135.542617,13.6283586 L134.8341,13.6283586 Z"
              id="Fill-41"
              fill="#000000"
            />
            <g id="Group-45" transform="translate(140.379310, 3.442345)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-44" />
              <path
                d="M6.50578966,4.06213448 C6.50578966,2.22706552 5.8518931,1.11882414 4.18006552,1.11882414 C2.67178966,1.11882414 1.27275517,2.44492759 1.27275517,4.18937586 L1.27275517,6.91513448 C1.27275517,8.35047931 2.34499655,9.3681 3.92558276,9.3681 C5.45185862,9.3681 6.50578966,8.49603103 6.50578966,6.29723793 L6.50578966,4.06213448 Z M0.345755172,13.9655483 C0.236824138,13.9655483 0.127893103,13.9475483 0.127893103,13.8019966 L0.127893103,0.409686207 C0.127893103,0.264755172 0.236824138,0.246444828 0.345755172,0.246444828 L1.05458276,0.246444828 C1.16351379,0.246444828 1.25444483,0.264755172 1.25444483,0.409686207 L1.25444483,1.95458276 C1.67247931,1.20944483 2.78072069,0.100893103 4.3253069,0.100893103 C6.43316897,0.100893103 7.63265172,1.4093069 7.63265172,3.9168931 L7.63265172,6.40647931 C7.63265172,8.93206552 6.03344483,10.3494103 4.05282414,10.3494103 C2.49023793,10.3494103 1.59954828,9.54996207 1.27275517,8.89575517 L1.27275517,13.8019966 C1.27275517,13.9475483 1.16351379,13.9655483 1.05458276,13.9655483 L0.345755172,13.9655483 Z"
                id="Fill-43"
                fill="#000000"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M150.19769,13.6283586 C150.088759,13.6283586 149.979828,13.6100483 149.979828,13.4648069 L149.979828,3.87049655 C149.979828,3.72494483 150.088759,3.70694483 150.19769,3.70694483 L150.888517,3.70694483 C150.997448,3.70694483 151.088069,3.74325517 151.088069,3.88849655 L151.088069,5.8328069 C151.633345,4.65163448 152.959759,3.5433931 154.213552,3.5433931 C154.431724,3.5433931 154.468034,3.61601379 154.449724,3.79756552 L154.341103,4.54301379 C154.322793,4.68794483 154.231552,4.72456552 154.140931,4.74256552 C153.977379,4.74256552 153.850138,4.72456552 153.741207,4.74256552 C152.451103,4.88811724 151.379172,6.03266897 151.124379,7.01428966 L151.124379,13.4648069 C151.124379,13.6100483 151.015448,13.6283586 150.906517,13.6283586 L150.19769,13.6283586 Z"
              id="Fill-46"
              fill="#000000"
            />
            <path
              d="M156.107276,13.6283586 C155.961724,13.6283586 155.871103,13.5557379 155.871103,13.3918759 L155.871103,3.96142759 C155.871103,3.76156552 155.998034,3.70694483 156.125586,3.70694483 L156.779483,3.70694483 C156.925034,3.70694483 157.015655,3.77987586 157.015655,3.94311724 L157.015655,13.3738759 C157.015655,13.5737379 156.888724,13.6283586 156.761172,13.6283586 L156.107276,13.6283586 Z M156.107276,2.18035862 C155.961724,2.18035862 155.871103,2.10773793 155.871103,1.94449655 L155.871103,0.236358621 C155.871103,0.0908068966 155.961724,0.000186206897 156.107276,0.000186206897 L156.761172,0.000186206897 C156.925034,0.000186206897 157.015655,0.0724965517 157.015655,0.236358621 L157.015655,1.92618621 C157.015655,2.12604828 156.906724,2.18035862 156.761172,2.18035862 L156.107276,2.18035862 Z"
              id="Fill-48"
              fill="#000000"
            />
            <path
              d="M164.362324,6.14187931 C164.235083,6.17818966 164.162462,6.17818966 164.108152,6.01463793 C163.817048,5.06994828 163.254083,4.52467241 162.01829,4.52467241 C160.837428,4.52467241 160.073979,5.10594828 160.073979,6.12356897 C160.073979,8.55853448 165.216393,7.4682931 165.216393,11.102431 C165.216393,12.5923966 163.98091,13.791569 162.01829,13.791569 C160.383083,13.791569 159.110979,13.010431 158.729566,11.5387759 C158.692945,11.3569138 158.747566,11.2476724 158.892807,11.2116724 L159.492703,11.0115 C159.637634,10.9571897 159.728566,10.9935 159.765186,11.1570517 C159.965048,12.0657414 160.637255,12.8288793 162.01829,12.8288793 C163.290083,12.8288793 164.125841,12.1203621 164.125841,11.1207414 C164.125841,8.41298276 158.983738,9.55784483 158.983738,6.14187931 C158.983738,4.61560345 160.237531,3.54336207 162.07291,3.54336207 C163.762738,3.54336207 164.780359,4.47005172 165.125462,5.68753448 C165.180083,5.86939655 165.107152,5.96001724 164.97991,5.99632759 L164.362324,6.14187931 Z"
              id="Fill-50"
              fill="#000000"
            />
            <path
              d="M173.021069,7.37751724 C173.021069,5.54213793 172.076069,4.52482759 170.404241,4.52482759 C168.986897,4.52482759 167.878655,5.45151724 167.878655,7.25027586 L167.878655,8.17696552 L173.021069,8.17696552 L173.021069,7.37751724 Z M173.856828,11.466 C173.984069,11.5386207 174.038379,11.6475517 173.965759,11.8111034 C173.438793,13.0285862 172.348552,13.7917241 170.513483,13.7917241 C168.078207,13.7917241 166.733793,12.3017586 166.733793,10.0306552 L166.733793,7.21396552 C166.733793,4.96086207 168.31469,3.54351724 170.422552,3.54351724 C172.675966,3.54351724 174.147621,4.86993103 174.147621,7.43213793 L174.147621,8.68562069 C174.147621,8.97641379 173.984069,9.10365517 173.693276,9.10365517 L167.878655,9.10365517 L167.878655,9.97603448 C167.878655,11.6658621 168.714414,12.8104138 170.513483,12.8104138 C172.003448,12.8104138 172.657345,12.1745172 173.039069,11.3387586 C173.13,11.1572069 173.220931,11.1388966 173.348172,11.2295172 L173.856828,11.466 Z"
              id="Fill-52"
              fill="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ShutterstockEnterpriseLogo = (props) => {
  return <SvgIcon {...props} component={ShutterstockEnterpriseLogoComponent}></SvgIcon>;
};

ShutterstockEnterpriseLogo.TestSelector = {
  container: 'shutterstock-enterprise-logo-container',
};
