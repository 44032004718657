import { privateProperties } from './access';
import { I18nKey } from '../i18n';

export const enterprisePreset = {
  'createUser.showValueProps': false,
  'createUser.formTitleI18nKey': I18nKey.CreateYourPremierAccountWithoutInvite,
  'logo.component': 'Enterprise',
  [privateProperties]: {
    showSocialLogin: true,
  },
};

export default enterprisePreset;
