import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { requestEnterpriseDemoUrlVar } from '../../application-state';
import { useReactiveVar } from '@apollo/client';

export const RequestDemoLink = () => {
  const { t: translate } = useTranslation();
  const requestEnterpriseDemoUrl = useReactiveVar(requestEnterpriseDemoUrlVar);

  return (
    <Button variant="outlined" fullWidth href={requestEnterpriseDemoUrl} data-test-id="request-demo-btn">
      {translate(I18nKey.RequestDemo)}
    </Button>
  );
};
