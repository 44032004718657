/**
 * Resolves the Chrome/Chromium version from the user agent string.
 *
 * @returns The current Chrome/Chromium version or `false` if the user agent is not
 * Chrome.
 */
export const resolveChromeVersion = () => {
  const userAgentMatch = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return userAgentMatch ? parseInt(userAgentMatch[2], 10) : false;
};

export const isUnsupportedBrowser = () => {
  const chromeVersion = resolveChromeVersion();

  if (chromeVersion && chromeVersion <= 66) {
    return true;
  }

  return false;
};

export const isIframe = () => {
  try {
    return window.self !== window.top;
  } catch (error) {
    return true;
  }
};
