import { I18nKey } from '../../i18n';

export const freetrialflex10variantA = {
  'createUser.showValueProps': true,
  'createUser.valueProps.component': 'freetrialflex10variantA',
  'createUser.formSubmitI18nKey': I18nKey.StartFreeTrial,
  'createUser.formTitleI18nKey': I18nKey.CreateUserFormTrialFlex10Title,
};

export default freetrialflex10variantA;
