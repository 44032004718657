import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { nextUrlVar } from '../application-state';
import { useAuth } from './hooks';

export const RequireAuth = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();

  const redirectUrl = location.pathname + location.search;
  const encodedRedirectUrl = encodeURIComponent(redirectUrl);

  useEffect(() => {
    if (!auth.user?.loggedIn) {
      nextUrlVar(redirectUrl);
    }
  }, [redirectUrl, auth.user?.loggedIn]);

  if (!auth.user?.loggedIn) {
    return <Navigate to={`/login?next=${encodedRedirectUrl}`} state={{ from: location }} replace />;
  }

  return children;
};
