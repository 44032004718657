import React from 'react';
import PropTypes from 'prop-types';
import classes from './FormPage.module.scss';

const FormPageComponent = ({ children, theme }) => <div className={theme.root}>{children}</div>;

FormPageComponent.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.objectOf(PropTypes.string).isRequired,
};

FormPageComponent.defaultProps = {};

export const FormPage = (props) => <FormPageComponent {...props} theme={classes} />;
