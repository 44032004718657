import { useState, useEffect } from 'react';
const joi = require('joi');

const createState = (fields) => {
  return Object.keys(fields).reduce((state, key) => {
    state[key] = {
      value: fields[key],
      meta: {
        touched: false,
        dirty: false,
      },
    };
    return state;
  }, {});
};

const getDataFromState = (state) => {
  return Object.keys(state).reduce((data, key) => {
    data[key] = state[key].value;
    return data;
  }, {});
};

const onChangeHandlerByKey = (state, key, setState, validate) => {
  return (event) => {
    let value;
    if (typeof event === 'object') {
      value = event.currentTarget.value;
    } else {
      value = event;
    }

    const newState = {
      ...state,
      [key]: {
        ...state[key],
        value,
        meta: {
          ...state[key].meta,
          dirty: true,
        },
      },
    };

    setState(newState);
    return validate(newState);
  };
};

const onClickHandlerByKey = (state, key, setState) => {
  return () => {
    setState({
      ...state,
      [key]: {
        ...state[key],
        meta: {
          ...state[key].meta,
          touched: true,
        },
      },
    });
  };
};

const createFormData = (state, setState, validate) => {
  return Object.keys(state).reduce((formData, key) => {
    formData[key] = {
      meta: state[key].meta,
      input: {
        value: state[key].value,
        onClick: onClickHandlerByKey(state, key, setState),
        onChange: onChangeHandlerByKey(state, key, setState, validate),
      },
    };

    return formData;
  }, {});
};

export const useFormValidation = (fields, schema, options) => {
  const initialError = null;
  const initialState = createState(fields);
  const { stateDependencies = [], ...restOptions } = options || {};

  const [state, setState] = useState(initialState);
  const [validation, setValidation] = useState({ valid: null, error: initialError });

  useEffect(() => {
    setState(initialState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, stateDependencies);

  const validateInternal = (state) => {
    const joiSchema = joi.isSchema(schema) ? schema : joi.object(schema);

    const newValidations = joiSchema.validate(getDataFromState(state), restOptions);
    const valid = !newValidations.error;

    setValidation({ valid, error: newValidations.error });

    return valid;
  };

  const validate = () => validateInternal(state);

  const formData = createFormData(state, setState, validateInternal);

  const getData = () => getDataFromState(state);
  const setData = (data) => setState(createState(data));

  return {
    formData,
    getData,
    setData,
    formValidation: validation,
    validateForm: validate,
  };
};
