import ContactSupportIcon from '@mui/icons-material/ContactSupportOutlined';
import { Tooltip } from '@mui/material';
import Fab from '@mui/material/Fab';
import { useTranslation } from 'react-i18next';
import { AnalyticsTracker } from '../../../analytics/trackers';
import { I18nKey } from '../../../i18n';

export const ContactSupportFabButton = () => {
  const { t: translate } = useTranslation();

  return (
    <AnalyticsTracker.OnClick eventLabel="clickContactSupport">
      <Tooltip title={translate(I18nKey.ContactUs)}>
        <Fab
          aria-label={translate(I18nKey.ContactUs)}
          href="https://www.shutterstock.com/help"
          target="_blank"
          rel="noopener noreferrer"
          size="small"
          color="secondary"
          sx={{ position: 'absolute', right: '1em', bottom: '1em' }}>
          <ContactSupportIcon />
        </Fab>
      </Tooltip>
    </AnalyticsTracker.OnClick>
  );
};
