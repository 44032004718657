import React, { useEffect } from 'react';
import { getLog } from '../../Log';

const logger = getLog('MarketingSandbox');

/**
 * Handle `message` events and calls required analyticsWrapper methods
 * @param {Object} params
 * @param {Object} params.analyticsWrapper - analytics instance
 */
const MarketingSandbox = ({ analyticsWrapper }) => {
  useEffect(() => {
    const messagesHandler = (event) => {
      const { namespace, prop: method, args } = event.data;
      logger.debug('iframe proxy event', { namespace, method, args });

      if (!method) {
        logger.warn('Analytics wrapper method was not provided.');
        return;
      }

      if (namespace === 'analytics') {
        try {
          analyticsWrapper[method](...args);
        } catch (error) {
          logger.error(`Analytics wrapper threw an error when calling method: ${method}`, error);
          throw error;
        }
      } else {
        logger.warn('incorrect namespace', { namespace });
      }
    };

    window.addEventListener('message', messagesHandler);

    return () => {
      window.removeEventListener('message', messagesHandler);
    };
  }, [analyticsWrapper]);

  return <></>;
};

export { MarketingSandbox };
