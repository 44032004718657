export function preserveQueryParameters(history, preserve, location) {
  const currentQuery = new URLSearchParams(history.location.search);

  if (currentQuery) {
    const preservedQuery = new URLSearchParams(location.search);

    for (let p of preserve) {
      const v = currentQuery.get(p);

      if (v) {
        preservedQuery.set(p, v);
      }
    }

    location.search = preservedQuery.toString();

    Object.defineProperty(history, 'location', {
      writable: true,
    });

    history.location = {
      ...history.location,
      search: location.search,
    };
  }

  return location;
}

export function createLocationDescriptorObject(location, state) {
  return typeof location === 'string' ? { pathname: location, state } : location;
}

export function createPreserveQueryHistory(createHistory, queryParameters) {
  return (options) => {
    const history = createHistory(options);
    const oldPush = history.push;
    const oldReplace = history.replace;
    history.push = (path, state) =>
      oldPush.apply(history, [
        preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state)),
      ]);
    history.replace = (path, state) =>
      oldReplace.apply(history, [
        preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state)),
      ]);
    return history;
  };
}
