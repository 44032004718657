import React from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import AppleIcon from '@mui/icons-material/Apple';
import { TextHideOverflow } from '../../core-components';
import classes from './AppleLogin.module.scss';

export const AppleLogin = ({ openSocialLoginInNewTab = false, nextUrl = '' }) => {
  const { t: translate } = useTranslation();

  const queryParams = new URLSearchParams([['next', nextUrl]]);
  const href = `/sso/apple/login?${String(queryParams)}`;

  return (
    <Button variant="outlined" href={href} target={openSocialLoginInNewTab ? 'socialsignon' : '_self'} fullWidth>
      <div className={classes.buttonContent}>
        <div className={classes.logo}>
          <AppleIcon />
        </div>
        <TextHideOverflow>{translate(I18nKey.ContinueWithApple)}</TextHideOverflow>
      </div>
    </Button>
  );
};
