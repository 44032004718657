import React from 'react';
import classes from './ValueProps.module.scss';

export const ValueProps = ({ title = '', summary = '', titleStyle, listStyle, children }) => {
  return (
    <div>
      <h3 style={titleStyle} className={classes.title}>
        {title}
      </h3>
      <p>{summary}</p>
      <ul style={listStyle} className={classes.list}>
        {children}
      </ul>
    </div>
  );
};
