import React from 'react';
import classes from './StepperBreadcrumbItem.module.scss';

export const StepperBreadcrumbItem = ({ index, label, active }) => {
  const displayIndex = index + 1;

  return (
    <div className={`${classes.root} ${active ? classes.active : ''}`}>
      <span className={classes.index}>{displayIndex}</span>
      <span>{label}</span>
    </div>
  );
};

StepperBreadcrumbItem.defaultProps = {
  index: 0,
};
