import { I18nKey } from '../../i18n';

export const freetrialflex10variantB = {
  'createUser.showValueProps': true,
  'createUser.valueProps.component': 'freetrialflex10variantB',
  'createUser.formSubmitI18nKey': I18nKey.StartFreeTrial,
  'createUser.formTitleI18nKey': I18nKey.CreateUserFormTrialFlex10Title,
};

export default freetrialflex10variantB;
