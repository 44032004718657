import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import React, { createContext, useState } from 'react';
import { localStorageKey } from './resolvers';

export const ApplicationConfigurationContext = createContext({});

// don't try to check for process var existence
// webpack 5 replaces process.env.NODE_ENV to a concrete string upon compile
// Set the display name for debugging with the React Developer Tools
if (process.env.NODE_ENV !== 'production') {
  ApplicationConfigurationContext.displayName = 'ApplicationConfigurationContext';
}

export const ApplicationConfigurationProvider = ({ children, configuration }) => {
  const [showApplicationConfigSetInLocalStorageWarning, setShowApplicationConfigSetInLocalStorageWarning] = useState(
    localStorage.getItem(localStorageKey) !== null,
  );

  const clearLocalConfiguration = () => {
    localStorage.removeItem(localStorageKey);
    window.location.reload();
  };

  return (
    <ApplicationConfigurationContext.Provider value={configuration}>
      {showApplicationConfigSetInLocalStorageWarning && (
        <Alert severity="warning" onClose={() => setShowApplicationConfigSetInLocalStorageWarning(false)}>
          Application configuration is set in local storage with key: applicationConfig. This is for development
          purposes only and may cause unexpected results.{' '}
          <Button size="small" onClick={clearLocalConfiguration}>
            Clear local configuration
          </Button>
        </Alert>
      )}
      {children}
    </ApplicationConfigurationContext.Provider>
  );
};
