import React from 'react';

import { View, useApplicationConfig } from '../../application-state';
import CreateUserValuePropsCreative from './CreateUserValuePropsCreative';
import CreateUserValuePropsDefault from './CreateUserValuePropsDefault';
import CreateUserValuePropsTrialImage from './CreateUserValuePropsTrialImage';
import CreateUserValuePropsTrialFlex10 from './CreateUserValuePropsTrialFlex10';
import CreateUserValuePropsTrialImageAIPS from './CreateUserValuePropsTrialImageAIPS';
import CreateUserValuePropsTrialImage7DayTrial from '../../../../web/src/lab/experiments/p3-2307-7-or-15-day-free-trial/TreatmentA/CreateUserValuePropsTrialFlex10';
import CreateUserValuePropsTrialImage15DayTrial from '../../../../web/src/lab/experiments/p3-2307-7-or-15-day-free-trial/TreatmentB/CreateUserValuePropsTrialFlex10';

const valueProps = {
  [View.FreeTrialImage]: CreateUserValuePropsTrialImage,
  [View.FreeTrialFlex10]: CreateUserValuePropsTrialFlex10,
  [View.FreeTrialImageAIPS]: CreateUserValuePropsTrialImageAIPS,
  creative: CreateUserValuePropsCreative,
  freetrialflex10variantA: CreateUserValuePropsTrialImage7DayTrial,
  freetrialflex10variantB: CreateUserValuePropsTrialImage15DayTrial,
};

export const CreateUserValueProps = () => {
  const config = useApplicationConfig();

  const ValueProps = valueProps[config['createUser.valueProps.component']] || CreateUserValuePropsDefault;
  const titleI18nKey = config['createUser.valueProps.titleI18nKey'];
  const subtitleI18nKey = config['createUser.valueProps.subtitleI18nKey'];
  const bullet4I18nKey = config['createUser.valueProps.bullet4I18nKey'];

  return <ValueProps titleI18nKey={titleI18nKey} subtitleI18nKey={subtitleI18nKey} bullet4I18nKey={bullet4I18nKey} />;
};
