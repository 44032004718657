import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateIcon,
  PlanIcon,
  AllImagesIcon,
  PredictiveAIIcon,
  RemoveBackgroundIcon,
} from '../../../core-components/icons';
import { I18nKey } from '../../../i18n';
import { ValueProps, ValueProp } from '../../../brand';

const CreateUserValuePropsCreative = () => {
  const { t: translate } = useTranslation();

  return (
    <ValueProps title={translate(I18nKey.CreateCreateUserValuePropsTitle)}>
      <ValueProp Icon={<CreateIcon />}>{translate(I18nKey.CreateCreateUserValuePropsBulletEdit)}</ValueProp>
      <ValueProp Icon={<PredictiveAIIcon />}>{translate(I18nKey.CreateCreateUserValuePropsBulletAi)}</ValueProp>
      <ValueProp Icon={<AllImagesIcon />}>{translate(I18nKey.CreateCreateUserValuePropsBulletLibrary)}</ValueProp>
      <ValueProp Icon={<RemoveBackgroundIcon />}>{translate(I18nKey.CreateCreateUserValuePropsBulletTools)}</ValueProp>
      <ValueProp Icon={<PlanIcon />}>{translate(I18nKey.CreateCreateUserValuePropsBulletPlan)}</ValueProp>
      <p>{translate(I18nKey.AndSoMuchMore)}</p>
    </ValueProps>
  );
};

export default CreateUserValuePropsCreative;
