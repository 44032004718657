import { I18nKey } from '../i18n';
import { privateProperties } from '../$presets/access';

export const creativeEmbeddedSignup = {
  'createUser.formTitleI18nKey': I18nKey.CreateYourAccount,
  'createUser.showValueProps': true,
  'createUser.valueProps.component': 'creative',
  [privateProperties]: {
    showFormBorders: true,
    'login.showFormTitle': true,
    'createUser.showFormTitle': false,
    'embedded.warnEmbeddedWindowSize': false,
  },
};

export default creativeEmbeddedSignup;
