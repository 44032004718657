import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const PageTitleText = ({ i18nKey }) => {
  const { t: translate } = useTranslation();

  const pageTitle = translate(i18nKey) || '';

  return <Fragment>{pageTitle}</Fragment>;
};
