import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { I18nKey } from '../../i18n';
import { AnalyticsTracker } from '../../analytics/trackers';
import { EmailInput, PasswordInput } from '../../core-components/form';

export const CredentialsInput = ({
  errors,
  email = '',
  disableEmail = false,
  password = '',
  autoFocus = true,
  showPassword = true,
  showErrors,
  preventAutofill = false,
  onChange,
  translations = {},
  formValidation,
  formData,
  validateForm,
  credentialRefs,
}) => {
  const { t: translate } = useTranslation();

  const handleEmailChange = useCallback(
    (value) => {
      const formIsValid = formData.email.input.onChange(value);
      onChange(value, password, formIsValid);
    },
    [onChange, password, formData.email.input],
  );

  const handlePasswordChange = (value) => {
    const formIsValid = formData.password.input.onChange(value);
    onChange(email, value, formIsValid);
  };

  const onTogglePasswordAnalytics = AnalyticsTracker.useOnClickTracker({
    eventLabel: 'clickShowPassword',
  });

  useEffect(() => {
    if (formValidation.valid === null) {
      validateForm();

      // If an initial value is provided for email and a callback function is provided,
      // notify the parent component that the input changed.
      if (email && onChange) {
        handleEmailChange(email);
      }
    }
  }, [email, formValidation.valid, handleEmailChange, onChange, validateForm]);

  // ID-66023: Prevent's autofill of browser-stored user/pass for all browsers if
  // the element names are non-standard.  Note: some browsers will require that both
  // the username and password fields be present before autofilling.
  const autoFillPrefix = preventAutofill ? 'not_a_' : '';
  const emailInputLabel = translations['emailLabel'] || translate(I18nKey.EmailAddress);

  return (
    <>
      <AnalyticsTracker.OnClick eventLabel="clickEmail">
        <EmailInput
          ref={credentialRefs?.email}
          name={`${autoFillPrefix}username`}
          label={emailInputLabel}
          value={email}
          error={showErrors && errors && errors.email}
          autoFocus={autoFocus}
          autoComplete={preventAutofill ? 'off' : 'username'}
          onChange={handleEmailChange}
          inputProps={{ 'data-test-id': 'email-input', 'aria-label': emailInputLabel }}
          disabled={disableEmail}
          size="small"
          margin="dense"
        />
      </AnalyticsTracker.OnClick>
      {showPassword && (
        <>
          <AnalyticsTracker.OnClick eventLabel="clickPassword">
            <PasswordInput
              ref={credentialRefs?.password}
              name={`${autoFillPrefix}password`}
              value={password}
              error={showErrors && errors && errors.password}
              autoComplete={`${preventAutofill ? 'new' : 'current'}-password`}
              size="small"
              margin="dense"
              inputProps={{ 'data-test-id': 'password-input', 'aria-label': translate(I18nKey.Password) }}
              onChange={handlePasswordChange}
              onToggleShowPassword={onTogglePasswordAnalytics}
            />
          </AnalyticsTracker.OnClick>
        </>
      )}
    </>
  );
};
