import defaultHttp from 'axios';
import get from 'lodash.get';
import { I18nKey } from '../i18n';
import { interceptRequest, interceptResponse, interceptResponseError } from './client-interceptors';

export const handleApiErrorResponse = ({ response = {} }) => {
  let data = { ...response.data };

  // copy status code to error, if we don't have it
  if (!data.statusCode) {
    data.statusCode = response.status;
  }

  if (response.status === 429) {
    // throttle response
    if (
      Array.isArray(response.data.errors) &&
      response.data.errors.map(({ code }) => code).includes('RECAPTCHA_REQUIRED')
    ) {
      data.showRecaptcha = true;
    }
  } else if (response.status === 409) {
    // validation response
    if (data.fields) {
      if (data.fields.recaptcha) {
        // server requires recaptcha
        data.showRecaptcha = true;
      } else if (data.fields.email && Array.isArray(data.fields.email.errors)) {
        // TODO: tmp workaround to support legacy native apps.  search for special native apps recaptcha error
        for (let i = 0; i < data.fields.email.errors.length; i++) {
          if (data.fields.email.errors[i].code === 'NATIVE_RECAPTCHA_INVALID') {
            data.showRecaptcha = true;
            // remove this error so it's not confused with an email validation problem
            data.fields.email.errors.splice(i, 1);
          }
        }
      }
    }
  } else if (response.status >= 500 && response.status <= 599) {
    data = {
      error: { i18nKey: I18nKey.ErrorInternalServerError },
    };
  }

  return Promise.reject(data);
};

export const handleOtpApiErrorResponse = (error) => {
  const { response = {} } = error;
  let data = { ...response.data };

  const validationError = get(data, 'errors[0]');
  if (validationError) {
    data = { error: { level: 'warning' } };
    switch (validationError.code) {
      // show general message for internal cases
      case 'OTP_INVALID_STATE':
      case 'OTP_NO_CODE':
        data.error.i18nKey = I18nKey.OtpError;
        break;
      // show field message
      case 'VERIFICATION_CHECK_CODE':
        data.error.isField = true;
        break;
      default:
        break;
    }
    // example: VERIFICATION_TRIGGER_FAILED => VerificationTriggerFailed
    const key = validationError.code
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
    const i18Key = `Otp${key}Error`;
    if (!data.error.i18nKey && I18nKey[i18Key]) {
      data.error.i18nKey = I18nKey[i18Key];
    } else if (!data.error.text) {
      // show untranslated value
      data.error.text = validationError.text;
    }
  } else if (response.status === 400) {
    data = {
      error: { i18nKey: I18nKey.OtpError, level: 'warning' },
    };
  } else if (response.status >= 500 && response.status <= 599) {
    data = {
      error: { i18nKey: I18nKey.ErrorInternalServerError, level: 'warning' },
    };
  }

  return Promise.reject(data);
};

export class ApiClient {
  constructor({ notifyUnexpectedError = () => {}, originalAnalyticsWrapper, http = defaultHttp } = {}) {
    this.http = http.create({
      timeout: 10000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    this.http.interceptors.request.use(interceptRequest({ originalAnalyticsWrapper }));
    this.http.interceptors.response.use(interceptResponse(), interceptResponseError(notifyUnexpectedError));
  }

  changeLanguage(code) {
    return this.http.get(`/web/api/languages/${code}`);
  }

  /**
   *
   * @param {Object} payload
   * @param {string=} payload.email_register
   * @param {string=} payload.password
   * @param {Boolean=} payload.special_offers
   */
  createUser(payload = {}) {
    return this.http
      .post('/users', payload)
      .then(({ data }) => {
        return data;
      })
      .catch(handleApiErrorResponse);
  }

  login(payload = {}) {
    return this.http
      .post('/login', payload, {
        headers: {
          'x-shutterstock-redirect-supported-login': true,
          'x-shutterstock-otp-supported-login': true,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch(handleApiErrorResponse);
  }

  loginSso(payload = {}) {
    return this.http
      .post('/login-sso', payload)
      .then(({ data }) => data)
      .catch(handleApiErrorResponse);
  }

  requestCredentialsResetLink(email) {
    return this.http
      .post('/web/api/credentials/forgot', {
        notification_address: email,
      })
      .then(({ data }) => {
        if (data.ok) return;
        return Promise.reject(data);
      })
      .catch(handleApiErrorResponse);
  }

  requestCredentialsChange(currentPassword, newPassword, newConfirmPassword, nextUrl) {
    return this.http
      .post('/web/api/credentials/change', {
        currentPassword,
        newPassword,
        newConfirmPassword,
        nextUrl,
      })
      .then(({ data }) => {
        if (data.ok) {
          return data;
        }

        return Promise.reject(data);
      })
      .catch(handleApiErrorResponse);
  }

  requestEmailChange({ newEmail, confirmEmail, password, nextUrl }) {
    return this.http
      .put('/web/api/users/current/email', {
        newEmail,
        confirmEmail,
        password,
        nextUrl,
      })
      .then(({ data }) => {
        if (data.ok) return data;
        return Promise.reject(data);
      })
      .catch(handleApiErrorResponse);
  }

  fetchUserForgetStatus() {
    return this.http
      .get('/web/api/users/forget')
      .then(({ data }) => data)
      .catch(handleApiErrorResponse);
  }

  fetchUserWorkspaces() {
    return defaultHttpCallHandler(this.http.get('/web/api/workspaces', { notifyUnexpectedError: false }));
  }

  fetchResetCodeData({ code, type, email }) {
    return defaultHttpCallHandler(
      this.http.get('/web/api/credentials/verification-code', {
        params: { code, type, email },
        notifyUnexpectedError: false,
      }),
    );
  }

  resetPassword({ code, type, newPassword, confirmPassword, specialOffers }) {
    return defaultHttpCallHandler(
      this.http.post(
        '/web/api/credentials/reset-password',
        { code, type, newPassword, confirmPassword, specialOffers },
        {
          notifyUnexpectedError: false,
        },
      ),
    );
  }

  setCurrentUserWorkspace(organization_id) {
    return defaultHttpCallHandler(this.http.put('/users/current/organizations', { organization_id }));
  }

  forgetMe({ disabledReason = 'disabled_by_self' } = {}) {
    return this.http
      .put('/users/current/disabled', { disabled_reason: disabledReason })
      .then(({ data }) => data)
      .catch(({ response: { data, status } }) => Promise.reject({ data, status }));
  }

  sendUserVerificationByEmail({ redirectUri }) {
    return this.http
      .post(
        `/web/api/users/current/verifications/email`,
        {},
        {
          params: { redirect_uri: redirectUri },
          notifyUnexpectedError: false,
        },
      )
      .then(({ data }) => data)
      .catch(({ response: { data, status } }) => Promise.reject({ data, status }));
  }

  performUserVerificationByCode({ code, redirectUri }) {
    return this.http
      .post(
        `/web/api/users/verifications`,
        {
          code,
        },
        {
          params: { redirect_uri: redirectUri },
        },
      )
      .then(({ data }) => {
        if (data.ok) return data;
        return Promise.reject({
          response: { data },
        });
      })
      .catch(({ response: { data, status } }) => Promise.reject({ data, status }));
  }

  fetchIndividualUsers() {
    return this.http
      .get('/web/api/individual-user')
      .then(({ data }) => data.data)
      .catch(handleApiErrorResponse);
  }

  validateDisplayName(displayName) {
    return this.http
      .get('/users/validate', { params: { username: displayName } })
      .then(({ data }) => data?.fields?.username?.errors || [])
      .catch(({ response: { data, status } }) => Promise.reject({ data, status }));
  }

  verifyIndividualAccount(username, password) {
    return this.http
      .patch(`/web/api/individual-user`, { username, password })
      .then(({ data }) => data)
      .catch(handleApiErrorResponse);
  }

  deleteIndividualAccount(userId) {
    return this.http
      .delete('/web/api/individual-user', { data: { userId } })
      .then(({ data }) => data)
      .catch(handleApiErrorResponse);
  }

  addIndividualAccount(username, password) {
    return this.http
      .post('/web/api/individual-user', { username, password })
      .then(({ data }) => data.data)
      .catch(handleApiErrorResponse);
  }

  fetchAdminSettings() {
    return defaultHttpCallHandler(this.http.get('/web/api/admin/settings'));
  }

  updateAdminSettings({ setting_name, values }) {
    return defaultHttpCallHandler(this.http.post('/web/api/admin/settings', { setting_name, values }));
  }

  flushMemcached() {
    return defaultHttpCallHandler(this.http.post('/web/api/admin/settings/flush-memcached'));
  }

  /**
   * Returns a list of identity providers
   * @returns {Promise<Object[]>}
   */
  async fetchIdentityProviders() {
    return defaultHttpCallHandler(this.http.get('/web/api/identity-providers'));
  }

  /**
   * Create a new identity provider
   * @param {Object} data - create identity props
   * @returns {Promise<Object>}
   */
  createIdentityProvider(data) {
    return defaultHttpCallHandler(this.http.post('/web/api/identity-providers', data));
  }

  /**
   * Update an identity provider
   * @param {Object} data - update identity props (the whole provider)
   * @returns {Promise<Object>}
   */
  updateIdentityProvider(data) {
    return defaultHttpCallHandler(this.http.put(`/web/api/identity-providers/${data.id}`, data));
  }

  /**
   * Regenerate SCIM token
   * @param {Number} id - identity provider id
   * @returns {Promise<Object>}
   */
  regenerateScimToken(id) {
    return defaultHttpCallHandler(this.http.post(`/web/api/identity-providers/${id}/scim-token`, {}));
  }

  /**
   * Returns a list of teams
   * @param {number} organizationId - parent organization id
   */
  async fetchTeamsByOrganization(organizationId) {
    return defaultHttpCallHandler(this.http.get(`/web/api/organizations/${organizationId}/teams`));
  }

  /**
   * Returns a provider data
   * @param {number} providerId - provider id
   */
  async fetchIdentityProvider(providerId) {
    return defaultHttpCallHandler(this.http.get(`/web/api/identity-providers/${providerId}`));
  }

  /**
   * Deletes a provider by id
   * @param {number} providerId
   */
  async deleteIdentityProvider(providerId) {
    return defaultHttpCallHandler(this.http.delete(`/web/api/identity-providers/${providerId}`));
  }

  /**
   * Send one-time passcode
   * @param {Object} data - date with otp code
   * @returns {Promise<Object>}
   */
  async checkOtp(data) {
    return this.http
      .post('/api/v1/otp/check', data)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => handleOtpApiErrorResponse(error));
  }

  /**
   * Resend one-time passcode
   * @param {Object} data - date with otp code
   * @returns {Promise<Object>}
   */
  async resendOtp(data) {
    return this.http
      .post('/api/v1/otp/resend', data)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => handleOtpApiErrorResponse(error));
  }

  /**
   * Trigger one-time passcode
   * @param {Object} data - data with otp namespace
   * @returns {Promise<Object>}
   */
  async triggerOtp(data) {
    return this.http
      .post('/api/v1/otp/trigger', data)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => handleOtpApiErrorResponse(error));
  }
}

const defaultHttpCallHandler = (request) =>
  request
    .then(({ data: { data } }) => data)
    .catch(({ response: { data, status } }) => Promise.reject({ data, status }));
